import React, {useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AntTableWithPagination from "../../components/AntTableWithPagination";

import {
  getAllActorsWithUnifiedName,
  isActorsFetching
} from '../selectors';
import {ApiContext} from "../../api/ApiContextProvider";



const ListOfActorsBasic = ({
  actorUUID,
 orderRules,
  // afterDeleteActor,
  columns,
  filterByTags,
  meIsAdmin,
  meIsRoot,
  myMajorGroup,
  newCurrentPage,
  // onSelectActors,
  selectActor,
  // selectedActors,
  setMasqueradingUser,
  switchView,
  tableData,
  searchValue,
  ignoreSearchCase,
  getActorTypes =[]
  // total,
}) => {
  const { t } = useTranslation();

  const {
    requestGetAllActors,
  } = useContext(ApiContext);

  const allActors = useSelector(getAllActorsWithUnifiedName);
  const fetchingActors = useSelector(isActorsFetching);

  const [actorsCount, changeActorsCount] = useState(0)
  const [paginationOptions, changePaginationOptions] = useState()


  const changePagination = (data) => {
    changePaginationOptions(data)
  }

  const rowSelection = {
    columnWidth: 40,
    selectedRowKeys: [actorUUID]
  };

  const onRow = (row) => {
    return {
      onClick: (e) => {
        e.stopPropagation();
        if (actorUUID === row.uuid) {
          switchView();
        } else {
            if(selectActor) {
                selectActor('info', row.type, row.uuid);
            }
        }
      },
      onDoubleClick: (e) => {
        e.stopPropagation();

        if(selectActor){
            selectActor('permissions', row.type, row.uuid);
        }
      }
    };
  };

  const getActorsAndCount = async (params) => {
    const data = {
      'actor_type': getActorTypes,
      ...params,
    }


    if(searchValue) {
      data.search_data = {
        value: searchValue,
        ignore_case: ignoreSearchCase,
        fields:
            {
              'base': ['uuid'],
              'uinfo': ['first_name', 'last_name', 'group_name', 'service_name']
            }
      }
    }

    const allActors = await requestGetAllActors(data);

    changeActorsCount(_.get(allActors, 'total'));
  }

  const initFunc = () => {


    if(paginationOptions) {
      const {pageLimit, offset} = paginationOptions;

      const params = {
        limit: pageLimit,
        offset: offset,
        ...orderRules
      }

      getActorsAndCount(params)
    }
  }

  useEffect(() => {
    initFunc()
  }, [
    paginationOptions,
    JSON.stringify(orderRules),
    JSON.stringify(filterByTags)
  ])


  return (
      <>
        <AntTableWithPagination
            columns={columns}
            data={allActors}
            loading={fetchingActors}
            onRow={onRow}
            rowSelection={rowSelection}
            total={actorsCount}
            newCurrentPage={newCurrentPage}
            getPaginationOptions={changePagination}
        />
      </>
  );
};

export default ListOfActorsBasic;

ListOfActorsBasic.propTypes = {

};
