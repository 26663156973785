import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { capitalize } from '../../utils';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../components/PageWrapper';
import { ApiContext } from '../../api/ApiContextProvider';
import { useSelector } from 'react-redux';
import { gertCertificates, gertCertificatesMap } from '../selectors';
import CertificatesList from './CertificatesList';
import CertificateCreate from './CertificateCreate';
import CertificateInfo from './CertificateInfo';


const CertificatesPage = () => {
  const { t } = useTranslation();

  const { requestGetCertificates } = useContext(ApiContext);

  const certificates = useSelector(gertCertificates);
  const certificatesMap = useSelector(gertCertificatesMap);

  const [currentView, setCurrentView] = useState(null);
  const [selectedCertificateID, setSelectedCertificateID] = useState('');

  const switchView = (view, id) => {
    setCurrentView(view || '');
    setSelectedCertificateID(id || null);
  };

  const isCreateView = currentView === 'create';
  const isInfoView = currentView === 'info';
  // const isInfoView = true;

  const initFunc = () => {
    requestGetCertificates();
  };

  const onSuccessDelete = () => {
    switchView();
    initFunc();
  };

  const onSuccessCreate = () => {
    switchView();
    initFunc();
  };

  useEffect(() => {
    initFunc();
  }, []);


  return (
      <Row gutter={[24, 16]}>
        <Col span={14}>
          <CertificatesList
              certificates={certificates}
              afterDeleteCertificate={onSuccessDelete}
              isCreateView={isCreateView}
              switchView={switchView}
              selectedCertificateID={selectedCertificateID}
          />
        </Col>
        {isCreateView && (
            <Col span={10}>
              <CertificateCreate
                  switchView={switchView}
                  onSuccessDelete={onSuccessDelete}
                  onSuccessCreate={onSuccessCreate}
              />
            </Col>
        )}
        {isInfoView && selectedCertificateID && (
            <Col span={10}>
              <CertificateInfo
                  certificate={certificatesMap.get(+selectedCertificateID)}
                  onSuccessDelete={onSuccessDelete}
              />
            </Col>
        )}
      </Row>
  );
};

export default CertificatesPage;
