import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, Input, Row, Space, Tag } from 'antd';
import { capitalize } from '../../utils';
import AntTable from '../../components/AntTable';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiPlusCircle, mdiTrashCanOutline } from '@mdi/js';
import DeleteButton from '../../components/DeleteButton';
import _ from 'lodash';
import '../../styles/custom.css'; 


const CertificatesList = ({
  certificates,
  afterDeleteCertificate,
  isCreateView,
  switchView,
  selectedCertificateID
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const formatDomain = (cell) => (
    <Space size={[4, 4]} wrap>
      {cell
        .split('\n')
        .filter((domain) => !!domain)
        .map((domain, i) => (
          <Tag
            key={`${domain}_${i}`}
            className={'tag-gray-light'}
          >
            {domain}
          </Tag>
        ))}
    </Space>
  );

  const formatActions = (id) => (
    <div className="d-flex">
      <DeleteButton
        targetType={'certificate'}
        onSuccess={afterDeleteCertificate}
        data={{ id }}
      >
        <Button
          className={'button-danger-outlined px-2'}
          size={'small'}
        >
          <Icon path={mdiTrashCanOutline} size={1} />
        </Button>
      </DeleteButton>
    </div>
  );
  

  const columns = [
    {
      dataIndex: 'id',
      key: 'id',
      className: 'p-2',
      title: capitalize(t('auth.headers.id', 'id')),
      render: (cell) => <div className="d-flex flex-nowrap">{cell}</div>
    },
    {
      dataIndex: 'domains',
      key: 'domains',
      className: 'p-2',
      title: capitalize(t('auth.headers.domains', 'domains')),
      render: formatDomain
    },
    {
      dataIndex: 'service_uuid',
      key: 'service_uuid',
      className: 'p-2',
      title: capitalize(t('auth.headers.service_uuid', 'service uuid')),
      render: (cell) =>  <div className="col-servise-uuid">{cell}</div>
    },
    {
      dataIndex: 'certificate',
      key: 'certificate',
      className: 'p-2 ',
      title: capitalize(t('auth.headers.certificate', 'certificate')),
      render: (cell) =>  <div className=" col-certificate">{cell}</div>
    },
  
    {
      dataIndex: 'key',
      key: 'actions',
      className: 'p-2',
      title: capitalize(t('auth.headers.action', 'action')),
      render: formatActions
    }
  ];

  const rowSelection = {
    // columnWidth: '100%',
    selectedRowKeys: selectedCertificateID ? [selectedCertificateID] : []
  };

  const onRow = (row) => {
    return {
      onClick: (e) => {
        e.stopPropagation();
        if (selectedCertificateID === _.toString(row.id)) {
          switchView();
        } else {
          switchView('info', _.toString(row.id));
        }
      }
    };
  };

  const getDataTable = () => {
    if (searchValue) {
      return certificates.filter(({ domains }) => domains.match(new RegExp(searchValue, 'i')));
    }

    return certificates.sort((a, b) => a.id - b.id);
  };

  return (
    <Card className={'card border-blue'}>
      <Row gutter={[10, 10]}>
        <Col className={'d-flex align-items-center'}>
          <h4 className={'header-primary mb-0'}>
            {capitalize(t('auth.headers.certificates_list', 'certificates list'))}
          </h4>
        </Col>
        <Col span={8}>
          <Input.Search
            allowClear
            onSearch={setSearchValue}
            className={'w-100'}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={capitalize(t('auth.placeholders.search_certificate', 'enter certificate domain'))}
          />
        </Col>
        <Col
          className={'d-flex ml-auto flex-wrap justify-content-end'}
        >
          <Button
            className={isCreateView ? 'button-warning-outlined' : 'button-primary-outlined'}
            onClick={() => switchView(isCreateView ? null : 'create')}
          >
            <Icon
              path={mdiPlusCircle}
              size={1.5}
              className={'slow mr-2'}
              rotate={isCreateView ? 765 : 0}
            />
            {isCreateView ? (
              capitalize(t('auth.buttons.cancel', 'cancel'))
            ) : (
              capitalize(t('auth.buttons.create_certificate', 'create certificate'))
            )}
          </Button>
        </Col>
      </Row>
      <hr className={'my-4'} />
      <Row>
        <Col span={24}>
          <AntTable
            data={getDataTable()}
            rowClassName={'table-selectable'}
            columns={columns}
            rowKey={'id'}
            onRow={onRow}
            rowSelection={rowSelection}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CertificatesList;

CertificatesList.propTypes = {
  afterDeleteCertificate: PropTypes.func.isRequired,
  certificates: PropTypes.array,
  isCreateView: PropTypes.bool,
  selectedCertificateID: PropTypes.string,
  switchView: PropTypes.func.isRequired
};
