/* eslint-disable new-cap */
// noinspection JSPotentiallyInvalidConstructorUsage,JSUnresolvedFunction

import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { antNotification, capitalize } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { isGroupsFetching } from '../../actors/selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import PolicyGroupsDescription from './PolicyGroupsDescription';
import PolicyEditFile from './PolicyEditFile';
import PolicyHistoryFile from './PolicyHistoryFile';
import { getLastPolicyDocument } from '../selectors';
import PolicyPreviewFile from './PolicyPreviewFile';
import IStickyBox from 'react-sticky-box';
import PageWrapper from '../../components/PageWrapper';


const SecurityPolicyComponent = () => {
  const { t } = useTranslation();
  const {
    requestGetPolicy,
    requestUpdatePolicy
  } = useContext(ApiContext);

  const fetchingGroups = useSelector(isGroupsFetching);
  const lastDocument = useSelector(getLastPolicyDocument);

  const [documentData, setDocumentData] = useState(null);
  const [documentName, setDocumentName] = useState('Security Policy');
  const [documentHistoryID, setDocumentHistoryID] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => setEditMode((prev) => !prev);

  const onUpdatePolicy = () => {
    setEditMode(false);
    requestUpdatePolicy({ 'security_policy_name': documentName, 'security_policy_text': documentData })
      .then(() => {
        antNotification.success('Updated!');
        requestGetPolicy();
        setDocumentHistoryID(null);
      });
  };

  const onResetSelectedHistory = () => {
    setDocumentHistoryID(null);
    setDocumentName(_.get(lastDocument, 'security_policy_name', ''));
    setDocumentData(_.get(lastDocument, 'security_policy_text', ''));
  };

  const onSelectFromHistory = (historyDocument) => {
    setDocumentHistoryID(historyDocument.id);
    setDocumentName(_.get(historyDocument, 'security_policy_name', ''));
    setDocumentData(_.get(historyDocument, 'security_policy_text', ''));
  };

  const onCancelEdit = () => {
    onResetSelectedHistory();
    toggleEditMode();
  };

  const initFunc = () => {
    requestGetPolicy().then();
  };

  useEffect(() => {
    initFunc();
  }, []);

  useEffect(() => {
    if (lastDocument) {
      setDocumentName(_.get(lastDocument, 'security_policy_name', ''));
      setDocumentData(_.get(lastDocument, 'security_policy_text', ''));
    }
  }, [lastDocument]);


  return (
      <Row gutter={[24, 0]}>
        <Col span={14}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <PolicyEditFile
                  documentData={documentData}
                  onChangeDocument={setDocumentData}
                  documentName={documentName}
                  onChangeDocumentName={setDocumentName}
                  editMode={editMode}
                  onCancelEdit={onCancelEdit}
                  onUpdatePolicy={onUpdatePolicy}
                  toggleEditMode={toggleEditMode}
              />
            </Col>
            {editMode && (
                <Col span={24}>
                  <PolicyHistoryFile
                      onSelectDocument={onSelectFromHistory}
                      onResetSelectedDocument={onResetSelectedHistory}
                      selectedID={documentHistoryID}
                  />
                </Col>
            )}
            <Col span={24}>
              <PolicyGroupsDescription
                  documentName={documentName}
                  documentData={documentData}
              />
            </Col>
          </Row>
        </Col>
        {editMode && (
            <Col span={10}>
              <IStickyBox offsetTop={16} offsetBottom={16}>
                <Card
                    className={`card border-${documentHistoryID ? 'volcano' : 'blue'}`}
                    loading={fetchingGroups}
                >
                  <Row gutter={[16, 0]} wrap={false}>
                    <Col>
                      <h4 className={'header-primary'}>
                        {capitalize(t('auth.headers.policy_preview', 'preview'))}
                      </h4>
                    </Col>
                  </Row>
                  <hr className={'my-4'} />
                  <Row>
                    <Col>
                      <PolicyPreviewFile documentData={documentData} />
                    </Col>
                  </Row>
                </Card>
              </IStickyBox>
            </Col>
        )}
      </Row>
  );
};

export default SecurityPolicyComponent;
