import PropTypes from 'prop-types';
import React from 'react';
import { capitalize, getUserColor } from '../utils';


const UserAvatar = ({
  userFirstName,
  userLastName,
  userUUID,
  size = 40,
  className = 'avatar-user'
}) => {
  const initials = `${capitalize(userFirstName[0])}${capitalize(userLastName[0])}`;

  return (
    <div
      className={className}
      style={{
        width: size,
        height: size,
        backgroundColor: getUserColor(userUUID)
      }}
    >
      <p>{initials}</p>
    </div>
  );
};

export default UserAvatar;

UserAvatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userUUID: PropTypes.string.isRequired
};
