import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import axiosRequest, {axiosRequestDelete} from './api';
import { useDispatch } from 'react-redux';
import { ProfileConstants } from '../profile/constants';
import { PublicUIConstants } from '../publicInterfaces/constants/types';
import _ from 'lodash';
import { ActorsConstants } from '../actors/constants/actionTypes';
import { AuthConstants } from '../auth/constants';
import { CertificatesConstants } from '../certificates/constants/actionTypes';


export const ApiContext = createContext({});

const ApiContextProvider = ({
  children
}) => {
  const dispatch = useDispatch();

  // AUTH
  const requestAuthorization = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.get(
          'authorization/',
          [
            AuthConstants.GET_LOGIN_TEMPLATE_REQUEST,
            AuthConstants.GET_LOGIN_TEMPLATE_SUCCESS,
            AuthConstants.GET_LOGIN_TEMPLATE_FAILURE
          ],
          {
            withoutSessionToken: true,
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestAbout = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.get(
          'about/',
          [
            AuthConstants.AUTH_INFO_REQUEST,
            AuthConstants.AUTH_INFO_SUCCESS,
            AuthConstants.AUTH_INFO_FAILURE
          ],
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  // ACTORS
  const getActors = (data, types) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'get/actors',
          [...types],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestGetActor = ({
    uuid,
    constants
  }) => {
      const finalConstants = constants || [
          ActorsConstants.GET_ACTORS_DATA_REQUEST,
          ActorsConstants.GET_ACTORS_DATA_SUCCESS,
          ActorsConstants.GET_ACTORS_DATA_FAILURE
      ]

      return new Promise((resolve, reject) => {
          dispatch(
              axiosRequest.post(`get/actor/${uuid}`,
                  finalConstants,
                  {},
                  {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
          ));
      });
  };

  const requestUpdateActor = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'update/actor',
          [
            ActorsConstants.UPDATE_ACTOR_REQUEST,
            ActorsConstants.UPDATE_ACTOR_SUCCESS,
            ActorsConstants.UPDATE_ACTOR_FAILURE,
            ActorsConstants.UPDATE_ACTOR_CANCEL
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestCreateActor = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'create/actor',
          [
            ActorsConstants.CREATE_ACTOR_REQUEST,
            ActorsConstants.CREATE_ACTOR_SUCCESS,
            ActorsConstants.CREATE_ACTOR_FAILURE,
            ActorsConstants.CREATE_ACTOR_CANCELED
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestDeleteActor = (uuid) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'delete/actor',
          [
            ActorsConstants.REMOVE_ACTOR_REQUEST,
            ActorsConstants.REMOVE_ACTOR_SUCCESS,
            ActorsConstants.REMOVE_ACTOR_FAILURE,
            ActorsConstants.REMOVE_ACTOR_CANCEL
          ],
          { uuid },
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestUpdateGroupUsers = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'append/group',
          [],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestGetAllActors = (data = {}) => {
    return getActors(
      data,
      [
        ActorsConstants.GET_ALL_ACTORS_REQUEST,
        ActorsConstants.GET_ALL_ACTORS_SUCCESS,
        ActorsConstants.GET_ALL_ACTORS_FAILURE
      ]
    );
  };

  const requestGetAllServices = () => {
    return getActors(
      { 'actor_type': ['service'] },
      [
        ActorsConstants.GET_ALL_SERVICES_REQUEST,
        ActorsConstants.GET_ALL_SERVICES_SUCCESS,
        ActorsConstants.GET_ALL_SERVICES_FAILURE
      ]
    );
  };

  const requestGetAllGroups = (data, constants) => {
      const finalConstants = constants || [
          ActorsConstants.GET_ALL_GROUPS_REQUEST,
          ActorsConstants.GET_ALL_GROUPS_SUCCESS,
          ActorsConstants.GET_ALL_GROUPS_FAILURE
      ]

    return getActors(
      { 'actor_type': ['group'], ...data },
        finalConstants
    );
  };

  const requestGetAllUsers = () => {
    return getActors(
      { 'actor_type': ['user', 'classic_user'] },
      [
        ActorsConstants.GET_ALL_USERS_REQUEST,
        ActorsConstants.GET_ALL_USERS_SUCCESS,
        ActorsConstants.GET_ALL_USERS_FAILURE
      ]
    );
  };

  const requestGetGroupUsers = (uuid) => {
    return getActors(
      {
          'limit': 1,
          'uinfo': {
              'groups': [uuid]
          }},
      [
        ActorsConstants.GET_ACTORS_IN_GROUP_REQUEST,
        ActorsConstants.GET_ACTORS_IN_GROUP_SUCCESS,
        ActorsConstants.GET_ACTORS_IN_GROUP_FAILURE
      ]
    );
  };

  const requestGroupUsersCount = (uuid) => {
        return getActors(
            {
                'limit': 1,
                'uinfo': {
                    'groups': [uuid]
                }},
            [
                ActorsConstants.GET_ACTORS_COUNT_IN_GROUP_REQUEST,
                ActorsConstants.GET_ACTORS_COUNT_IN_GROUP_SUCCESS,
                ActorsConstants.GET_ACTORS_COUNT_IN_GROUP_FAILURE
            ]
        );
    }

  const manageServiceListingGroup = ({
    serviceUUID,
    newGroupUUID,
    requestMethod = 'post'
}) => {
      const data = {
          service_uuid: serviceUUID,
          group_uuid: newGroupUUID
      }
      return new Promise((resolve, reject) => {
          dispatch(
              axiosRequest[requestMethod](
                  'manage-service-listing-group',
                  [],
                  data,
                  {
                      onSuccess: resolve,
                      onFailure: reject,
                      onCancel: reject
                  }
              ));
      });
  }

  const requestCheckServiceKeyPair = (serviceUUID) => {
        return new Promise((resolve, reject) => {
            const data = {
                service_uuid: serviceUUID
            }

            dispatch(
                axiosRequest.post(
                    'healthcheck/check_key_pair',
                    [
                        ActorsConstants.CHECK_SERVICE_KEY_PAIR_REQUEST,
                        ActorsConstants.CHECK_SERVICE_KEY_PAIR_SUCCESS,
                        ActorsConstants.CHECK_SERVICE_KEY_PAIR_FAILURE,
                    ],
                    data,
                    {
                        onSuccess: resolve,
                        onFailure: reject,
                        onCancel: reject
                    }
                ));
        });
    };

  const requestResetSessionsForService = ({
    serviceUUID,
    action
  }) => {
      //"action": "for_service_as_actor" or "on_service" or "full_reset"
        return new Promise((resolve, reject) => {
            const data = {
                service_uuid: serviceUUID,
                action
            }

            dispatch(
                axiosRequest.post(
                    'healthcheck/reset_sessions_for_service',
                    [
                        ActorsConstants.RESET_SESSIONS_FOR_SERVICE_REQUEST,
                        ActorsConstants.RESET_SESSIONS_FOR_SERVICE_SUCCESS,
                        ActorsConstants.RESET_SESSIONS_FOR_SERVICE_FAILURE,
                    ],
                    data,
                    {
                        onSuccess: resolve,
                        onFailure: reject,
                        onCancel: reject
                    }
                ));
        });
    };

    const checkServiceSessions = (serviceUUID) => {
        return new Promise((resolve, reject) => {
            const data = {
                service_uuid: serviceUUID
            }

            dispatch(
                axiosRequest.post(
                    'healthcheck/check_service_sessions',
                    [
                        ActorsConstants.CHECK_SERVICE_SESSIONS_REQUEST,
                        ActorsConstants.CHECK_SERVICE_SESSIONS_SUCCESS,
                        ActorsConstants.CHECK_SERVICE_SESSIONS_FAILURE,
                    ],
                    data,
                    {
                        onSuccess: resolve,
                        onFailure: reject,
                        onCancel: reject
                    }
                ));
        });
    };

    const checkServiceVersion = (serviceUUID) => {
        return new Promise((resolve, reject) => {
            const data = {
                service_uuid: serviceUUID
            }

            dispatch(
                axiosRequest.post(
                    'healthcheck/get_versioning_info_for_service',
                    [
                        ActorsConstants.CHECK_SERVICE_VERSION_REQUEST,
                        ActorsConstants.CHECK_SERVICE_VERSION_SUCCESS,
                        ActorsConstants.CHECK_SERVICE_VERSION_FAILURE,
                    ],
                    data,
                    {
                        onSuccess: resolve,
                        onFailure: reject,
                        onCancel: reject
                    }
                ));
        });
    };


    // CERTIFICATES
  const requestGetCertificates = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'services/certificates/get',
          [
            CertificatesConstants.GET_CERTIFICATES_REQUEST,
            CertificatesConstants.GET_CERTIFICATES_SUCCESS,
            CertificatesConstants.GET_CERTIFICATES_SUCCESS
          ],
          {},
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestCreateCertificate = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'services/certificates/create',
          [
            CertificatesConstants.CREATE_CERTIFICATES_REQUEST,
            CertificatesConstants.CREATE_CERTIFICATES_SUCCESS,
            CertificatesConstants.CREATE_CERTIFICATES_SUCCESS
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestDeleteCertificate = (uuid) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'services/certificates/delete',
          [
            CertificatesConstants.DELETE_CERTIFICATES_REQUEST,
            CertificatesConstants.DELETE_CERTIFICATES_SUCCESS,
            CertificatesConstants.DELETE_CERTIFICATES_SUCCESS
          ],
          {
            'service_uuid': uuid
          },
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  // LOGS
  const requestGetLogs = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'get_logs',
          [],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        )
      );
    });
  };

  // PERMISSION
  const getPermissions = (data, types, url) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          `get/permactions/${url}`,
          [...types],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestGetDefaultPermissions = (service) => {
    return getPermissions(
      {
        'service_uuid': service
      },
      [
        ActorsConstants.GET_DEFAULT_PERMS_REQUEST,
        ActorsConstants.GET_DEFAULT_PERMS_SUCCESS,
        ActorsConstants.GET_DEFAULT_PERMS_FAILURE,
        ActorsConstants.GET_DEFAULT_PERMS_CANCEL
      ],
      'default'
    );
  };

  const requestGetGroupPermissions = (service, groups) => {
    return getPermissions(
      {
        'service_uuid': service,
        'actor_uuid': groups
      },
      [
        ActorsConstants.GET_GROUPS_PERMS_REQUEST,
        ActorsConstants.GET_GROUPS_PERMS_SUCCESS,
        ActorsConstants.GET_GROUPS_PERMS_FAILURE,
        ActorsConstants.GET_GROUPS_PERMS_CANCEL
      ],
      'group'
    );
  };

  const requestGetActorPermissions = (service, actor) => {
    return getPermissions(
      {
        'service_uuid': service,
        'actor_uuid': actor
      },
      [
        ActorsConstants.GET_ACTOR_PERMS_REQUEST,
        ActorsConstants.GET_ACTOR_PERMS_SUCCESS,
        ActorsConstants.GET_ACTOR_PERMS_FAILURE,
        ActorsConstants.GET_ACTOR_PERMS_CANCEL
      ],
      'actor'
    );
  };

  const requestSetPermissions = (data, isGroup = false) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          `set/permactions/${isGroup ? 'group' : 'actor'}`,
          [
            ActorsConstants.SET_PERMISSIONS_REQUEST,
            ActorsConstants.SET_PERMISSIONS_SUCCESS,
            ActorsConstants.SET_PERMISSIONS_FAILURE
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestServiceSpecificAdmin = (data) => {
      return new Promise((resolve, reject) => {
          dispatch(
              axiosRequest.post(
                  `service-specific-admin`,
                  [
                      ActorsConstants.SERVICE_SPECIFIC_ADMIN_REQUEST,
                      ActorsConstants.SERVICE_SPECIFIC_ADMIN_SUCCESS,
                      ActorsConstants.SERVICE_SPECIFIC_ADMIN_FAILURE
                  ],
                  data,
                  {
                      onSuccess: resolve,
                      onFailure: reject,
                  }
              )
          )
      })
  }

  const requestDeletePermissions = (data, isGroup = false) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          `delete/permactions/${isGroup ? 'group' : 'actor'}`,
          [
            ActorsConstants.DELETE_ACTOR_PERMS_REQUEST,
            ActorsConstants.DELETE_ACTOR_PERMS_SUCCESS,
            ActorsConstants.DELETE_ACTOR_PERMS_FAILURE
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestGetSyncServicesInfo = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'healthcheck/synchronization_info',
          [
            ActorsConstants.GET_SYNC_SERVICES_INFO_REQUEST,
            ActorsConstants.GET_SYNC_SERVICES_INFO_SUCCESS,
            ActorsConstants.GET_SYNC_SERVICES_INFO_FAILURE,
            ActorsConstants.GET_SYNC_SERVICES_INFO_CANCEL
          ],
          null,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestForceSyncService = (serviceUUID) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'synchronization/forced_for_service',
          [
            ActorsConstants.GET_SYNC_SERVICES_INFO_REQUEST,
            ActorsConstants.GET_SYNC_SERVICES_INFO_SUCCESS,
            ActorsConstants.GET_SYNC_SERVICES_INFO_FAILURE,
            ActorsConstants.GET_SYNC_SERVICES_INFO_CANCEL
          ],
          {
            'service_uuid': serviceUUID
          },
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  // MASQUERADING

  const requestTurnOnMasquerading = (uuid) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'masquerade/on/',
          [
            ProfileConstants.TURN_ON_MASQUERADING_REQUEST,
            ProfileConstants.TURN_ON_MASQUERADING_SUCCESS,
            ProfileConstants.TURN_ON_MASQUERADING_FAILURE,
            ProfileConstants.TURN_ON_MASQUERADING_CANCEL
          ],
          { 'actor_uuid': uuid },
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestTurnOffMasquerading = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'masquerade/off/',
          [
            ProfileConstants.TURN_OFF_MASQUERADING_REQUEST,
            ProfileConstants.TURN_OFF_MASQUERADING_SUCCESS,
            ProfileConstants.TURN_OFF_MASQUERADING_FAILURE,
            ProfileConstants.TURN_OFF_MASQUERADING_CANCEL
          ],
          {},
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  // PROFILE
  const requestGetProfile = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.get(
          'actor/me',
          [
            ProfileConstants.GET_PROFILE_INFO_REQUEST,
            ProfileConstants.GET_PROFILE_INFO_SUCCESS,
            ProfileConstants.GET_PROFILE_INFO_FAILURE
          ],
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestUpdateProfile = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.put(
          'actor/me',
          [
            ProfileConstants.UPDATE_PROFILE_INFO_REQUEST,
            ProfileConstants.UPDATE_PROFILE_INFO_SUCCESS,
            ProfileConstants.UPDATE_PROFILE_INFO_FAILURE
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestGetMyPermissions = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'permactions/actor/me',
          [
            ProfileConstants.GET_MY_PERMISSIONS_REQUEST,
            ProfileConstants.GET_MY_PERMISSIONS_SUCCESS,
            ProfileConstants.GET_MY_PERMISSIONS_FAILURE
          ],
          {},
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestUpdateUserPassword = (data) => {
      return new Promise((resolve, reject) => {
          dispatch(
              axiosRequest.post(
                  'update/actor/own-password',
                  [
                        ProfileConstants.UPDATE_PROFILE_PASSWORD_REQUEST,
                        ProfileConstants.UPDATE_PROFILE_PASSWORD_SUCCESS,
                        ProfileConstants.UPDATE_PROFILE_PASSWORD_FAILURE
                    ],
                  data,
                  {
                        onSuccess: resolve,
                        onFailure: reject,
                        onCancel: reject
                    }
            ));
      });
  };

  // PUBLIC INTERFACES
  const requestCreateInterface = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'create/public_interface',
          [
            ActorsConstants.CREATE_PUBLIC_UI_REQUEST,
            ActorsConstants.CREATE_PUBLIC_UI_SUCCESS,
            ActorsConstants.CREATE_PUBLIC_UI_FAILURE
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestGetInterface = (uuid) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.get(
          `get/public_interface/${uuid}`,
          [
            PublicUIConstants.GET_PUBLIC_UI_DATA_REQUEST,
            {
              type: PublicUIConstants.GET_PUBLIC_UI_DATA_SUCCESS,
              payload: (response) => _.get(response, 'public_interface')
            },
            PublicUIConstants.GET_PUBLIC_UI_DATA_FAILURE
          ],
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestUpdateInterface = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'update/public_interface',
          [
            ActorsConstants.UPDATE_PUBLIC_UI_REQUEST,
            {
              type: PublicUIConstants.UPDATE_PUBLIC_UI_SUCCESS,
              payload: (response) => _.get(response, 'public_interface')
            },
            ActorsConstants.UPDATE_PUBLIC_UI_FAILURE
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        )
      );
    });
  };

  const requestGetAllInterfaces = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'get/public_interfaces',
          [
            PublicUIConstants.GET_ALL_PUBLIC_UI_REQUEST,
            {
              type: PublicUIConstants.GET_ALL_PUBLIC_UI_SUCCESS,
              payload: (response) => _.get(response, 'public_interface', [])
            },
            PublicUIConstants.GET_ALL_PUBLIC_UI_FAILURE
          ],
          {},
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestGetOpenInterfaces = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.get(
          '/services_info',
          [
            PublicUIConstants.GET_ALL_OPEN_UI_REQUEST,
            {
              type: PublicUIConstants.GET_ALL_OPEN_UI_SUCCESS,
              payload: (response) => _.get(response, 'services', [])
            },
            PublicUIConstants.GET_ALL_OPEN_UI_FAILURE
          ],
          {},
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  // SECURITY POLICY
  const requestGetPolicy = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.get(
          'security_policy/',
          [
            AuthConstants.GET_LIST_OF_POLICIES_REQUEST,
            AuthConstants.GET_LIST_OF_POLICIES_SUCCESS,
            AuthConstants.GET_LIST_OF_POLICIES_FAILURE
          ],
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };

  const requestUpdatePolicy = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'security_policy/',
          [],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject
          }
        ));
    });
  };


  return (
    <ApiContext.Provider
      value={{
        getActors,
        manageServiceListingGroup,
        requestAbout,
        requestAuthorization,
        requestCreateActor,
        requestCreateCertificate,
        requestCreateInterface,
        requestDeleteActor,
        requestDeleteCertificate,
        requestDeletePermissions,
        requestForceSyncService,
        requestGetActor,
        requestGetActorPermissions,
        requestGetAllActors,
        requestGetAllGroups,
        requestGetAllInterfaces,
        requestGetAllServices,
        requestGetAllUsers,
        requestGetCertificates,
        requestGetDefaultPermissions,
        requestGetGroupPermissions,
        requestGetGroupUsers,
        requestGetInterface,
        requestGetLogs,
        requestGetMyPermissions,
        requestGetOpenInterfaces,
        requestGetPolicy,
        requestGetProfile,
        requestGetSyncServicesInfo,
        requestGroupUsersCount,
        requestServiceSpecificAdmin,
        requestSetPermissions,
        requestTurnOffMasquerading,
        requestTurnOnMasquerading,
        requestUpdateActor,
        requestUpdateGroupUsers,
        requestUpdateInterface,
        requestUpdatePolicy,
        requestUpdateProfile,
        requestUpdateUserPassword,
        requestCheckServiceKeyPair,
        requestResetSessionsForService,
        checkServiceSessions,
        checkServiceVersion,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContextProvider;

ApiContextProvider.propTypes = {
  children: PropTypes.element
};
