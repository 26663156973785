// PERMISSIONS
import _ from 'lodash';


export const isPermissionsFetching = (state) => _.get(state, 'actorInfo.permissions.fetching', false);

export const getDefaultPermissions = (state) => _.get(state, 'actorInfo.permissions.defaultPermissions');

export const getDefaultPermissionsMap = (state) => _.get(state, 'actorInfo.permissions.defaultPermissionsMap');

export const getGroupPermissions = (state) => _.get(state, 'actorInfo.permissions.groupPermissions');

export const getGroupPermissionsMap = (state) => _.get(state, 'actorInfo.permissions.groupPermissionsMap');

export const getActorPermissions = (state) => _.get(state, 'actorInfo.permissions.actorPermissions');

export const getActorPermissionsMap = (state) => _.get(state, 'actorInfo.permissions.actorPermissionsMap');

export const getUnions = (state) => _.get(state, 'actorInfo.permissions.unions');

export const getSyncServicesData = (state) => _.get(state, 'syncServices.data');

export const isCeleryActive = (state) => _.get(state, 'syncServices.celery_active');

export const getSyncServicesFetching = (state) => _.get(state, 'syncServices.fetching');

