/* eslint-disable camelcase */
import { ActorsConstants } from '../../actors/constants/actionTypes';
import _ from 'lodash';


const initialState = {
  fetching: false,
  data: null,
  celery_active: false
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ActorsConstants.GET_SYNC_SERVICES_INFO_REQUEST:
    case ActorsConstants.FORCE_SYNC_SERVICE_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case ActorsConstants.GET_SYNC_SERVICES_INFO_SUCCESS:
      return {
        ...state,
        celery_active: _.get(payload, 'celery_active', false),
        data: _.get(payload, 'sync_data', null),
        fetching: false
      };

    case ActorsConstants.GET_SYNC_SERVICES_INFO_FAILURE:
    case ActorsConstants.GET_SYNC_SERVICES_INFO_CANCEL:
    case ActorsConstants.FORCE_SYNC_SERVICE_SUCCESS:
    case ActorsConstants.FORCE_SYNC_SERVICE_FAILURE:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
};
