import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Input, Row, Space, Tag } from 'antd';
import { capitalize, textToClipboard } from '../../utils';
import Icon from '@mdi/react';
import { mdiClose, mdiCogPlayOutline, mdiContentCopy, mdiContentSaveOutline, mdiTooltipTextOutline } from '@mdi/js';
import InfoBlock from '../../components/InfoBlock';
import { useTranslation } from 'react-i18next';
import IStickyBox from 'react-sticky-box';
import _ from 'lodash';


const PermissionInfo = ({
  permission,
  editEnabled,
  params,
  onChangeParams,
  paramsError,
  resetPermissions,
  onUpdatePermissions,
  hasUnsavedData,
  permissionTableType
}) => {
  const { t } = useTranslation();
  const [permissionForm] = Form.useForm();

  const { permaction_uuid: uuid, title, description, perm_type: type, actor_uuid: actorUUID } = permission;

  const tagType = () => {
    const colors = {
      simple: 'gray',
      check: 'volcano'
    };

    return (
      <Tag className={`tag-${colors[type]} ml-2`}>
        {capitalize(type)}
      </Tag>
    );
  };

  const defaultParams = JSON.stringify(_.get(permission, 'default_params', params), null, 4);
  const simpleParams = JSON.stringify({ 'status': 0 }, null, 4);
  const currentParams = JSON.stringify(_.get(permission, 'params', ''), null, 4);

  const onUseSimple = () => {
    onChangeParams(simpleParams);
    permissionForm.setFieldsValue({ params: simpleParams });
  };

  const onSetDefault = () => {
    onChangeParams(defaultParams);
    permissionForm.setFieldsValue({ params: defaultParams });
  };

  const onCancel = () => {
    onChangeParams(currentParams);
    permissionForm.setFieldsValue({ params: currentParams });
  };

  const getBorderClass = () => {
    switch (permissionTableType) {
      case 'actor':
        return 'border-purple';
      case 'group':
        return 'border-volcano';
      case 'default':
        return 'border-green';
      default:
        return '';
    }
  };

  useEffect(() => {
    permissionForm.setFieldsValue({
      'params': currentParams
    });
  }, [uuid, actorUUID]);


  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Card className={`card ${getBorderClass()}`}>
        <Row gutter={[16, 0]} wrap={false}>
          <Col flex={'none'}>
            <h4 className={'header-primary'}>
              {capitalize(t('auth.headers.permission_info', 'permission info'))}
            </h4>
          </Col>
          <Col flex={'auto'} className={'d-flex justify-content-end'}>
            <Space size={[5, 1]} wrap className={'justify-content-end'}>
              <Button
                className={'button-secondary-outlined'}
                size={'small'}
                onClick={resetPermissions}
                disabled={!hasUnsavedData}
              >
                <Icon path={mdiClose} size={1} className={'mr-1'} />
                {capitalize(t('auth.buttons.cancel', 'cancel'))}
              </Button>
              <Button
                className={'button-primary'}
                size={'small'}
                onClick={onUpdatePermissions}
                disabled={!hasUnsavedData}
              >
                <Icon path={mdiContentSaveOutline} size={1} className={'mr-1'} />
                {capitalize(t('auth.buttons.save', 'save'))}
              </Button>
            </Space>
          </Col>
        </Row>
        <hr className={'my-4'} />
        <Row>
          <Col span={24} className={'d-flex align-items-center'}>
            <h3 className={'header-primary m-0'}>
              {title}
            </h3>
            {tagType()}
          </Col>
          <Col span={24} className={'d-flex align-items-center mt-2'}>
            <h5 className={'header-primary'}>
              UUID: {uuid}
            </h5>
            <Button
              className={'button-primary-link ml-2'}
              size={'small'}
              onClick={() => textToClipboard(uuid)}
            >
              <Icon path={mdiContentCopy} size={1} />
            </Button>
          </Col>
        </Row>
        <hr className={'mt-4 mb-0'} />
        <InfoBlock
          iconPath={mdiTooltipTextOutline}
          title={capitalize(t('auth.headers.description', 'description'))}
        >
          {description}
        </InfoBlock>
        {type === 'check' && (
          <InfoBlock
            iconPath={mdiCogPlayOutline}
            title={capitalize(t('auth.headers.condition', 'condition'))}
          >
            <Row gutter={[0, 5]}>
              <Col span={24}>
                <Space size={[5, 1]} wrap className={'justify-content-end'}>
                  <Button
                    className={'button-primary-outlined'}
                    size={'small'}
                    disabled={!editEnabled || defaultParams.trim() === params.trim()}
                    onClick={onSetDefault}
                  >
                    {capitalize(t('auth.buttons.set_default', 'set default value'))}
                  </Button>
                  <Button
                    className={'button-primary-outlined'}
                    size={'small'}
                    disabled={!editEnabled || simpleParams.trim() === params.trim()}
                    onClick={onUseSimple}
                  >
                    {capitalize(t('auth.buttons.use_simple', 'use simple'))}
                  </Button>
                  <Button
                    className={'button-warning-outlined'}
                    size={'small'}
                    disabled={!editEnabled || currentParams.trim() === params.trim()}
                    onClick={onCancel}
                  >
                    {capitalize(t('auth.buttons.cancel', 'cancel'))}
                  </Button>
                </Space>
              </Col>
              <Col span={24}>
                <Form
                  initialValues={{ params }}
                  form={permissionForm}
                  onValuesChange={({ params }) => onChangeParams(params)}
                >
                  <Form.Item
                    name={'params'}
                    rules={[
                      () => (
                        {
                          validator: () => paramsError ? Promise.reject(capitalize(t(
                            'auth.validation.messages.json_invalid',
                            'JSON format invalid'
                          ))) : Promise.resolve()
                        }
                      )
                    ]}
                  >
                    <Input.TextArea
                      autoSize={{
                        minRows: 3,
                        maxRows: 20
                      }}
                      // value={params}
                      disabled={!editEnabled}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </InfoBlock>
        )}
      </Card>
    </IStickyBox>

  );
};

export default PermissionInfo;

PermissionInfo.propTypes = {
  editEnabled: PropTypes.bool,
  hasUnsavedData: PropTypes.bool,
  onChangeParams: PropTypes.func.isRequired,
  onUpdatePermissions: PropTypes.func.isRequired,
  params: PropTypes.string,
  paramsError: PropTypes.bool,
  permission: PropTypes.object.isRequired,
  permissionTableType: PropTypes.string.isRequired,
  resetPermissions: PropTypes.func.isRequired
};
