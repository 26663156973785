import PropTypes from 'prop-types';
import React from 'react';
import InfoBlock from '../../components/InfoBlock';
import { mdiClipboardTextMultipleOutline, mdiFormatColorFill, mdiStarPlusOutline } from '@mdi/js';
import { capitalize } from '../../utils';
import { Col, Form, Input, Row, Space, Switch } from 'antd';
import { COLORS, ICONS } from '../constants/icons';
import IconSwitch from './IconSwitch';
import ColorSwitch from './ColorSwitch';
import { useTranslation } from 'react-i18next';


const InterfaceForm = ({
  form,
  editMode,
  interfaceData,
  setInterfaceData
}) => {
  const { t } = useTranslation();

  const tMsg = (
    path,
    defaultValue
  ) => capitalize(
    t(
      `auth.validation.messages.${path}`,
      defaultValue || path
    ));

  const rules = {
    'service_name': [{ min: 1 }, { max: 200 }, { required: true }],
    'service_domain': [{ type: 'url' }, { required: true }],
    'backend_service': [{ required: true }],
    'params': [
      () => (
        {
          validator(_, value) {
            try {
              JSON.parse(value);
              return Promise.resolve();
            } catch (e) {
              return Promise.reject(new Error(capitalize(t(
                'auth.headers.json_invalid',
                'JSON format invalid'
              ))));
            }
          }
        }
      )
    ]
  };

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters')
    },
    pattern: {
      mismatch: tMsg('mismatch', 'is not a valid email')
    },
    types: {
      email: tMsg('email', 'is not a valid email'),
      number: tMsg('number', 'must be a number type'),
      url: tMsg('url', 'is not a valid URL')
    }
  };

  return (
    <Form
      layout={'vertical'}
      autoComplete={'off'}
      form={form}
      validateMessages={validateMessages}
    >
      <InfoBlock
        iconPath={mdiClipboardTextMultipleOutline}
        title={capitalize(t('auth.headers.interface_detail', 'interface detail'
        ))}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.service_name', 'service name'))}
              name={'service_name'}
              rules={rules.service_name}
            >
              <Input
                placeholder={capitalize(
                  t('auth.placeholders.enter', {
                    value: '$t(auth.headers.service_name)'
                  })
                )}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.domain', 'domain'))}
              name={'service_domain'}
              rules={rules.service_domain}
            >
              <Input
                placeholder={capitalize(
                  t('auth.placeholders.enter', {
                    value: '$t(auth.headers.domain)'
                  })
                )}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.configuration', 'configuration'))}
              name={'params'}
              rules={rules.params}
              normalize={(value) => {
                try {
                  return JSON.stringify(JSON.parse(value), null, 4);
                } catch (e) {
                  return value;
                }
              }}
            >
              <Input.TextArea
                placeholder={capitalize(
                  t('auth.placeholders.enter', {
                    value: '$t(auth.headers.configuration)'
                  })
                )}
                autoSize={{
                  minRows: 3,
                  maxRows: 10
                }}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.display_on_main', 'display on main'))}
              className={'mb-0'}
              name={'display_service'}
              valuePropName={'checked'}
            >
              <Switch
                className={'switch-primary'}
                checkedChildren={'ON'}
                unCheckedChildren={'OFF'}
                valuePropName={'checked'}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
      {editMode && (
        <>
          <InfoBlock
            iconPath={mdiStarPlusOutline}
            title={capitalize(t('auth.headers.select_icon', 'select icon'))}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Space size={[8, 8]} wrap>
                  {Object.keys(ICONS).map((item) => {
                    return (
                      <IconSwitch
                        key={`icon_${item}`}
                        icon={item}
                        checked={interfaceData.icon === item}
                        onClick={(value) => setInterfaceData((prev) => (
                          {
                            ...prev,
                            icon: value
                          }
                        ))}
                      />
                    );
                  })}
                </Space>
              </Col>
            </Row>
          </InfoBlock>
          <InfoBlock
            iconPath={mdiFormatColorFill}
            title={capitalize(t('auth.headers.select_color', 'select color'))}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Space size={[8, 8]} wrap>
                  {Object.keys(COLORS).map((item) => {
                    return (
                      <ColorSwitch
                        key={`color_${item}`}
                        path={interfaceData.icon}
                        color={item}
                        checked={interfaceData.color === item}
                        onClick={(value) => setInterfaceData((prev) => (
                          {
                            ...prev,
                            color: value
                          }
                        ))}
                      />
                    );
                  })}
                </Space>
              </Col>
            </Row>
          </InfoBlock>
        </>
      )}

    </Form>
  );
};

export default InterfaceForm;

InterfaceForm.propTypes = {
  editMode: PropTypes.bool,
  form: PropTypes.object.isRequired,
  interfaceData: PropTypes.object.isRequired,
  setInterfaceData: PropTypes.func.isRequired
};
