import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMasqueradingEnabled } from '../profile/selectors';
import { Button } from 'antd';
import { antNotification, capitalize } from '../utils';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiGhostOffOutline } from '@mdi/js';
import Cookies from 'universal-cookie';
import { ProfileConstants } from '../profile/constants';
import { AuthHelper } from '../auth/utils';
import { useHistory } from 'react-router-dom';


const MasqueradingTurnOffButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const isMasquerading = useSelector(getMasqueradingEnabled);

  const reloadBrowserPage = () => {
    history.go(0);
  };

  const turnOffMasquerading = () => {
    AuthHelper.masqueradingOff()
      .then(() => {
        antNotification.warning(capitalize(t(
          'auth.notifications.masquerading_off',
          'you are out of masquerading mode'
        )));
        reloadBrowserPage();
      })
      .catch(() => {
        antNotification.error(capitalize(t(
          'auth.notifications.masquerading_off_failed',
          'error exiting masquerading mode'
        )));
      });
  };

  const initFunc = () => {
    const cookies = new Cookies();

    const authPrimary = cookies.get('AuthPrimary');
    if (authPrimary) {
      dispatch({ type: ProfileConstants.TURN_ON_MASQUERADING_SUCCESS });
    } else {
      dispatch({ type: ProfileConstants.TURN_OFF_MASQUERADING_SUCCESS });
    }
  };

  useEffect(() => {
    initFunc();
  }, []);

  if (!isMasquerading) {
    return null;
  }

  return (
    <div className={'d-flex align-items-center h-100 px-4'}>
      <Button
        className={'button-warning-outlined'}
        onClick={turnOffMasquerading}
      >
        <Icon path={mdiGhostOffOutline} size={1.5} className={'mr-2'} />
        {capitalize(t('auth.buttons.exit_masquerading', 'exit from masquerading'))}
      </Button>
    </div>
  );
};

export default MasqueradingTurnOffButton;
