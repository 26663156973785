import i18n from './i18n';
import _ from 'lodash';
import qs from 'query-string';
import { history } from './history';
import { notification } from 'antd';


export const capitalize = string => string ? `${string[0].toUpperCase()}${string.slice(1)}` : '';


class AntNotification {
  display = (type, message, description) => {
    notification[type]({
      message: message || type,
      description,
      top: 74,
      duration: description ? description.length * 0.08 : 3
    });
  };

  error = (message, description) => {
    this.display('error', message, description);
  };

  success = (message, description) => {
    this.display('success', message, description);
  };

  warning = (message, description) => {
    this.display('warning', message, description);
  };

  info = (message, description) => {
    this.display('info', message, description);
  };
}


export const antNotification = new AntNotification();

export const textToClipboard = (value) => {
  navigator.clipboard.writeText(value)
    .then(() => {
      antNotification.success(`${capitalize(i18n.t(
        'auth.notifications.copied',
        'copied to clipboard'
      ))}!`);
    });
};

export const clearObject = (obj) => JSON.parse(JSON.stringify(
  obj,
  (key, value) => _.identity(value)
));

export const getUserColor = (userUUID) => {
  const userColorNum =
    (
      Number.parseInt(userUUID[userUUID.length - 1], 16) +
      Number.parseInt(userUUID[0], 16)
    ) %
    10;

  switch (userColorNum) {
    case 7:
      return '#722ed1';
    case 8:
      return '#eb2f96';
    case 0:
      return '#f5222d';
    case 1:
      return '#fa541c';
    case 2:
      return '#fa8c16';
    case 3:
      return '#faad14';
    case 4:
      return '#fadb14';
    case 5:
      return '#a0d911';
    case 6:
      return '#52c41a';
    case 9:
      return '#13c2c2';
    default:
      return '#2f54eb';
  }
};


class ParamsURL {
  mainPage = 'admin/profile';

  // is selector
  get = (state) => qs.parse(_.get(state, 'router.location.search', ''));

  set = (data, path) => {
    history.push({
      path: path || _.get(history, 'location.path', this.mainPage),
      search: data ? qs.stringify({ ...qs.parse(history.location.search), ...data }) : ''
    });

    return qs.parse(history.location.search);
  };

  clear = () => {
    history.push({
      path: _.get(history, 'location.path', this.mainPage),
      search: ''
    });
  };
}


export const paramsURL = new ParamsURL();

export const percentage = (partialValue, totalValue) => (100 * partialValue) / totalValue;

export const stopPropagation = e => e.stopPropagation()

export const getNameForActor = (actor) => {
  const {
    actor_type,
    uinfo,
  } = actor || {};

  switch (actor_type) {
    case 'user':
    case 'classic_user':
      return uinfo?.first_name + ' ' + uinfo?.last_name
    case 'group':
      return uinfo?.group_name
    case 'service':
      return uinfo?.service_name
    default:
      break;
  }
}
