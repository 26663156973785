import React, {useContext, useReducer, useState} from "react";
import Icon from "@mdi/react";
import {mdiKey} from "@mdi/js";
import {Button, Col, Row} from "antd";

import ColumnsWrapperForBasicActorList from "../ColumnsWrapperForBasicActorList";

import {ApiContext} from "../../../api/ApiContextProvider";
import {antNotification} from "../../../utils";



const ServicesKeyPairsBtn = ({uuid}) => {
    const {requestCheckServiceKeyPair} = useContext(ApiContext);

    const [fetching, setFetching] = useReducer((state) => !state, false);

    const checkServicePair = () => {
        setFetching()
        requestCheckServiceKeyPair(uuid)
            .then(() => {
                setFetching()
                antNotification.success('Success')
            })
            .catch(() => {setFetching()})
    }

    return(
        <Button
            className={'mx-auto button-primary-outlined'}
            size={'small'}
            disabled={fetching}
            onClick={checkServicePair}
        >
            {fetching ? 'Pending...' : 'Check'}
            <Icon
                className={'ml-1'}
                path={mdiKey}
                size={1}
            />
        </Button>
    )
}

const ServicesKeyPairs = () => {
    const {requestCheckServiceKeyPair} = useContext(ApiContext);

    const [selectedActors, setSelectedActors] = useState(new Set());

    const checkKeyPairs = async () => {
        for (let actor of selectedActors) {
            await requestCheckServiceKeyPair(actor).then((res) => {
                antNotification.success('Success')
            }).catch()
        };
    }

    const actionKeyPair = {
        dataIndex: 'uuid',
        key: 'actions',
        className: 'd-flex p-2 pr-4',
        title: '',
        render: (cell) => (
            <ServicesKeyPairsBtn uuid={cell}/>
        )
    }

    return(
        <>
            {selectedActors.size !== 0 && (
                <Row className={'mb-2'}>
                    <Col>
                        <Button
                            type={'primary'}
                            size={'small'}
                            onClick={checkKeyPairs}
                        >
                            Check key pairs
                        </Button>
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={24}>
                    <ColumnsWrapperForBasicActorList
                        getActorTypes={['service']}
                        typeOfColumns={'servicesKeyPairs'}
                        withCheckBox
                        action={actionKeyPair}
                        getSelectedActors={setSelectedActors}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ServicesKeyPairs;
