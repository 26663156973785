import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Radio, Row, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiCheckboxMultipleMarkedCircleOutline,
  mdiCog,
  mdiHexagonMultiple,
  mdiShieldAccount
} from '@mdi/js';
import {omit, get} from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import IStickyBox from 'react-sticky-box';
import CryptoJS from 'crypto-js';
import { saveAs } from 'file-saver';

import InfoBlock from '../../components/InfoBlock';
import ServiceInfoFormItems from './infoForms/ServiceInfoFormItems';
import GroupInfoFormItems from './infoForms/GroupInfoFormItems';
import UserInfoFormItems from './infoForms/UserInfoFormItems';

import { antNotification, capitalize, clearObject } from '../../utils';
import { isActorCreatedFetching } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import {getAuthBiomeInfo, getAuthServiceInfo} from "../../auth/selectors";
import ActorFormWrapper from "./infoForms/ActorFormWrapper";


const ActorCreate = ({
  switchView,
  onSuccess
}) => {
  const { t } = useTranslation();
  const [actorForm] = Form.useForm();

  // console.log('actorForm', actorForm)

  const { requestCreateActor, requestUpdateActor } = useContext(ApiContext);

  const creatingFetching = useSelector(isActorCreatedFetching);
  const authBiomeInfo = useSelector(getAuthBiomeInfo);
  const authServiceInfo = useSelector(getAuthServiceInfo);

  const [selectedActorType, setSelectedActorType] = useState('classic_user');

  const tMsg = (path, defaultValue) => capitalize(t(`auth.validation.messages.${path}`, defaultValue || path));
  const getTranslate = (path, defaultValue) => capitalize(t(path, defaultValue || path))

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters')
    },
    pattern: {
      mismatch: tMsg('mismatch_email', 'is not a valid email')
    },
    types: {
      email: tMsg('email', 'is not a valid email'),
      number: tMsg('number', 'must be a number type'),
      url: tMsg('url', 'is not a valid URL')
    }
  };

  const rules = {
    'user': {
      'first_name': [{ max: 200 }, { required: true }],
      'last_name': [{ max: 200 }, { required: true }],
      'email': [{ type: 'email' }, { required: true }],
      'password': [{ min: 4 }, { max: 200 }, { required: true }]
    },
    'group': {
      'group_name': [{ min: 1 }, { max: 200 }, { required: true }],
      'weight': [{ type: 'number' }, { required: true }]
    },
    'service': {
      'service_name': [{ min: 1 }, { max: 200 }, { required: true }],
      'service_domain': [{ type: 'url' }, { required: true }],
      'uuid': [{ pattern: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i }],
      'initial_key': [{ len: 130 }, { required: true }]
    }
  };

  const actorTypes = [
    {
      value: 'classic_user',
      label: getTranslate('auth.headers.classic_user', 'classic_user'),
      color: 'blue',
      iconPath: mdiAccount
    },
    {
      value: 'user',
      label: getTranslate('auth.headers.user', 'user'),
      color: 'geekblue',
      iconPath: mdiShieldAccount
    },
    {
      value: 'group',
      label: getTranslate('auth.headers.group', 'group'),
      color: 'red',
      iconPath: mdiHexagonMultiple
    },
    {
      value: 'service',
      label: getTranslate('auth.headers.service', 'service'),
      color: 'orange',
      iconPath: mdiCog
    }
  ];

  const handleChangeActorType = (e) => {
    const { value } = e.target;
    setSelectedActorType(value);
  };

  const formProps = {
    editMode: true,
    creating: true,
    actorType: selectedActorType,
    actorForm,
    validateMessages
  };


  const encryptUserToBackup = (actor) => {
    const {uuid, name, domain} = authBiomeInfo;
    const {
      uuid: actorUUID,
      private_key,
      initial_key,
      uinfo: {
        first_name,
        last_name,
        email,
      }} = actor;
    // console.log('authBiomeInfo', authBiomeInfo)
    // console.log('authServiceInfo', authServiceInfo)
    // console.log('actor', actor)


    const data = {
      "authority": {
        "uuid": actorUUID,
        "private_key": private_key,
        "public_key": initial_key,
        "apt54": '',
        "login": '',
        "password": '',
      },
      "biom": {
        "authority_uuid": actorUUID,
        uuid,
        name,
        domain,
      },
      "config": {
        "authority_uuid": actorUUID,
        "is_default": false,
        "comment": '',
        "is_classic": false,
        "updated_time": '',
      },
  //     "services": [
  //       {
  //         "service": {
  //
  //           "authority_uuid": '',
  //           "uuid": '',
  //           "name": '',
  //           "domain": '',
  //           "token": '',
  //           "last_token_update_time": '',
  //           "auth_url": '',
  //           "about_url": '',
  //           "depends_on": '',
  //         }
  //   },
  // ],
    "user_info": {
      "authority_uuid": actorUUID,
          first_name,
          last_name,
          email,
          "address": '',
          "phone": '',
          "is_root": false,
    }
  }


    const dataToJSON = JSON.stringify(data);
    // console.log('dataToJSON', dataToJSON)

    // const PRIVATE_KEY = CryptoJS.enc.Hex.parse(private_key);
    // const PRIVATE_KEY = private_key;
    const passToUTF8 = CryptoJS.enc.Utf8.parse("1111111111111111").toString();


    // console.log('passToUTF8', passToUTF8)
    // console.log('PRIVATE_KEY', PRIVATE_KEY)

     const ciphertext = CryptoJS.AES.encrypt(dataToJSON, passToUTF8).toString();

    // console.log('ciphertext', ciphertext)


    const wordArray = CryptoJS.enc.Utf8.parse(ciphertext);
    const BASE64 = CryptoJS.enc.Base64.stringify(wordArray);


    const byteCharacters = atob(BASE64)

    let byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {type:"text/plain;charset=utf-8"})

    saveAs(blob, `${(new Date).getMilliseconds()}.esx54.txt`);

  }

  const checkNewAddedActorsAndUpdateUser = (actorUUID) => {
    const newActorGroups = actorForm.getFieldValue('newActorGroups');
    const newActors = actorForm.getFieldValue('newActors') || 0;

    if(newActorGroups || newActors.length > 0) {
      const data = {
        actor_type: selectedActorType,
        add_actors_list: selectedActorType === 'group' ? newActors : newActorGroups,
        uuid: actorUUID,
      }

      requestUpdateActor(data);
    }
  }

  const createActor = (formData) => {
    let data = {
      'actor_type': selectedActorType,
      uinfo: {
        ...omit(formData, ['users', 'initial_key', 'uuid', 'listingGroupFlag', 'newActorGroups'])
      }
    };

    if (selectedActorType === 'service') {
      const uuid = get(formData, 'uuid');

      data = {
        ...data,
        uinfo: {
          ...data.uinfo,
          'create_without_listing_group': get(formData, 'listingGroupFlag', false)
        },
        'initial_key': get(formData, 'initial_key'),
      };

      if (uuid) {
        data = {
          ...data,
          uuid
        };
      }
    }

    requestCreateActor(data).then((res) => {
      const {actor} = res || {};

        switch (selectedActorType) {
          case 'classic_user':
          case 'user':
            checkNewAddedActorsAndUpdateUser(actor.uuid)
            // encryptUserToBackup(actor)
            break;
          case 'group':
            checkNewAddedActorsAndUpdateUser(actor.uuid)
            break;
          default:
            break;
        }

        antNotification.success(getTranslate('auth.notifications.actor_created', 'actor created successful'));

        actorForm.resetFields();

        if (onSuccess) {
          onSuccess(actor);
        }
      });
  };


  const onSubmitForm = async () => {

    try {
      const formData = await actorForm.validateFields();

      // console.log('newActorGroups', actorForm.getFieldValue('newActorGroups'))

      const cleanData = clearObject(formData);
      const newData = get(cleanData, 'birthday') ?
        {
          ...cleanData,
          birthday: moment(get(cleanData, 'birthday')).format('YYYY-MM-DD')
        } : cleanData;

      createActor({...newData});
    } catch (error) {
      console.log('error', error)
      antNotification.error(getTranslate('auth.notifications.invalid_data', 'invalid data entered'));
    }
  };

  useEffect(() => {
    actorForm.resetFields();
  }, [selectedActorType]);



  const testFunc = () => {


    const data = {
      "authority": {
        "uuid": 'e4e0d65b-1785-453b-bf23-97ce3626d3e9',
        "private_key": '',
        "public_key": '',
        "apt54": '',
        "login": 'testroman',
        "password": '1234',
      },
    }

    const data2 = {
      "authority": {
        "uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "private_key":"6e86236fb4c99ef76f0446d8fcff586a734cfd684b5808746194020b98ba62b1",
        "public_key":"04573a78db0579bb363bddfad9062141cda007a39a89d274975a1181ef118449af46ea35c8df0752befe91aacb1715812d45481ca30112dedc78b9a042fce6f217",
        "login":"testemail@mail.ru",
        "password":"EZjdr6t52ESD7+ka9njSQA\u003d\u003d\n"
      },
      "biom":{
        "authority_uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "uuid":"0d038a0b-f013-4516-b7a2-30cab28c0296",
        "name":"04releasetest",
        "domain":""
      },
      "config":{
        "authority_uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "is_default":false,
        "is_classic":true,
        "updated_time":1660568819680
      },
      "services": [{
        "authority_uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "uuid":"0d038a0b-f013-4516-b7a2-30cab28c0296",
        "name":"auth",
        "domain":"https://auth.04releasetest.54origins.com",
        "token":"ftHIL6BuxdIOEIJfvE7wnMmd7jIQtzBC",
        "last_token_update_time":1660568819680,
        "auth_url":"https://auth.04releasetest.54origins.com/auth/"
      }],
      "user_info":{
        "authority_uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "first_name":"test",
        "last_name":"email",
        "email":"testemail@mail.ru",
        "address":"",
        "phone":"",
        "is_root":false
      }
    }


    const data3 = {
      "authority": {
        "uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "private_key":"6e86236fb4c99ef76f0446d8fcff586a734cfd684b5808746194020b98ba62b1",
        "public_key":"04573a78db0579bb363bddfad9062141cda007a39a89d274975a1181ef118449af46ea35c8df0752befe91aacb1715812d45481ca30112dedc78b9a042fce6f217",
        "login":"testemail@mail.ru",
        "password":""
      },
      "biom":{
        "authority_uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "uuid":"0d038a0b-f013-4516-b7a2-30cab28c0296",
        "name":"04releasetest",
        "domain":""
      },
      "config":{
        "authority_uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "is_default":false,
        "is_classic":true,
        "updated_time":0
      },
      "services": [{
        "authority_uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "uuid":"0d038a0b-f013-4516-b7a2-30cab28c0296",
        "name":"auth",
        "domain":"https://auth.04releasetest.54origins.com",
        "token":"",
        "last_token_update_time":0,
        "auth_url":"https://auth.04releasetest.54origins.com/auth/"
      }],
      "user_info":{
        "authority_uuid":"8910107d-6d04-4575-8908-971c413b9cd1",
        "first_name":"test",
        "last_name":"email",
        "email":"testemail@mail.ru",
        "address":"",
        "phone":"",
        "is_root":false
      }
    }



    const dataToJSON = JSON.stringify(data);
    const passToUTF8 = CryptoJS.enc.Utf8.parse("1111111111111111").toString();


    // console.log('passToUTF8', passToUTF8)
    // console.log('PRIVATE_KEY', PRIVATE_KEY)

    const ciphertext = CryptoJS.AES.encrypt(dataToJSON, passToUTF8).toString();

    // console.log('ciphertext', ciphertext)


    const wordArray = CryptoJS.enc.Utf8.parse(ciphertext);
    const BASE64 = CryptoJS.enc.Base64.stringify(wordArray);


    const byteCharacters = atob(BASE64)

    let byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {type:"text/plain;charset=utf-8"})

    saveAs(blob, `${(new Date).getMilliseconds()}.esx54.txt`);
  }

  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      {/*<button onClick={testFunc}>TEST</button>*/}
      <Spin spinning={creatingFetching}>
        <ActorFormWrapper
            {...formProps}
        >
          <Card className={'card'}>
            <Row>
              <Col span={24}>
                <h4 className={'header-primary'}>
                  {getTranslate('auth.headers.create_actor', 'creating new actor')}
                </h4>
              </Col>
            </Row>
            <hr className={'mt-4 mb-0'} />
            <InfoBlock
                iconPath={mdiCheckboxMultipleMarkedCircleOutline}
                title={getTranslate('auth.headers.choose_actor_type', 'choose actor type')}
            >
              <Radio.Group
                  optionType={'button'}
                  buttonStyle={'solid'}
                  value={selectedActorType}
                  onChange={handleChangeActorType}
              >
                {actorTypes.map((item) => {
                  const active = item.value === selectedActorType;

                  return (
                      <Radio.Button
                          key={`type_${item.value}`}
                          value={item.value}
                          className={`radio-item radio-item-${item.color} ${active ? 'active' : ''}`}
                      >
                        <div className={'d-flex align-items-center'}>
                          <Icon
                              path={item.iconPath}
                              size={1.2}
                              className={'slow mr-2'}
                              rotate={active ? 720 : 0}
                          />
                          {item.label}
                        </div>
                      </Radio.Button>
                  );
                })}
              </Radio.Group>
            </InfoBlock>
            <hr className={'my-4'} />
            <Tabs
                activeKey={selectedActorType}
                tabBarStyle={{ display: 'none' }}
                destroyInactiveTabPane
                animated
            >
              <Tabs.TabPane key={'classic_user'}>
                <UserInfoFormItems
                    {...formProps}
                    rules={rules.user}
                    hideElements={['oldPassword', 'passwordConfirmation']}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key={'user'}>
                <UserInfoFormItems
                    {...formProps}
                    rules={rules.user}
                    hideElements={['oldPassword', 'passwordConfirmation']}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key={'group'}>
                <GroupInfoFormItems {...formProps} rules={rules.group} />
              </Tabs.TabPane>
              <Tabs.TabPane key={'service'}>
                <ServiceInfoFormItems {...formProps} rules={rules.service} />
              </Tabs.TabPane>
            </Tabs>
            <hr className={'my-4'} />
            <Row>
              <Col className={'mr-auto d-flex'}>
                <Form.Item shouldUpdate>
                  {() => (
                      <Button
                          className={'button-primary'}
                          onClick={onSubmitForm}
                          disabled={!actorForm.isFieldsTouched()}
                      >
                        {getTranslate('auth.buttons.create_actor', 'create actor')}
                      </Button>
                  )}
                </Form.Item>
                <Button
                    className={'button-secondary-outlined ml-2'}
                    onClick={switchView}
                >
                  {getTranslate('auth.buttons.cancel', 'cancel')}
                </Button>
              </Col>
            </Row>
          </Card>
        </ActorFormWrapper>
      </Spin>
    </IStickyBox>
  );
};

export default ActorCreate;

ActorCreate.propTypes = {
  onSuccess: PropTypes.func,
  switchView: PropTypes.func.isRequired
};
