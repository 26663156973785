import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import IStickyBox from 'react-sticky-box';
import { Button, Card, Col, Form, Row, Skeleton, Space, Spin } from 'antd';
import { antNotification, capitalize, textToClipboard } from '../../utils';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiClose, mdiContentCopy, mdiContentSaveOutline, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import DeleteButton from '../../components/DeleteButton';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import InterfaceForm from './InterfaceForm';
import { getInterfaceData, getInterfaceFetching } from '../selectors';
import InterfaceIcon from './interfaceIcon';
import { ApiContext } from '../../api/ApiContextProvider';


const InterfaceInfo = ({
  onSuccessDelete,
  interfaceID,
  onSuccessUpdate
}) => {
  const { t } = useTranslation();
  const [interfaceForm] = Form.useForm();
  const { requestGetInterface, requestUpdateInterface } = useContext(ApiContext);

  const defaultInterfaceData = useSelector(getInterfaceData);
  const interfaceFetching = useSelector(getInterfaceFetching);

  const [editMode, setEditMode] = useState(false);
  const initInterfaceData = {
    icon: 'mdi-cube',
    color: 'DARK'
  };

  const [interfaceData, setInterfaceData] = useState(initInterfaceData);

  const onSetEditMode = (editState) => {
    setEditMode(editState);
  };

  const setInterfaceFormData = () => {
    interfaceForm.setFieldsValue({
      ...defaultInterfaceData,
      'params': JSON.stringify(defaultInterfaceData.params, null, 4)
    });

    setInterfaceData({
      icon: _.get(defaultInterfaceData, 'service_icon', initInterfaceData.icon),
      color: _.get(defaultInterfaceData, 'icon_color', initInterfaceData.color)
    });
  };

  const onCancel = () => {
    setEditMode(false);
    interfaceForm.resetFields();
    setInterfaceFormData();
  };

  const initFunc = () => {
    if (interfaceID) {
      interfaceForm.resetFields();
      setInterfaceData(initInterfaceData);
      requestGetInterface(interfaceID).then();
    }
  };

  const onSubmitForm = async () => {
    const {
      icon,
      color
    } = interfaceData;

    try {
      const formData = await interfaceForm.validateFields();

      const data = {
        ...defaultInterfaceData,
        ...formData,
        'service_icon': icon,
        'icon_color': color,
        'params': JSON.parse(formData.params)
      };

      requestUpdateInterface(data).then(() => {
        antNotification.success(
          capitalize(t('auth.notifications.updated_successfully', 'updated successfully')));

        setEditMode(false);

        if (onSuccessUpdate) {
          onSuccessUpdate();
        }
      });
    } catch (error) {
      antNotification.error(capitalize(t('auth.notifications.invalid_data', 'invalid data entered')));
    }
  };

  useEffect(() => {
    if (defaultInterfaceData.id) {
      setInterfaceFormData();
    }
  }, [defaultInterfaceData]);


  useEffect(() => {
    setEditMode(false);
    initFunc();
  }, [interfaceID]);


  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Spin
        spinning={interfaceFetching || !defaultInterfaceData.id}
      >
        <Card className={'card border-blue'}>
          <Row gutter={[16, 0]} wrap={false}>
            <Col flex={'none'}>
              <h4 className={'header-primary'}>
                {capitalize(t('auth.headers.interface_info', 'interface info'))}
              </h4>
            </Col>
            <Col flex={'auto'} className={'d-flex justify-content-end'}>
              <Space size={[5, 1]} wrap className={'justify-content-end'}>
                {editMode ? (
                  <>
                    <Button
                      className={'button-secondary-outlined'}
                      size={'small'}
                      onClick={onCancel}
                    >
                      <Icon path={mdiClose} size={1} className={'mr-1'} />
                      {capitalize(t('auth.buttons.cancel', 'cancel'))}
                    </Button>
                    <Button
                      className={'button-primary'}
                      size={'small'}
                      onClick={onSubmitForm}
                    >
                      <Icon path={mdiContentSaveOutline} size={1} className={'mr-1'} />
                      {capitalize(t('auth.buttons.save', 'save'))}
                    </Button>
                  </>
                ) : (
                  <Button
                    className={'button-primary-outlined'}
                    size={'small'}
                    onClick={() => onSetEditMode(true)}
                  >
                    <Icon path={mdiSquareEditOutline} size={1} className={'mr-1'} />
                    {capitalize(t('auth.buttons.edit', 'edit'))}
                  </Button>
                )}
                <DeleteButton
                  targetType={'public_interface'}
                  onSuccess={onSuccessDelete}
                  data={{ id: interfaceID }}
                >
                  <Button
                    className={'button-danger-outlined'}
                    size={'small'}
                  >
                    <Icon path={mdiTrashCanOutline} size={1} className={'mr-1'} />
                    {capitalize(t('auth.buttons.remove_interface', 'remove interface'))}
                  </Button>
                </DeleteButton>
              </Space>
            </Col>
          </Row>
          <hr className={'my-4'} />
          <Row gutter={[8, 0]}>
            <Col>
              <InterfaceIcon
                iconName={_.get(defaultInterfaceData, 'service_icon')}
                color={_.get(defaultInterfaceData, 'icon_color')}
                size={1.6}
              />
            </Col>
            <Col>
              <Row>
                <Col span={24}>
                  <Skeleton
                    loading={interfaceFetching || !defaultInterfaceData.id}
                    paragraph={false}
                    title={{
                      width: '50%',
                      style: {
                        margin: 0,
                        height: 24
                      }
                    }}
                    active
                    round
                  >
                    <h3 className={'header-primary m-0'}>
                      {_.get(defaultInterfaceData, 'service_name', '')}
                    </h3>
                  </Skeleton>
                </Col>
                <Col span={24} className={'d-flex align-items-center mt-2'}>
                  <h5 className={'header-primary'}>
                    ID: {interfaceID}
                  </h5>
                  <Button
                    className={'button-primary-link ml-2'}
                    size={'small'}
                    onClick={() => textToClipboard(interfaceID)}
                  >
                    <Icon path={mdiContentCopy} size={1} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr className={'mt-4 mb-0'} />
          <InterfaceForm
            form={interfaceForm}
            interfaceData={interfaceData}
            setInterfaceData={setInterfaceData}
            editMode={editMode}
          />
        </Card>
      </Spin>
    </IStickyBox>
  );
};

export default InterfaceInfo;

InterfaceInfo.propTypes = {
  interfaceID: PropTypes.number.isRequired,
  onSuccessDelete: PropTypes.func.isRequired,
  onSuccessUpdate: PropTypes.func
};
