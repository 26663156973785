import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Modal, Tag} from "antd";
import Icon from "@mdi/react";
import { mdiPlusCircleOutline} from "@mdi/js";

import ModalListOfActors from "./ModalListOfActors";
import ListOfActorsTags from "./ListOfActorsTags";

import {antNotification, stopPropagation} from "../../utils";
import {ApiContext} from "../../api/ApiContextProvider";


const ActorsModalBtn = ({
    actorType,
    actorUUID,
    btnModalLabel,
    currentActors = [],
    updateActorCallback,
    doNotMakeRequest = [],
    hideElements = [],
    iconPath = mdiPlusCircleOutline,
    listOfActorsActionBtnLabel,
    getSelectedActorsAfterSave,
    openModalFromOuter,
    modalTitle,
    onCancelModalCallback,
    onOkModalCallback,
}) => {
    const { requestUpdateActor, getActors } = useContext(ApiContext);

    const defaultState = {
        modalActors: [],
        addActorsList: [],
        removeActorsList: [],
    }

    const [modalVisible, changeModalVisible] = useReducer((state) => !state, false);
    const [state, setState] = useState(defaultState)

    const {
        modalActors,
        addActorsList,
        removeActorsList,
    } = state || {};

    const localActorsIsChanged = addActorsList.length !== 0 || removeActorsList.length !== 0;

    const changeState = (params) => {
        setState((prev) => ({...prev, ...params}))
    }

    const getSelectedActors = (actors) => {
        const actorsUUIDs = actors.map(item => item.uuid)

        changeState({
            modalActors: [...modalActors, ...actors],
            addActorsList: [...addActorsList, ...actorsUUIDs]
        })

    }

    const getDataForListOfActorsRequest = () => {
        switch (actorType) {
            case 'user':
            case 'classic_user':
            case 'service':
                return {
                'actor_type': ['group'],
                'uuid__not': modalActors.map(({uuid}) => uuid)
            }
            break;
            case 'group':
            case 'newGroup':
                return {
                    'actor_type': ['user', 'classic_user'],
                    'uuid__not': modalActors.map(({uuid}) => uuid)
            }
                break;
            default:
                break;
        }
    }

    const onOkModal = () => {
        changeModalVisible()

        if(onOkModalCallback) {
            onOkModalCallback()
        }
    }

    const removeModalActors = (removeUUID) => {
        // const removeUUID = event?.currentTarget?.attributes?.uuid?.value || '';

        changeState({
            modalActors: modalActors.filter(({uuid}) => uuid !== removeUUID),
            removeActorsList: [...removeActorsList, removeUUID],
        })
    }

    const getActorsRequest = () => {
        const data = {}

        switch (actorType) {
            case 'user':
            case 'classic_user':
            case 'service':
                if(currentActors.length !== 0) {
                    data.actor_type = ['group']
                    data.uuid = currentActors

                    return getActors(data, [])
                }
                break;
            case 'group':
                data.uinfo = {}
                data.uinfo.groups = [actorUUID]
                return getActors(data, [])

            case 'newGroup':
                if(currentActors.length !== 0) {
                    data.actor_type = ['user', 'classic_user']
                    data.uuid = currentActors
                    return getActors(data, [])
                }
                break;
            default:
                break;
        }
    }

    const onCancel = () => {
        changeModalVisible()

        changeState(defaultState)

        if(onCancelModalCallback) {
            onCancelModalCallback()
        }
    }

    const onSave = () => {
        if(!doNotMakeRequest.includes('saveActors') && localActorsIsChanged) {
            requestUpdateActor({
                actor_type: actorType,
                add_actors_list: addActorsList,
                remove_actors_list: removeActorsList,
                uuid: actorUUID
            }).then(() => {
                antNotification.success('Success')

                if(updateActorCallback) {
                    updateActorCallback()
                }
            })
        }

        onCancel();

        if(getSelectedActorsAfterSave) {
            getSelectedActorsAfterSave(modalActors)
        }
    }

    const showModal = async (e) => {
        e?.stopPropagation();

        if(!doNotMakeRequest.includes('getModalSelectedActors')) {
            const actorsData = await getActorsRequest();

            const {
                actors = []
            } = actorsData || {};


            changeState({modalActors: actors})
        }

        changeModalVisible();
    }

    const getBtnModalLabel = () => {
        switch (actorType) {
            case 'user':
            case 'classic_user':
            case 'service':
                return 'groups'
            case 'group':
            case 'newGroup':
                return 'users'
            default:
                return 'actor'
        }
    }

    const getListOfActorsActionBtnLabel = () => {
        switch (actorType) {
            case 'user':
            case 'classic_user':
            case 'service':
                return 'add group'
            case 'group':
            case 'newGroup':
                return 'add user'
            default:
                return 'add actor'
        }
    }

    const getModalTitle = () => {
        switch (actorType) {
            case 'user':
            case 'classic_user':
                return 'User groups'
            case 'service':
                return 'Service groups'
            case 'group':
            case 'newGroup':
                return 'Users'
            default:
                return 'add actor'
        }
    }


    useEffect(() => {
        if(openModalFromOuter) {
            showModal()
        }
    }, [openModalFromOuter])

    return(
       <div
           onClick={stopPropagation}
           onDoubleClick={stopPropagation}
       >
           <Modal
               title={modalTitle || getModalTitle()}
               open={modalVisible}
               onOk={onOkModal}
               onCancel={onCancel}
               destroyOnClose={true}
               width={'40%'}
               centered={true}
               footer={[
                   <Button
                       key="back"
                       onClick={onCancel}
                   >
                       Cancel
                   </Button>,
                   <Button
                       key="submit"
                       type="primary"
                       onClick={onSave}
                       disabled={!localActorsIsChanged}
                   >
                      Save
                   </Button>
               ]}
           >
               <>
                   <ListOfActorsTags
                       actors={modalActors}
                       getRemovedUserCallback={removeModalActors}
                       isModal
                   />
                   <ModalListOfActors
                       getSelectedActors = {getSelectedActors}
                       listOfActorsActionBtnLabel={listOfActorsActionBtnLabel || getListOfActorsActionBtnLabel()}
                       dataForActorsRequest={getDataForListOfActorsRequest()}
                   />
               </>
           </Modal>
           {!hideElements.includes('mainBtn') &&
           <Button
               type="default"
               onClick={showModal}
               size={'small'}
               className={'button-primary-outlined actorsModalBtn'}
           >
               {!hideElements.includes('btnIcon') &&
               <Icon
                   size={1}
                   className={'mr-1'}
                   path={iconPath}
               />
               }
               <span>{btnModalLabel || getBtnModalLabel()}</span>
           </Button>
           }
       </div>
    )
}

export default ActorsModalBtn;
