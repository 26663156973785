import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { COLORS, ICONS } from '../constants/icons';


const InterfaceIcon = ({
  iconName,
  color = 'dark',
  size
}) => {
  return (
    <Icon
      className={`icon-shadow-${(color || 'gray').toLowerCase()}`}
      path={ICONS[iconName] || ICONS['mdi-cube']}
      color={COLORS[color] || COLORS.GRAY}
      size={size || 1}
    />
  );
};

export default InterfaceIcon;

InterfaceIcon.propTypes = {
  color: PropTypes.string,
  iconName: PropTypes.string,
  size: PropTypes.number
};
