import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'antd';
import { capitalize } from '../utils';


const VersionLabel = ({
  children,
  version
}) => {
  const getColor = () => {
    switch (version) {
      case 'beta':
        return '#52c41a';
      case 'alpha':
        return '#1890ff';
      case 'new':
        return '#f5222d';
      default:
        return '#1890ff';
    }
  };

  if (window.location.hostname !== 'localhost' && version === 'alpha') {
    return null;
  }

  return (
    <>
      {version && version !== 'prod' ? (
        <Badge.Ribbon
          text={capitalize(version)}
          color={getColor()}
        >
          {children}
        </Badge.Ribbon>
      ) : children}
    </>

  );
};

export default VersionLabel;

VersionLabel.propTypes = {
  children: PropTypes.element,
  version: PropTypes.string
};
