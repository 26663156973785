import keyMirror from 'keymirror';


export const CertificatesConstants = keyMirror({
  GET_CERTIFICATES_REQUEST: null,
  GET_CERTIFICATES_SUCCESS: null,
  GET_CERTIFICATES_FAILURE: null,

  CREATE_CERTIFICATES_REQUEST: null,
  CREATE_CERTIFICATES_SUCCESS: null,
  CREATE_CERTIFICATES_FAILURE: null,

  DELETE_CERTIFICATES_REQUEST: null,
  DELETE_CERTIFICATES_SUCCESS: null,
  DELETE_CERTIFICATES_FAILURE: null
});
