import PropTypes from 'prop-types';
import {Form} from 'antd';

import UserInfoFormItems from "./UserInfoFormItems";




const ActorFormWrapper = (props) => {
  const {
    actorForm,
    validateMessages,
    initialValues = [],
      children,
  } = props;

  return (
      <Form
          layout={'vertical'}
          autoComplete={'off'}
          form={actorForm}
          validateMessages={validateMessages}
          initialValues = {initialValues}
      >
        {children}
      </Form>
  );
};

export default ActorFormWrapper;

ActorFormWrapper.propTypes = {
  actorForm: PropTypes.object.isRequired,
  actorType: PropTypes.string.isRequired,
  creating: PropTypes.bool,
  editMode: PropTypes.bool,
  isProfile: PropTypes.bool,
  rules: PropTypes.object,
  validateMessages: PropTypes.object
};
