import PropTypes from 'prop-types';
import React from 'react';
import {mdiAccountGroupOutline, mdiHelpCircle, mdiNoteEditOutline, mdiTextBoxMultipleOutline} from '@mdi/js';
import { Col, Form, Input, InputNumber, Row, Tooltip } from 'antd';
import Icon from '@mdi/react';

import InfoBlock from '../../../components/InfoBlock';
import CollapseGroupsForActor from "../CollapseGroupsForActor";
import AddActorsForNewActor from "../AddActorsForNewActor";

import { capitalize } from '../../../utils';
import { useTranslation } from 'react-i18next';



const GroupInfoFormItems = (props) => {
  const {
    actorData,
    actorForm,
    editMode,
    rules,
    creating,
  } = props;

  const { t } = useTranslation();

  const { setFieldsValue } = actorForm;

  const getSelectedActors = (actors) => {
    setFieldsValue({
      newActors: actors.map((item) => item.uuid),
    })
  }

  const weightLabel = (
    <Tooltip
      title={capitalize(t('auth.tooltips.help_weight'))}
    >
      <div className={'d-flex align-items-center'}>
        {capitalize(t('auth.headers.weight', 'weight'))}
        <Icon path={mdiHelpCircle} size={1} className={'ml-1'} />
      </div>
    </Tooltip>
  );


  return (
    <>
      <InfoBlock
          iconPath={mdiTextBoxMultipleOutline}
          title={capitalize(t('auth.headers.group_detail', 'group detail'))}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
                label={capitalize(t('auth.headers.group_name', 'group name'))}
                name={'group_name'}
                rules={rules.group_name}
            >
              <Input
                  placeholder={
                    capitalize(t('auth.placeholders.enter', {value: t('auth.headers.group_name')}))
                  }
                  disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
                label={weightLabel}
                name={'weight'}
                rules={rules.weight}
            >
              <InputNumber
                  className={'w-100'}
                  placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.group_weight') }))}
                  min={1}
                  max={4294967297}
                  disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
                label={capitalize(t('auth.headers.description', 'description'))}
                name={'description'}
                rules={rules.description}
            >
              <Input.TextArea
                  placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.description') }))}
                  autoSize={{
                    minRows: 3,
                    maxRows: 5
                  }}
                  disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
      {creating ?
          <AddActorsForNewActor
              actorType={'newGroup'}
              btnModalLabel={'add users'}
              getSelectedActors={getSelectedActors}
          /> :
          <CollapseGroupsForActor
              actorData={actorData}
              btnModalLabel={'add or remove users'}
              iconModalPath={mdiAccountGroupOutline}
          />
      }
      <InfoBlock
          iconPath={mdiNoteEditOutline}
          title={capitalize(t('auth.headers.comment', 'comment'))}
      >
        <Row>
          <Col span={24}>
            <Form.Item
                name={'comment'}
                rules={rules.comment}
            >
              <Input.TextArea
                  placeholder={capitalize(t('auth.placeholders.enter', {value: t('auth.headers.comment')}))}
                  autoSize={{
                    minRows: 3,
                    maxRows: 5
                  }}
                  disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
    </>
  );
};

export default GroupInfoFormItems;

GroupInfoFormItems.propTypes = {
  actorForm: PropTypes.object.isRequired,
  actorUUID: PropTypes.string,
  editMode: PropTypes.bool,
  rules: PropTypes.object,
  validateMessages: PropTypes.object
};
