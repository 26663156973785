import React, {useEffect, useState} from 'react';
import {Button, Select} from "antd";



const ChangeTypeOfUserBtn = ({
    changeTypeOfUserCallback,
    defaultUserType,
}) => {

    const [localUserType, setLocalUserType] = useState(defaultUserType);

    const onChange = (value) => {
        setLocalUserType(value)

        if(changeTypeOfUserCallback) {
            changeTypeOfUserCallback(value)
        }
    }

    useEffect(() => {
        setLocalUserType(defaultUserType)
    }, [defaultUserType])

    return(
        <Select
            defaultValue={localUserType}
            onChange={onChange}
        >
            <Select.Option
                value={localUserType === 'user' ? "classic_user" : "user"}
            >
                {localUserType === 'user' ? "classic user" : "user"}
            </Select.Option>
        </Select>
    )
}

export default ChangeTypeOfUserBtn;
