import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Skeleton, Space, Spin, Tag } from 'antd';
import { antNotification, capitalize, clearObject, textToClipboard } from '../../utils';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiClose,
  mdiContentCopy,
  mdiContentSaveOutline,
  mdiPhone,
  mdiHexagonMultipleOutline,
  mdiSquareEditOutline
} from '@mdi/js';
import { useSelector } from 'react-redux';
import {get, omit} from 'lodash';
import moment from 'moment';

import ActorFormWrapper from "../../actors/components/infoForms/ActorFormWrapper";
import UserInfoFormItems from "../../actors/components/infoForms/UserInfoFormItems";
import InfoBlock from '../../components/InfoBlock';
import GroupTags from '../../components/GroupTags';
import PageWrapper from '../../components/PageWrapper';

import {getProfileInfo, isProfileFetching} from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';




const ProfilePage = () => {
  const { t } = useTranslation();
  const [actorForm] = Form.useForm();

  const {
    requestGetProfile,
    requestUpdateProfile,
    requestUpdateUserPassword,
    requestGetAllGroups,
    requestGetMyPermissions
  } = useContext(ApiContext);

  const { actor: profileInfo } = useSelector(getProfileInfo);
  const profileFetching = useSelector(isProfileFetching);

  console.log('profileFetching', profileFetching)

  const [editMode, setEditMode] = useState(false);

  const {
    uuid: actorUUID,
    actor_type,
    uinfo: {
      birthday = null,
      email = '',
      first_name = '',
      last_name = '',
      login = '',
      group_names = [],
      phone_number = '',
    } = {},
  } = profileInfo || {};

  const profileInitialValues = {
    birthday: birthday ? moment(birthday, 'YYYY-MM-DD') : null,
    email,
    first_name,
    last_name,
    login,
  }

  const tMsg = (
    path,
    defaultValue
  ) => capitalize(t(`auth.validation.messages.${path}`, defaultValue || path));

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters')
    },
    types: {
      email: tMsg('email', 'is not a valid email')
    }
  };

  const rules = {
    'first_name': [{ max: 200 }],
    'last_name': [{ max: 200 }],
    'email': [{ type: 'email' }, { required: true }],
    'password': [{ min: 4 }, { max: 200 }],
    'new_password': [
      // {
      //   validator: async (_, names) => {
      //     console.log('names', names)
      //     if (!names || names.length < 2) {
      //       return Promise.reject(new Error('At least 2 passengers'));
      //     }
      //   },
      // },
      { min: 6 },
      { max: 200 }
    ],
    'password_confirmation': '',
    'initial_key': [{ len: 130 }]
  };

  const setDataForm = () => {
    const newData = {
      'birthday': birthday ? moment(birthday, 'YYYY-MM-DD') : null,
      'first_name': first_name,
      'last_name': last_name,
      'email':email,
      'groups': group_names
    };

    actorForm.setFieldsValue(profileInitialValues);
  };

  const onSubmitFunc = async () => {
    const changedInputs = actorForm.getFieldsValue(null, meta => meta.touched);

    const {
      birthday,
      email,
      first_name,
      last_name,
      password,
      login,
      old_password,
      password_confirmation,
      typeOfUser: actor_type
    } = changedInputs;

    try {
      await actorForm.validateFields();

      const data = {
        ...profileInfo.uinfo,
        ...(first_name ? { first_name } : {}),
        ...(last_name ? { last_name } : {}),
        ...(email ? { email } : {}),
        ...(login ? { login } : {}),
        ...(birthday ? { birthday: moment(birthday).format('YYYY-MM-DD') } : {}),
      }

      delete data?.groups
      delete data?.password
      // delete data?.phone_number

      if(birthday) {
        data.birthday = moment(birthday).format('YYYY-MM-DD')
      }

      requestUpdateProfile(data).then(() => {
        antNotification.success(capitalize(t('auth.notifications.updated_successfully', 'updated successfully')));
      });

      if(password && old_password && password_confirmation) {
        requestUpdateUserPassword({
          password,
          old_password,
          password_confirmation
        }).then(() => {
          antNotification.success(capitalize(t(
              'auth.notifications.updated_successfully',
              'password successfully updated'
          )));
          actorForm.resetFields(['password', 'old_password', 'password_confirmation']);
        })
      }

      setEditMode(false);
    } catch (e) {
      antNotification.error(capitalize(t('auth.notifications.invalid_data', 'invalid data entered')));

      setEditMode(false);
      actorForm.resetFields();
      actorForm.setFieldsValue(profileInitialValues);
    }
  }

  const onCancel = () => {
    setEditMode(false);
    actorForm.resetFields();
    setDataForm();
  };

  const rootTag = (
    <>
      {get(profileInfo, 'root_perms_signature', false) && (
        <Tag className={'tag-purple ml-3'}>
          ROOT
        </Tag>
      )}
    </>
  );

  const initFunc = () => {
    requestGetProfile();
    requestGetAllGroups();
    requestGetMyPermissions();
  };

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <PageWrapper
      title={capitalize(t('auth.pages.profile', 'profile'))}
    >
      <Row gutter={[24, 16]}>
        <Col span={14}>
          <Spin
            spinning={false}
          >
            <Card className={'card'}>
              <Row gutter={[16, 0]} wrap={false}>
                <Col flex={'none'}>
                  <h4 className={'header-primary'}>
                    {capitalize(t('auth.headers.user_info', 'user info'))}
                  </h4>
                </Col>
                <Col flex={'auto'} className={'d-flex justify-content-end'}>
                  <Space size={[5, 1]} wrap className={'justify-content-end'}>
                    {editMode && (
                      <>
                        <Button
                          className={'button-secondary-outlined'}
                          size={'small'}
                          onClick={onCancel}
                        >
                          <Icon path={mdiClose} size={1} className={'mr-1'} />
                          {capitalize(t('auth.buttons.cancel', 'cancel'))}
                        </Button>
                        <Button
                          className={'button-primary'}
                          size={'small'}
                          onClick={onSubmitFunc}
                        >
                          <Icon path={mdiContentSaveOutline} size={1} className={'mr-1'} />
                          {capitalize(t('auth.buttons.save', 'save'))}
                        </Button>
                      </>
                    )}
                    {!editMode && (
                      <Button
                        className={'button-primary-outlined'}
                        size={'small'}
                        onClick={() => setEditMode(true)}
                      >
                        <Icon path={mdiSquareEditOutline} size={1} className={'mr-1'} />
                        {capitalize(t('auth.buttons.edit', 'edit'))}
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>
              <hr className={'my-4'} />
              <Row>
                <Col span={24} className={'d-flex align-items-center'}>
                  <Skeleton
                    loading={false}
                    paragraph={false}
                    title={{
                      width: '50%',
                      style: {
                        margin: 0,
                        height: 24
                      }
                    }}
                    active
                    round
                  >
                    <h3 className={'header-primary m-0'}>
                      {first_name + ' ' + last_name}
                    </h3>
                    {rootTag}
                  </Skeleton>
                </Col>
                <Col span={24} className={'d-flex align-items-center mt-2'}>
                  <h5 className={'header-primary'}>
                    UUID: {actorUUID}
                  </h5>
                  <Button
                    className={'button-primary-link ml-2'}
                    size={'small'}
                    onClick={() => textToClipboard(actorUUID)}
                  >
                    <Icon path={mdiContentCopy} size={1} />
                  </Button>
                </Col>
              </Row>
              <hr className={'my-4'} />
              {!profileFetching &&
                <ActorFormWrapper
                  actorForm={actorForm}
                  validateMessages={validateMessages}
                  initialValues={profileInitialValues}
                >
                  <UserInfoFormItems
                      hideElements={['actorsModal', 'phone']}
                      actorData={profileInfo}
                      actorForm={actorForm}
                      actorType={actor_type}
                      actorUUID = {actorUUID}
                      editMode={editMode}
                      isProfile
                      rules={rules}
                      validateMessages={validateMessages}
                  />
                </ActorFormWrapper>
              }

              {phone_number &&
                <InfoBlock
                    iconPath={mdiPhone}
                    title={capitalize(t('auth.headers.phone_number', 'phone number'))}
                >
                  {phone_number}
                </InfoBlock>
              }
              <InfoBlock
                iconPath={mdiHexagonMultipleOutline}
                title={capitalize(t('auth.headers.user_groups', 'user groups'))}
              >
                <GroupTags
                  groupNames={get(profileInfo, 'group_names', [])}
                />
              </InfoBlock>
            </Card>
          </Spin>
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default ProfilePage;
