import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Modal,
  Button
} from 'antd';
import _, {get} from 'lodash';
import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IStickyBox from 'react-sticky-box';

import MasqueradingModal from '../../components/MasqueradingModal';
import ProgressModal from '../../components/ProgressModal';
import PermissionsPage from '../../permissions/components/PermissionsPage';
import ActorCreate from './ActorCreate';
import ActorInfo from './ActorInfo';
import ActorsList from './ActorsList';
import ActorsListHeader from './ActorsListHeader';
import ListOfGroupsAsTagsInModal from "./ListOfGroupsAsTagsInModal";
import SelectedGroupsTable from "./SelectedGroupsTable";

import { ApiContext } from '../../api/ApiContextProvider';
import {
  getMeIsAdmin,
  getMeIsMajorGroup,
  getMeIsRoot
} from '../../profile/selectors';
import {
  capitalize,
  paramsURL
} from '../../utils';
import {
  getActorsInfo,
  getAllActors,
} from '../selectors';


const ActorsPage = () => {
  const { t } = useTranslation();

  const {
    requestGetAllActors,
    requestGetAllGroups,
    requestDeleteActor
  } = useContext(ApiContext);

  const dataFromUrl = useSelector(paramsURL.get);
  const allActors = useSelector(getAllActors);
  const actorData = useSelector(getActorsInfo);
  const meIsRoot = useSelector(getMeIsRoot);
  const meIsAdmin = useSelector(getMeIsAdmin);
  const myMajorGroup = useSelector(getMeIsMajorGroup);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    view,
    type,
    uuid,
    actor_type: actors,
    groups,
    root
  } = dataFromUrl;

  const [tableData, setTableData] = useState([]);
  const [ignoreSearchCase, setIgnoreSearchCase] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [masqueradingUser, setMasqueradingUser] = useState('');

  const [selectedActors, setSelectedActors] = useState(new Set());
  const [showDeletionProgress, setShowDeletionProgress] = useState(false);
  const [deletionProgress, setDeletionProgress] = useState(0);

  const [newCurrentPage, changeCurrentPage] = useState(null)
  const [actorsCount, changeActorsCount] = useState(0)
  const [requestFilters, changeRequestFilters] = useState({
    actorsFilter: [],
    groupsFilter: [],
    root,
  })

  const [groupTags, changeGroupTags] = useState([])

  const {
    actorsFilter,
    groupsFilter,
    root: requestFilterRoot
  } = requestFilters;

  const isPermsView = dataFromUrl.view === 'permissions';
  const isCreateView = dataFromUrl.view === 'create';
  const isInfoView = dataFromUrl.view === 'info';

  const switchView = (view = '', uuid = '') => {
    if (dataFromUrl.uuid !== uuid || view !== dataFromUrl.view) {
      paramsURL.set({
        view,
        uuid
      });
    }
  };

  const formattedTableData = (data) => data.map((item) => {
    const {
      actor_type: type,
      uuid,
      created,
      root_perms_signature: root
    } = item;

    const getInfo = (path, value = '') => get(item, `uinfo.${path}`, value);

    const actorData = {
      uuid,
      type,
      created,
      root,
      weight: getInfo('weight', 1),
      comment: getInfo('comment')
    };

    switch (type) {
      case 'user':
      case 'classic_user':
        return {
          ...actorData,
          name: `${getInfo('first_name')} ${getInfo('last_name')}`,
          groups: getInfo('groups', [])
        };

      case 'group':
        const name = getInfo('group_name', '');

        return {
          ...actorData,
          name,
          default: ['ADMIN', 'DEFAULT', 'BAN'].includes(name)
        };

      case 'service':
        return {
          ...actorData,
          name: getInfo('service_name'),
          groups: getInfo('groups', [])
        };

      default:
        return item;
    }
  });

  const filterTableData = () => {
    let data = [];

    data = formattedTableData([...allActors]);

    setTableData(data);
  };

  const handleSelectedFilters = (filters = []) => {

    if(filters.findIndex(item => item.value === 'addMore') !== -1) {
      setIsModalVisible(true)
    }

    let newFilters = filters.filter(item => item.value !== 'addMore')

    filterTableData();

    const filtersToUrl = (filterKey) => newFilters
      .filter(({ key }) => key === filterKey)
      .map(({ value }) => value);

    const actorsFilter = filtersToUrl('actor_type');
    const groupsFilter = filtersToUrl('uinfo.groups');
    const rootFilter = filtersToUrl('is_root');

    paramsURL.set({
      'actor_type': actorsFilter,
      'groups': groupsFilter,
      'root': rootFilter
    });

    const requestFiltersData = {
      actorsFilter,
      groupsFilter,
      root: _.head(rootFilter)
    };

    // console.log('requestFiltersData', requestFiltersData)

   changeCurrentPage(1)
   changeRequestFilters(requestFiltersData)
  };

  const selectActor = (view, type, uuid) => {
    paramsURL.set({
      view,
      type,
      uuid
    });
  };

  const afterDeleteActor = () => {
    setDeletionProgress(0);
    setSelectedActors(new Set());
    setShowDeletionProgress(false);
    switchView();
  };

  const afterCreateActor = (actor) => {
    selectActor('info', actor.actor_type, actor.uuid);

    getActorsAndCount()
  };

  const initFromURL = () => {
    const getFilters = (filters, key) => {
      if (_.isArray(filters)) {
        return filters.map((item) => (
          {
            key,
            value: item
          }
        ));
      } else if (filters) {
        return [
          {
            key,
            value: filters
          }
        ];
      }
      return [];
    };

    const filters = [
      ...getFilters(actors, 'actor_type'),
      ...getFilters(groups, 'uinfo.groups'),
      ...getFilters(root, 'is_root')
    ];

    if (uuid) {
      selectActor(view, type, uuid);
    }

    if (filters.length > 0) {
      handleSelectedFilters(filters);
    } else {
      handleSelectedFilters();
    }
  };

  const deleteSomeActors = async (uuids) => {
    setShowDeletionProgress(true);
    for (let index = 0; index < uuids.length; index = index + 1) {
      await requestDeleteActor(uuids[index]);
      setDeletionProgress(index + 1);

      if (index + 1 === uuids.length) {
        afterDeleteActor();
      }
    }
  };

  const onDeleteSomeActors = () => {
    Modal.confirm({
      title: capitalize(t(
        'auth.buttons.remove_selected_actors',
        'remove selected actors',
      )),
      content: capitalize(t(
        'auth.messages.delete_sure_actors',
        'are you sure you want to delete this actors?',
      )),
      maskClosable: true,
      centered: true,
      okText: capitalize(t('auth.buttons.remove', 'remove')),
      okType: 'danger',
      okButtonProps: {
        className: 'button-danger'
      },
      cancelText: capitalize(t('auth.buttons.cancel', 'cancel')),
      cancelButtonProps: {
        className: 'button-secondary-outlined'
      },
      onOk() {
        deleteSomeActors([...selectedActors]);
      }
    });
  };

  const onSelectActors = (value) => {
    if (value === 'all') {
      setSelectedActors((prev) => {
        if (prev.size !== tableData.length) {
          return new Set(tableData.map(({ uuid }) => uuid));
        } else {
          return new Set();
        }
      });
    } else {
      setSelectedActors((prev) => {
        if (prev.has(value)) {
          prev.delete(value);
        } else {
          prev.add(value);
        }
        return new Set(prev);
      });
    }
  };

  const breadCrumbsRender = () => {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          {isPermsView ? (
            <a onClick={() => switchView('info', dataFromUrl.uuid)}>
              {capitalize(t('auth.pages.actors', 'actors'))}
            </a>
          ) : (
            <>
              {capitalize(t('auth.pages.actors', 'actors'))}
            </>
          )}
        </Breadcrumb.Item>
        {isPermsView && (
          <Breadcrumb.Item>
            {capitalize(t('auth.headers.permissions', 'permissions'))}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    );
  };

  const getActorsAndCount = async (params) => {
    console.log('getActorsAndCount')
    // const {actorsFilter, groupsFilter, root} = requestFilters;

    const filterData = {};

    if (!_.isEmpty(actorsFilter)) {
      if(actorsFilter.includes('user')) {
        filterData.actor_type = ['classic_user', ...actorsFilter]
      } else {
        filterData.actor_type = actorsFilter;
      }
    }

    if (!_.isEmpty(groupsFilter)) {
      filterData.uinfo = {
        groups: groupsFilter
      }
    }

    if (_.head(requestFilterRoot)) {
      filterData.is_root = true;
    }

    const data = {
      ...params,
      ...filterData
    }


    if(searchValue) {
      data.search_data = {
        value: searchValue,
        ignore_case: ignoreSearchCase,
        fields:
            {
              'base': ['uuid'],
              'uinfo': ['first_name', 'last_name', 'group_name', 'service_name']
            }
      }
    }

    const allActors = await requestGetAllActors(data);

    changeActorsCount(_.get(allActors, 'total'));
  }

  const onSearchClick = () => {
    getActorsAndCount()
  }


  const addFiltersFunc = () => {
    setIsModalVisible(false);
  }

  const initFunc = async () => {
    const groups = await requestGetAllGroups({
      limit: 10,
      offset: 0,
      order_by_column: "created",
      order_by_rule: "asc"
    })

    // console.log('INIT groups', get(groups, 'actors', []))

    changeGroupTags(get(groups, 'actors', []))
  }

  useEffect(() => {
    filterTableData();
  }, [
    JSON.stringify(allActors),
    // searchValue
  ]);

  useEffect(() => {
    initFromURL();
    initFunc()
  }, []);

  useEffect(() => {
    changeCurrentPage(null);
  }, [newCurrentPage])

  return (
    <div className={'dt-content-wrapper'}>
      <ProgressModal
        visible={showDeletionProgress}
        all={selectedActors.size}
        current={deletionProgress}
      />
      <MasqueradingModal
        visible={!!masqueradingUser}
        onCancel={() => setMasqueradingUser('')}
        userUUID={masqueradingUser}
      />
      <div className={'dt-content'}>
        <Row>
          <Col span={24}>
            {breadCrumbsRender()}
          </Col>
        </Row>
        <Row gutter={[24, 16]}>
          {!isPermsView ? (
            <>
              <Col span={14}>
                <Card className={'card border-blue'}>
                  <ActorsListHeader
                    actorUUID={dataFromUrl.uuid}
                    ignoreSearchCase={ignoreSearchCase}
                    isCreateView={isCreateView}
                    newSelectedTagsOfGroups={groupTags}
                    onSearch={setSearchValue}
                    onSearchClick={onSearchClick}
                    onSelectTags={handleSelectedFilters}
                    searchValue={searchValue}
                    setIgnoreSearchCase={setIgnoreSearchCase}
                    switchView={switchView}
                  />
                  <hr className={'my-4'} />

                  {groupsFilter.length !== 0 &&
                    <>
                      <SelectedGroupsTable
                          selectedGroups={groupsFilter}
                          selectActor={selectActor}
                          selectedActors={selectedActors}
                          actorUUID={dataFromUrl.uuid}
                      />
                      <h4>Filtered users</h4>
                    </>
                  }

                  {selectedActors.size !==0 &&
                    <>
                      <Row>
                        <Col span={24}>
                          <Button
                              className="button-danger-outlined"
                              size="small"
                              disabled={selectedActors.size === 0}
                              onClick={onDeleteSomeActors}
                          >
                            {capitalize(t('auth.buttons.delete_selected', 'delete selected'))}
                          </Button>
                        </Col>
                      </Row>
                      <hr className={'my-4'} />
                    </>
                  }
                  <ActorsList
                    actorUUID={dataFromUrl.uuid}
                    afterDeleteActor={afterDeleteActor}
                    filterByTags={requestFilters}
                    getActorsAndCount={getActorsAndCount}
                    meIsAdmin={meIsAdmin}
                    meIsRoot={meIsRoot}
                    myMajorGroup={myMajorGroup}
                    newCurrentPage={newCurrentPage}
                    onSelectActors={onSelectActors}
                    selectActor={selectActor}
                    selectedActors={selectedActors}
                    setMasqueradingUser={setMasqueradingUser}
                    switchView={switchView}
                    tableData={tableData}
                    total={actorsCount}
                  />
                </Card>
              </Col>
              {isInfoView && (
                <Col span={10}>
                  <IStickyBox
                    offsetTop={16}
                    offsetBottom={16}
                  >
                    <ActorInfo
                        actorURLUUID={dataFromUrl.uuid}
                        actorType={dataFromUrl.type}
                        actorData={actorData}
                        onSuccessUpdate={getActorsAndCount}
                        onSuccessDelete={afterDeleteActor}
                        switchView={switchView}
                        applyFilter={handleSelectedFilters}
                    />
                  </IStickyBox>
                </Col>
              )}
              {isCreateView && (
                <Col span={10}>
                  <ActorCreate
                    switchView={switchView}
                    onSuccess={afterCreateActor}
                  />
                </Col>
              )}
            </>
          ) : (
            <>
              <PermissionsPage
                  actorData={actorData}
                  onSuccessUpdate={getActorsAndCount}
                  onSuccessDelete={afterDeleteActor}
                  switchView={switchView}
                  dataFromUrl={dataFromUrl}
              />
            </>
          )}
        </Row>
        <Modal
            title="Add filters"
            open={isModalVisible}
            destroyOnClose
            footer={[
              <Button key="back" onClick={() => setIsModalVisible(false)}>
                Cancel
              </Button>,
              <Button
                  key="submit"
                  type="primary"
                  disabled={groupTags.length === 0}
                  onClick={addFiltersFunc}
              >
                Add filters
              </Button>,
            ]}
            onCancel={() => setIsModalVisible(false)}
        >
          {isModalVisible &&
            <ListOfGroupsAsTagsInModal
              getSelectedTags={(tags) => changeGroupTags([...groupTags, ...tags])}
              groupTagsOnActorsPage={groupTags}
            />
          }
        </Modal>
      </div>
    </div>
  );
};

export default ActorsPage;
