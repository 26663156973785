import PropTypes from 'prop-types';
import React from 'react';
import InfoBlock from '../../components/InfoBlock';
import { mdiFileCertificateOutline } from '@mdi/js';
import { capitalize } from '../../utils';
import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';


const CertificateForm = ({
  form,
  disabled
}) => {
  const { t } = useTranslation();

  const tMsg = (path, defaultValue) => capitalize(t(`auth.validation.messages.${path}`, defaultValue));
  // eslint-disable-next-line max-len
  const validationCertificate = '^-----BEGIN CERTIFICATE-----\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{64}\r?\n[A-Za-z0-9+\/=]{38}==\r?\n-----END CERTIFICATE-----\r?\n?$';


  const rules = {
    'certificate': [
      { pattern: validationCertificate},
      { required: true }
    ],
    'domains': [
      { required: true }
    ],
    'service_uuid': [
      { pattern: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i }
    ]
  };

  const validateMessages = {
    pattern: {
      mismatch: (name) => {
        return tMsg('mismatch', { format: `$t(auth.validation.formats.${name})`});
      }
    },
    required: tMsg('required', 'is required')
  };


  return (
    <Form
      layout={'vertical'}
      autoComplete={'off'}
      form={form}
      validateMessages={validateMessages}
    >
      <InfoBlock
        iconPath={mdiFileCertificateOutline}
        title={capitalize(t('auth.headers.certificate_detail', 'certificate detail'))}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.domains', 'domains'))}
              name={'domains'}
              rules={disabled ? null : rules.domains}
            >
              <Input.TextArea
                placeholder={capitalize(t('auth.placeholders.enter', {value: t('auth.headers.domains')}))}
                autoSize={{
                  minRows: 1,
                  maxRows: 10
                }}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.public_key', 'public key'))}
              name={'certificate'}
              rules={disabled ? null : rules.certificate}
            >
              <Input.TextArea
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.public_key')}))}
                autoSize={{
                  minRows: 3,
                  maxRows: 24
                }}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.service_uuid', 'service uuid'))}
              name={'service_uuid'}
              rules={disabled ? null : rules.service_uuid}
            >
              <Input
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.service_uuid')}))}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
    </Form>
  );
};

export default CertificateForm;

CertificateForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired
};
