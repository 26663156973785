import React, {useContext, useReducer} from "react";
import {Button, Col, Row, Tooltip, Popconfirm} from "antd";

import ColumnsWrapperForBasicActorList from "../ColumnsWrapperForBasicActorList";

import {ApiContext} from "../../../api/ApiContextProvider";
import {antNotification} from "../../../utils";



const ResetServiceSessionBtn = ({
    title,
    action,
    serviceData,
    tooltipTitle = ''
}) => {
    const {requestResetSessionsForService, checkServiceSessions} = useContext(ApiContext);

    const {
        uuid: serviceUUID,
        uinfo: {
            service_name = ''
        } = {}
    } = serviceData || {};

    const [fetching, setFetching] = useReducer((state) => !state, false);
    const [confirmFlag, setConfirmFlag] = useReducer((state) => !state, false);

    const restSessionForService = () => {
        setFetching()
        requestResetSessionsForService({ serviceUUID, action }).then((res) => {
            antNotification.success('Success', `Service ${service_name}`)
            setFetching()
            setConfirmFlag()
        }).catch(() => {
            setFetching()
            setConfirmFlag()
        })
    }

    const checkSessionForService = () => {
        setFetching()
        checkServiceSessions(serviceUUID).then(() => {
            antNotification.success('Success', `Service ${service_name}`)
            setFetching()
            setConfirmFlag()
        }).catch(() => {
            setFetching()
            setConfirmFlag()
        })
    }

    const getFuncForBtn = () => {
      switch (action) {
          case 'check_session':
              checkSessionForService()
              break;
          default:
              return restSessionForService()
      }
    }


    return(
        <Popconfirm
            open={confirmFlag}
            title="Are you sure?"
            onConfirm={getFuncForBtn}
            onCancel={setConfirmFlag}
            okText="Yes"
            cancelText="No"
        >
            <Button
                className={'ml-3 button-primary-outlined'}
                size={'small'}
                disabled={fetching}
                onClick={setConfirmFlag}
            >
                <Tooltip placement="bottomLeft"  title={tooltipTitle}>
                    {fetching ? 'Pending...' : title}
                </Tooltip>
            </Button>
        </Popconfirm>
    )
}

const ServicesSessions = () => {
    const actionFormatter = (cell, row) => {
        return(

            <Row justify="space-between">
                <Col span={24} className={'d-flex'}>
                    <ResetServiceSessionBtn
                        serviceData={row}
                        action={'check_session'}
                        title={'Check service communication'}
                        tooltipTitle={
                            'Checking the ability of the service to perform any action on the Auth service as an actor with its own session\n' +
                            'If unsuccessful, it is recommended to reset the sessions of this service as an actor and contact administrator to set the reason for such response'
                        }
                    />
                    <ResetServiceSessionBtn
                        serviceData={row}
                        action={'for_service_as_actor'}
                        title={'Reset sessions for service as actor'}
                        tooltipTitle={
                            "Resetting service sessions as an actor on other services\n" +
                            "After this operation, the service will receive new sessions on other services when trying to interact with them"
                        }
                    />
                    <ResetServiceSessionBtn
                        serviceData={row}
                        action={'for_users'}
                        title={'Reset user sessions'}
                        tooltipTitle={"Reset all user sessions on the service"}
                    />
                    <ResetServiceSessionBtn
                        serviceData={row}
                        action={'full_reset'}
                        title={'Full sessions reset'}
                        tooltipTitle={"Complete cleaning of all sessions on the service, including users and services"}
                    />
                </Col>
            </Row>
        )
    }

    const actionSessions = {
        dataIndex: 'uuid',
        key: 'actions',
        className: 'd-flex p-2 pr-4',
        title: '',
        render: actionFormatter
    }

    return(
        <>
            {/*{selectedActors.size !== 0 && (*/}
            {/*    <Row className={'mb-2'}>*/}
            {/*        <Col>*/}
            {/*            <Button*/}
            {/*                type={'primary'}*/}
            {/*                size={'small'}*/}
            {/*                onClick={checkKeyPairs}*/}
            {/*            >*/}
            {/*                Check key pairs*/}
            {/*            </Button>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*)}*/}
            <Row>
                <Col span={24}>
                    <ColumnsWrapperForBasicActorList
                        getActorTypes={['service']}
                        typeOfColumns={'servicesSessions'}
                        // withCheckBox
                        action={actionSessions}
                        // getSelectedActors={setSelectedActors}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ServicesSessions;
