import React from 'react';
import Icon from "@mdi/react";
import {mdiHelpCircleOutline} from "@mdi/js";
import {capitalize} from "../utils";
import {Tooltip} from "antd";



const HelpIconWithHover = ({
    toolTipPlacement="left",
    toolTipText="",
    iconClassName = ''
}) => {

    return(
        <>
            <Tooltip
                placement={toolTipPlacement}
                title={toolTipText}
            >
                <Icon
                    size={1.3}
                    className={iconClassName}
                    path={mdiHelpCircleOutline}
                />
            </Tooltip>
        </>
    )
}

export default HelpIconWithHover;
