import React, {useState} from "react";
import ListOfActorsTags from "./ListOfActorsTags";
import ActorsModalBtn from "./ActorsModalBtn";

const AddActorsForNewActor = ({
  actorType,
  getSelectedActors,
  btnModalLabel,
  hideElements
}) => {
    const [localActors, setLocalActors] = useState([]);

    const getSelectedActorsAfterSave = (actors) => {
      setLocalActors(actors)


        if(getSelectedActors) {
            getSelectedActors(actors)
        }
    }

    const actorsUUIDs = localActors.map(item => item.uuid);

    return (
        <>
            <ListOfActorsTags
                actors={localActors}
            />
            <ActorsModalBtn
                currentActors={actorsUUIDs}
                actorType={actorType}
                btnModalLabel={btnModalLabel}
                hideElements={hideElements}
                doNotMakeRequest={['saveActors']}
                getSelectedActorsAfterSave={getSelectedActorsAfterSave}
            />
        </>
    )
}

export default AddActorsForNewActor;
