import keyMirror from 'keymirror';


export const ProfileConstants = keyMirror({
  GET_PROFILE_INFO_REQUEST: null,
  GET_PROFILE_INFO_SUCCESS: null,
  GET_PROFILE_INFO_FAILURE: null,

  UPDATE_PROFILE_INFO_REQUEST: null,
  UPDATE_PROFILE_INFO_SUCCESS: null,
  UPDATE_PROFILE_INFO_FAILURE: null,

  UPDATE_PROFILE_PASSWORD_REQUEST: null,
  UPDATE_PROFILE_PASSWORD_SUCCESS: null,
  UPDATE_PROFILE_PASSWORD_FAILURE: null,

  TURN_ON_MASQUERADING_REQUEST: null,
  TURN_ON_MASQUERADING_SUCCESS: null,
  TURN_ON_MASQUERADING_FAILURE: null,
  TURN_ON_MASQUERADING_CANCEL: null,

  TURN_OFF_MASQUERADING_REQUEST: null,
  TURN_OFF_MASQUERADING_SUCCESS: null,
  TURN_OFF_MASQUERADING_FAILURE: null,
  TURN_OFF_MASQUERADING_CANCEL: null,

  GET_MY_PERMISSIONS_REQUEST: null,
  GET_MY_PERMISSIONS_SUCCESS: null,
  GET_MY_PERMISSIONS_FAILURE: null
});
