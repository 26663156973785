import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'antd';
import _ from 'lodash';


const AntTable = ({
  bordered = false,
  columns,
  components,
  current,
  data,
  disablePagination,
  hidePaginationOnSinglePage = false,
  loading,
  onChangePagination,
  onRow,
  onShowSizeChange,
  pageSizeOptions = [25, 50, 100],
  pagination,
  paginationPageSize = 25,
  paginationSize = 'default',
  rowClassName = '',
  rowKey = 'uuid',
  rowSelection,
  showHeader = true,
  total = data ? data.length : 0,
}) => {
  const defaultPagination = {
    'current': current,
    'defaultPageSize': paginationPageSize,
    'hideOnSinglePage': hidePaginationOnSinglePage,
    'onChange': onChangePagination,
    'onShowSizeChange': onShowSizeChange,
    'pageSizeOptions': pageSizeOptions,
    'position': ['bottomLeft'],
    'showLessItems': paginationSize === 'small',
    'showSizeChanger': paginationSize === 'default',
    'showTitle': false,
    'size': paginationSize,
    'total': total,
    'locale': {
      'items_per_page': ''
    }
  };

  const getRowKey = (item) => {
    const toKey = (path) => _.get(item, path, '').toString().replaceAll(' ',
      '_');

    if (_.isArray(rowKey)) {
      return rowKey.map((rKey) => toKey(rKey)).join('_');
    }
    // console.log('toKey(_.toString(rowKey))', toKey(_.toString(rowKey)));
    return toKey(_.toString(rowKey));
  };

  const getRowClassName = (data) => {
    if (!rowClassName) {
      if (rowSelection) {
        return 'table-selectable';
      }
      return '';
    }

    if (_.isFunction(rowClassName)) {
      return rowClassName(data);
    }
    return rowClassName;
  };

  return (
    <Table
      bordered={bordered}
      columns={columns}
      components={components}
      dataSource={data}
      loading={loading}
      onRow={onRow}
      onShowSizeChange={onShowSizeChange}
      pagination={disablePagination ? false : (pagination || defaultPagination)}
      rowClassName={getRowClassName}
      rowKey={getRowKey}
      rowSelection={rowSelection}
      showHeader={showHeader}
      showSorterTooltip={false}
    />
  );
};

export default AntTable;

AntTable.propTypes = {
  bordered: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  components: PropTypes.object,
  data: PropTypes.array.isRequired,
  disablePagination: PropTypes.bool,
  loading: PropTypes.bool,
  onRow: PropTypes.func,
  pagination: PropTypes.object,
  paginationPageSize: PropTypes.number,
  paginationSize: PropTypes.string,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowKey: PropTypes.string,
  rowSelection: PropTypes.object,
  showHeader: PropTypes.bool
};
