import _ from 'lodash';


export const getProfileInfo = state => _.get(state, 'profile.info.info');

export const isProfileFetching = state => _.get(state, 'profile.info.fetching', false);

export const getAccess = state => _.get(state, 'profile.info.info.access', false);

export const getMasqueradingEnabled = state => _.get(state, 'profile.info.masqueradingEnabled', false);

export const getMyPermissions = state => _.get(state, 'profile.info.permissions');

export const getMeIsAdmin = state => _.get(state, 'profile.info.isAdmin');

export const getMeIsBan = state => _.get(state, 'profile.info.isBan');

export const getMeIsRoot = state => _.get(state, 'profile.info.isRoot');

export const getMeIsMajorGroup = state => _.get(state, 'profile.info.majorGroup');

