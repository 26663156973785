import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Descriptions, Row, Space, Tag, Timeline } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { mdiArrowRight, mdiClockTimeEightOutline, mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import { getLocale } from '../../locale/selectors';
import PropTypes from 'prop-types';
import { capitalize, textToClipboard } from '../../utils';
import { useTranslation } from 'react-i18next';
import IStickyBox from 'react-sticky-box';
import { ApiContext } from '../../api/ApiContextProvider';
import { getAllGroupsMap, getAllUsersMap } from '../../actors/selectors';


const LogsInfoComponent = ({
  logData,
  currentViewInfoFromEntity,
  formatAction,
  formatType
}) => {
  const { t } = useTranslation();
  const { requestGetLogs } = useContext(ApiContext);

  const locale = useSelector(getLocale) || 'en';
  const allUsersMap = useSelector(getAllUsersMap);
  const allGroupsMap = useSelector(getAllGroupsMap);

  const [logDataList, setLogDataList] = useState([]);


  const formatName = (cell) => {
    switch (_.get(cell, 'actor_type')) {
      case 'classic_user':
      case 'user':
        const firstName = _.get(cell, 'first_name') || _.get(cell, 'uinfo.first_name', '');
        const lastName = _.get(cell, 'last_name') || _.get(cell, 'uinfo.last_name', []);
        return `${firstName} ${lastName}`;
      case 'group':
        return _.get(cell, 'group_name') || _.get(cell, 'uinfo.group_name', '');
      case 'service':
        return _.get(cell, 'service_name') || _.get(cell, 'uinfo.service_name', '');
      default:
        return '';
    }
  };

  const renderLogItem = (logItem, id) => {
    const {
      created,
      current,
      previous
    } = logItem;
    if (!logItem.action) {
      return null;
    }

    const action = _.get(logItem, 'action', 'update');
    const fields = Object.keys(_.get(current, 'uinfo', {}));

    if (logItem === 'delete') {
      fields.push('delete');
    }

    if (_.isEmpty(fields)) {
      return null;
    }

    const whereOrBy = currentViewInfoFromEntity ?
      capitalize(t('auth.labels.by', 'by')) :
      capitalize(t('auth.labels.where', 'where'));

    let currentTime = logData.created === logItem.created;

    const dataActor = (type) => {
      const currentData = (data) => {
        switch (type) {
          case 'name':
            return formatName(data);

          case 'type':
            return formatType(data);

          default:
            return null;
        }
      };

      switch (action) {
        case 'update':
        case 'delete':
          return currentData(_.get(logItem, `${currentViewInfoFromEntity ? 'updater' : 'actor'}`));
        case 'create':
          return currentData(_.get(logItem, `${currentViewInfoFromEntity ? 'updater' : 'current'}`));
        default:
          return null;
      }
    };


    return (
      <Timeline.Item
        dot={currentTime &&
        <Icon path={mdiClockTimeEightOutline} color={'#f5222d'} size={1.2} />}
        key={`time_${created}_${id}`}
      >
        <Row gutter={[16, 10]} className={'mt-3'}>
          <Col span={24} className={'d-flex flex-wrap'}>
            <Tag className={'tag-default-light'}>
              {moment(created).locale(locale).format('YYYY-MM-DD hh:mm:ss')}
            </Tag>

            <span className={'d-flex ml-4 colorTextGrey'}>
              {formatAction(_.get(logItem, 'action'))}
            </span>

            <div className={'d-flex ml-2 colorTextGrey flex-nowrap'}>
              <div className={'d-flex flex-nowrap'}>{`${whereOrBy}:`}</div>

              <span className={'ml-2'}>
                {dataActor('type')}
              </span>

              <span className={'d-flex ml-2 colorTextGrey'}>
                {dataActor('name')}
              </span>
            </div>
          </Col>
          <Col span={24}>
            <Descriptions
              size={'small'}
              column={1}
              bordered
            >
              {fields.map((item, id) => {
                let currentData = _.get(current, `uinfo.${item}`, []);
                let oldsData = _.get(previous, `uinfo.${item}`, []);
                let newCurrentDataArray = [];
                let oldDataArray = [];


                switch (item) {
                  case 'groups':
                    _.difference(currentData, oldsData)
                      .forEach((i) => {
                        if (allGroupsMap.has(i)) {
                          newCurrentDataArray.push(
                            _.get(allGroupsMap.get(i), 'uinfo.group_name', '')
                          );
                        }
                      });

                    _.difference(oldsData, currentData)
                      .forEach((i) => {
                        if (allGroupsMap.has(i)) {
                          oldDataArray.push(
                            _.get(allGroupsMap.get(i), 'uinfo.group_name', '')
                          );
                        }
                      });

                    break;
                  case 'users':
                    _.difference(currentData, oldsData)
                      .forEach((i) => {
                        if (allUsersMap.has(i)) {
                          let name = `${_.get(
                            allUsersMap.get(i),
                            'uinfo.first_name',
                            ''
                          )} ${_.get(
                            allUsersMap.get(i),
                            'uinfo.last_name',
                            ''
                          )}`;
                          newCurrentDataArray.push(name);
                        }
                      });

                    _.difference(oldsData, currentData)
                      .forEach((i) => {
                        if (allUsersMap.has(i)) {
                          let name = `${_.get(
                            allUsersMap.get(i),
                            'uinfo.first_name',
                            ''
                          )} ${_.get(
                            allUsersMap.get(i),
                            'uinfo.last_name',
                            ''
                          )}`;
                          oldDataArray.push(name);
                        }
                      });

                    break;
                  case 'partitions':
                    newCurrentDataArray
                      = currentData.map(i => i.partitions.name);
                    oldDataArray = oldsData.map(i => i.partitions.name);
                    break;
                  case 'password':
                    currentData = capitalize(t(
                      'auth.headers.updated',
                      'updated'
                    ));
                    oldsData = '';
                    break;
                  case 'delete':
                    currentData = capitalize(t(
                      'auth.headers.actor_deleted',
                      'actor deleted'
                    ));
                    oldsData = '';
                    break;
                  default:
                    break;
                }


                return (
                  <Descriptions.Item
                    label={<span className={'px-3 py-2'}>{item}</span>}
                    labelStyle={{ width: 120 }}
                    key={`${item}_${created}_${id + 1}`}
                    className={'p-0'}
                  >
                    <Row>
                      <Col span={11} className={'bg-red px-3 py-2'}>
                        <div className={'d-flex flex-wrap'}>
                          {(
                            _.isEmpty(newCurrentDataArray) && _.isEmpty(oldDataArray)
                          ) &&
                          <Space
                            size={[0, 8]}
                            direction={'vertical'}
                          >
                            {_.isArray(oldsData) ? (
                              oldsData.map((uuid) => {
                                return (
                                  <div
                                    className={'w-100'}
                                    key={`info_group_${uuid}`}
                                  >
                                    {allGroupsMap.has(uuid) ? _.get(allGroupsMap.get(uuid), 'uinfo.group_name') : uuid}
                                  </div>
                                );
                              })
                            ) : (
                              oldsData
                            )}
                          </Space>
                          }
                        </div>
                      </Col>
                      <Col
                        span={2}
                        className={'d-flex align-items-center justify-content-center'}
                      >
                        <Icon path={mdiArrowRight} size={1} />
                      </Col>

                      <Col span={11} className={'bg-green px-3 py-2'}>
                        <div className={'d-flex flex-wrap'}>
                          {(
                            _.isEmpty(newCurrentDataArray) && _.isEmpty(oldDataArray)
                          ) ?
                            <Space
                              size={[0, 8]}
                              direction={'vertical'}
                            >
                              {_.isArray(currentData) ? (
                                currentData.map((uuid) => {
                                  return (
                                    <div
                                      className={'w-100'}
                                      key={`info_group_${uuid}`}
                                    >
                                      {allGroupsMap.has(uuid)
                                        ? _.get(allGroupsMap.get(uuid), 'uinfo.group_name')
                                        : uuid}
                                    </div>
                                  );
                                })
                              ) : (
                                currentData
                              )}
                            </Space> :

                            <div className={'d-flex flex-column align-item-start justify-content-start'}>
                              {!_.isEmpty(oldDataArray) &&
                              <div className={'d-flex align-item-start colorRed'}>
                                <span className={'d-flex'}> {'- '}</span>
                                <div className={'d-flex align-item-center flex-wrap  colorRed'}>
                                  {oldDataArray.map((item, id) => {
                                    return (
                                      <span key={item + id} className={'ml-2 '}>
                                        {item}
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                              }

                              {!_.isEmpty(newCurrentDataArray) &&
                              <div className={'d-flex align-item-center'}>
                                <div className={'d-flex align-item-center flex-wrap'}>
                                  {newCurrentDataArray.map((item, id) => {
                                    return (
                                      <span key={item + id} className={'ml-2'}>
                                        {item}
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                              }

                            </div>
                          }
                        </div>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          </Col>
        </Row>
      </Timeline.Item>
    );
  };

  const cardInfo = (
    name,
    checkDeleted,
    type,
    uuid,
    ip
  ) => {
    return (
      <div className={'d-flex flex-column  backgroundWhite '}>
        <h3 className={'header-primary mb-2'}>
          {name}
        </h3>

        {checkDeleted &&
        <div className={'d-flex align-items-center mb-1'}>
          <label className={'labelHeader'}>
            {capitalize(t('auth.headers.actor', 'actor'))}:
          </label>
          <Tag className={'tag-red-light mb-1'}>
            {capitalize(t('auth.headers.deleted', 'deleted'))}
          </Tag>
        </div>
        }
        <div className={'d-flex'}>
          <label className={'labelHeader'}>
            {capitalize(t('auth.headers.type', 'type'))}:
          </label>

          <div className={'d-flex ml-2 colorTextGrey align-items-start'}>
            {type}
          </div>

        </div>

        <div className={'d-flex align-items-start'}>
          <label className={'labelHeader'}>
            UUID:
          </label>
          <span className={'d-flex ml-2 colorTextGrey'}>
            {uuid}
          </span>
          <Button
            className={'button-primary-link ml-2'}
            size={'small'}
            onClick={() => textToClipboard(uuid)}
          >
            <Icon path={mdiContentCopy} size={1} />
          </Button>
        </div>

        <div className={'d-flex align-items-start'}>
          <label className={'labelHeader'}>
            {capitalize(t('auth.headers.ip_actor', 'IP actor'))}:
          </label>
          <span className={'d-flex ml-2 colorTextGrey align-items-center'}>
            {ip}
            <Button
              className={'button-primary-link ml-2'}
              size={'small'}
              onClick={() => textToClipboard(ip)}
            >
              <Icon path={mdiContentCopy} size={1} />
            </Button>
          </span>
        </div>
        <hr />
      </div>
    );
  };

  const initFunc = async () => {
    let currentUuid = currentViewInfoFromEntity ?
      _.get(logData, 'actor.uuid') :
      _.get(logData, 'updater.uuid');

    const data = {
      [currentViewInfoFromEntity ? 'uuid' : 'updater']: currentUuid,
      'order_by': 'created',
      'reverse': true
    };

    let res = await requestGetLogs(data).then(({ data }) => data);
    setLogDataList(res);
  };

  useEffect(() => {
    initFunc().then();
  }, [JSON.stringify(logData), currentViewInfoFromEntity]);


  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Card
        className={'card'}
        loading={false}
      >
        {currentViewInfoFromEntity ?
          cardInfo(
            formatName(_.get(_.head(logDataList) || {}, 'actor', '')),
            _.get(_.head(logDataList) || {}, 'action') === 'delete',
            formatType(_.head(logDataList)),
            _.get(_.head(logDataList) || {}, 'actor.uuid', ''),
            _.get(_.head(logDataList) || {}, 'updater_ip')
          ) :
          cardInfo(
            formatName(_.get(logData, 'updater')),
            _.get(logData, 'updater.deleted', false),
            formatType(_.get(logData, 'updater')),
            _.get(logData, 'updater.uuid', ''),
            _.get(logData, 'updater_ip')
          )
        }

        {logDataList.length > 0 && (
          <Timeline>
            {logDataList.map(renderLogItem)}
          </Timeline>
        )}
      </Card>
    </IStickyBox>


  );
};

LogsInfoComponent.propTypes = {
  currentViewInfoFromEntity: PropTypes.bool,
  formatAction: PropTypes.func,
  formatType: PropTypes.func,
  logData: PropTypes.object
};

export default LogsInfoComponent;
