import _ from 'lodash';


export const getAuthorizationFetching = (state) => _.get(state, 'auth.login.fetching', false);

export const getAuthInfoFetching = (state) => _.get(state, 'auth.info.fetching', false);

export const getAuthBiomeInfo = (state) => _.get(state, 'auth.info.biome', {});

export const getAuthServiceInfo = (state) => _.get(state, 'auth.info.auth', {});
