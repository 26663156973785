import keyMirror from 'keymirror';


export const AuthConstants = keyMirror({
  AUTH_INFO_REQUEST: null,
  AUTH_INFO_SUCCESS: null,
  AUTH_INFO_FAILURE: null,

  GET_REDIRECT_URL_REQUEST: null,
  GET_REDIRECT_URL_SUCCESS: null,
  GET_REDIRECT_URL_FAILURE: null,

  GET_LOGIN_TEMPLATE_REQUEST: null,
  GET_LOGIN_TEMPLATE_SUCCESS: null,
  GET_LOGIN_TEMPLATE_FAILURE: null,

  LOGIN_CLASSIC_USER_REQUEST: null,
  LOGIN_CLASSIC_USER_SUCCESS: null,
  LOGIN_CLASSIC_USER_FAILURE: null,

  SIGN_UP_CLASSIC_USER_REQUEST: null,
  SIGN_UP_CLASSIC_USER_SUCCESS: null,
  SIGN_UP_CLASSIC_USER_FAILURE: null,

  GET_LIST_OF_POLICIES_REQUEST: null,
  GET_LIST_OF_POLICIES_SUCCESS: null,
  GET_LIST_OF_POLICIES_FAILURE: null,

  CREATE_NEW_POLICY_REQUEST: null,
  CREATE_NEW_POLICY_SUCCESS: null,
  CREATE_NEW_POLICY_FAILURE: null,

  GET_QR_CODE_TO_DOWNLOAD_REQUEST: null,
  GET_QR_CODE_TO_DOWNLOAD_SUCCESS: null,
  GET_QR_CODE_TO_DOWNLOAD_FAILURE: null,

  GET_QR_CODE_REQUEST: null,
  GET_QR_CODE_SUCCESS: null,
  GET_QR_CODE_FAILURE: null,

  SIGN_UP_PROCESS_REQUEST: null,
  SIGN_UP_PROCESS_SUCCESS: null,
  SIGN_UP_PROCESS_FAILURE: null,

  SIGN_IN_PROCESS_REQUEST: null,
  SIGN_IN_PROCESS_SUCCESS: null,
  SIGN_IN_PROCESS_FAILURE: null,

  VERIFY_QR_CODE_REQUEST: null,
  VERIFY_QR_CODE_SUCCESS: null,
  VERIFY_QR_CODE_FAILURE: null,

  FETCH_SERVICE_INFO_REQUEST: null,
  FETCH_SERVICE_INFO_SUCCESS: null,
  FETCH_SERVICE_INFO_FAILURE: null,

  CONNECT_WS: null,

  SUBSCRIBED_TO_CHANNEL: null,
  UNSUBSCRIBED_FROM_CHANNEL: null,

  DISCONNECT_WS: null
});
