import { ProfileConstants } from '../constants/index';


const initialState = {
  fetching: true,
  info: {},
  masqueradingEnabled: false,
  permissions: {
    actor: [],
    group: []
  },
  isAdmin: false,
  isRoot: false,
  isBan: false
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ProfileConstants.GET_PROFILE_INFO_REQUEST:
    case ProfileConstants.UPDATE_PROFILE_INFO_REQUEST:
    case ProfileConstants.GET_MY_PERMISSIONS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case ProfileConstants.GET_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        fetching: false,
        info: payload
      };

    case ProfileConstants.GET_PROFILE_INFO_FAILURE:
    case ProfileConstants.UPDATE_PROFILE_INFO_FAILURE:
    case ProfileConstants.GET_MY_PERMISSIONS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case ProfileConstants.UPDATE_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        fetching: false,
        info: {
          ...state.info,
          actor: {
            ...state.info.actor,
            ...payload.actor
          }
        }
      };

    case ProfileConstants.TURN_ON_MASQUERADING_SUCCESS:
      return {
        ...state,
        masqueradingEnabled: true
      };

    case ProfileConstants.TURN_OFF_MASQUERADING_SUCCESS:
      return {
        ...state,
        masqueradingEnabled: false
      };

    case ProfileConstants.GET_MY_PERMISSIONS_SUCCESS:
      const {
        actor_permactions: actor,
        group_permactions: group,
        is_admin: isAdmin,
        is_root: isRoot,
        is_ban: isBan
      } = payload;

      const majorGroupIndex = [isRoot, isBan, isAdmin].findIndex((item) => item);
      const majorGroup = ['ROOT', 'BAN', 'ADMIN'][majorGroupIndex] || '';

      return {
        ...state,
        permissions: {
          actor,
          group
        },
        isAdmin,
        isRoot,
        isBan,
        majorGroup,
        fetching: false
      };

    default:
      return state;
  }
};
