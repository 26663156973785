import React, { useContext, useEffect } from 'react';
import { Col, Dropdown, Menu, Row } from 'antd';
import Icon from '@mdi/react';
import { mdiCube, mdiMenuDown } from '@mdi/js';
import { capitalize } from '../utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InterfaceIcon from '../publicInterfaces/components/interfaceIcon';
import { Link } from 'react-router-dom';
import { getOpenInterfacesList } from '../publicInterfaces/selectors';
import { ApiContext } from '../api/ApiContextProvider';


const InterfacesList = () => {
  const { t } = useTranslation();
  const { requestGetOpenInterfaces } = useContext(ApiContext);

  const allInterfaces = useSelector(getOpenInterfacesList);

  const interfacesMenu = (
    <Menu>
      {allInterfaces.map((item) => {
        const { name, domain, icon_color: color, service_icon: icon } = item;

        return (
          <Menu.Item
            key={`service_${domain}`}
            className={'header-dropdown-item pl-3'}
          >
            <Link to={{ pathname: domain }} target={'_blank'}>
              <Row className={'py-2'}>
                <Col className={'d-flex align-items-center'}>
                  <InterfaceIcon
                    color={color}
                    iconName={icon}
                    size={1.6}
                  />
                </Col>
                <Col className={'d-flex flex-column justify-content-center pl-3'}>
                  <h3 className={'m-0'}>
                    {name}
                  </h3>
                  <h5 className={'header-secondary m-0'}>
                    {domain}
                  </h5>
                </Col>
              </Row>
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    requestGetOpenInterfaces().then();
  }, []);


  return (
    <div id={'interfaces_list'} className={'header-dropdown-wrapper h-100'}>
      <Dropdown
        overlay={interfacesMenu}
        trigger={['click']}
        placement={'bottom'}
        className={'h-100'}
      >
        <Row className={'cursor-pointer'}>
          <Col className={'d-flex align-items-center'}>
            <Icon path={mdiCube} size={1.6} />
          </Col>
          <Col className={'d-flex align-items-center'}>
            <h4 className={'m-0 ml-3'} style={{ userSelect: 'none' }}>
              {capitalize(t('auth.headers.services', 'services'))}
            </h4>
          </Col>
          <Col className={'d-flex align-items-center'}>
            <Icon path={mdiMenuDown} size={1.5} />
          </Col>
        </Row>
      </Dropdown>
    </div>
  );
};

export default InterfacesList;
