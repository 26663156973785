import { ActorsConstants } from '../constants/actionTypes';


const initialState = {
  filteredData: {},
  resetTags: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActorsConstants.FILTER_RESET_TAGS:
      const resetIds = [...action.payload];
      let resetTags = [...state.resetTags];

      resetIds.forEach((item) => {
        if (resetTags.includes(item)) {
          resetTags = resetTags.filter((tag) => tag !== item);
        } else {
          resetTags = [...resetTags, item];
        }
      });

      return {
        ...state,
        resetTags
      };

    default:
      return state;
  }
};
