import React, {useContext, useEffect, useState} from "react";
import {Popconfirm, Popover} from "antd";
import {
    mdiPlus,
    mdiTrashCanOutline
} from "@mdi/js";
import Icon from "@mdi/react";
import {useTranslation} from "react-i18next";

import {antNotification, capitalize, paramsURL} from "../../../utils";
import {ApiContext} from "../../../api/ApiContextProvider";
import {ActorsConstants} from "../../constants/actionTypes";


const ServiceListingGroupLink = ({
    actorUUID,
    listingGroupUUID,
    onSuccessActorUpdate,
}) => {
    const {
        requestGetActor,
        manageServiceListingGroup,
    } = useContext(ApiContext)

    const {t} = useTranslation();
    // const dispatch = useDispatch();

    const [popConfirmVisible, changePopConfirmVisible] = useState(false)

    const popConfirmVisibleToggle = () => changePopConfirmVisible(!popConfirmVisible);

    const getService = () => {
        return(
            requestGetActor({uuid: actorUUID})
        )
    }

    const getListingGroup = (uuid) => {
        return(
            requestGetActor({
                uuid,
                constants: [
                    ActorsConstants.CHECK_LISTING_GROUP_REQUEST,
                    ActorsConstants.CHECK_LISTING_GROUP_SUCCESS,
                    ActorsConstants.CHECK_LISTING_GROUP_FAILURE,
                ]
            })
        )
    }

    // const getListingGroupAndSaveToStore = (uuid) => {
    //     getListingGroup(uuid).then((res) => {
    //         dispatch({
    //             type: ActorsConstants.CREATE_ACTOR_SUCCESS,
    //             payload: res
    //         })
    //
    //     })
    // }

    const checkLinkAndGoToActor = () => {
        getListingGroup(listingGroupUUID)
            .then(() => {
                paramsURL.set({
                    'view': 'info',
                    'uuid': listingGroupUUID,
                    'type': 'group'
                });
            })
            .catch(({status}) => {
                if(status === 400) {
                    // console.log('error', status)
                    changePopConfirmVisible(true)
                }
            })
    }

    const createListingGroupAndConnectToService = () => {
        return(
            manageServiceListingGroup({
                serviceUUID: actorUUID,
            }).then(() => {
                antNotification.success('Success');
            })
        )
    }

    const createListingGroupAndGetService = async () => {
        await createListingGroupAndConnectToService()
        await getService().then(() => {
            //TODO: Rewrite this update callback
            if(onSuccessActorUpdate) {
                onSuccessActorUpdate()
            }
        })
    }

    const removeListingGroup = () => {
        return (
            manageServiceListingGroup({
                serviceUUID: actorUUID,
                requestMethod: 'delete'
            }).then(() => {
                antNotification.success('Success');
            })
        )
    }

    const removeListingGroupAndGetService = async () => {
      await removeListingGroup();
      await getService();
    }

    const removeListingGroupLinkAndCreateListingGroup = async () => {
        await removeListingGroup();
        await createListingGroupAndConnectToService();
        await getService().then((res) => {
            // const uuid = res?.actor?.uinfo?.listing_group;
            //
            // if(uuid) {
            //     getListingGroupAndSaveToStore(uuid)
            // }

            //TODO: Rewrite this update callback
            if(onSuccessActorUpdate) {
                onSuccessActorUpdate()
            }
        })
    }

    useEffect(() => {
        changePopConfirmVisible(false)
    }, [actorUUID])


    return(
        <>
            <Popconfirm
                title={
                    listingGroupUUID ?
                        capitalize(t('auth.messages.remove_link_and_create_new', 'remove_link_and_create_new')):
                        capitalize(t('auth.messages.listing_group_will_be_created', 'listing_group_will_be_created'))
                }
                onConfirm={listingGroupUUID ? removeListingGroupLinkAndCreateListingGroup : createListingGroupAndGetService}
                onCancel={popConfirmVisibleToggle}
                okText={capitalize(t('auth.buttons.yes', 'yes'))}
                cancelText={capitalize(t('auth.buttons.no', 'no'))}
                open={popConfirmVisible}
            />

            <span
                className={'cursor-pointer'}
                style={{
                    color: '#038fde'
                }}
                onClick={listingGroupUUID ? checkLinkAndGoToActor : popConfirmVisibleToggle}
            >
                {!listingGroupUUID &&
                    <Icon
                        className={'mr-2 '}
                        path={mdiPlus}
                        size={1.4}
                    />
                }
                <span>
                   {listingGroupUUID ?
                       capitalize(t('auth.buttons.go_to_listing_group', 'Go to listing group')) :
                       capitalize(t('auth.buttons.create_listing_group', 'Create listing group'))
                   }
                </span>
            </span>

            {listingGroupUUID &&
                <Popover
                    placement="right"
                    content={capitalize(t('auth.tooltips.remove_listing_group', 'remove_listing_group'))}
                >
                    <Popconfirm
                        placement="topLeft"
                        title={capitalize(t('auth.messages.are_you_sure', 'are_you_sure'))}
                        onConfirm={removeListingGroupAndGetService}
                        okText={capitalize(t('auth.buttons.yes', 'yes'))}
                        cancelText={capitalize(t('auth.buttons.no', 'no'))}
                    >
                        <Icon
                            className={'ml-2 cursor-pointer'}
                            path={mdiTrashCanOutline}
                            size={1.4}
                        />
                    </Popconfirm>
                </Popover>
            }
        </>
    )
}

export default ServiceListingGroupLink;
