import React, {useContext, useReducer} from "react";
import {Alert, Button} from "antd";

import { ApiContext } from '../../api/ApiContextProvider';
import {antNotification} from "../../utils";


const CheckKeyPairOfServiceBtn = ({
    serviceUUID
}) => {
    const { requestCheckServiceKeyPair } = useContext(ApiContext);

    const [loading, setLoading] = useReducer((state) => !state, false)

    const checkKeys = () => {
        setLoading()
        requestCheckServiceKeyPair(serviceUUID)
            .then((res) => {
                const {
                    database,
                    settings,
                    valid_key_pair,
                } = res || {};

                setLoading()

                if(database && settings && valid_key_pair) {
                    antNotification.success('Key pair is valid');
                }

                if(!database) {
                    antNotification.warning('Public key does not matches with key in service database!');
                }

                if(!settings) {
                    antNotification.warning('Public key does not matches with key in service settings!');
                }

                if(!valid_key_pair) {
                    antNotification.warning('Key pair is not valid!');
                }
            })
            .catch(({message}) => {
                setLoading()
            })
    }

    return(
        <>
            <Button
                disabled={loading}
                onClick={checkKeys}
                type="default"
                size={'small'}
                className={'button-primary-outlined'}
            >
                {loading ? 'Loading...' : 'Check key pair'}
            </Button>
        </>
    )
}

export default CheckKeyPairOfServiceBtn;
