import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {mdiAccountDetailsOutline, mdiAccountGroupOutline, mdiNoteEditOutline} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import {Col, DatePicker, Form, Input, Row,} from 'antd';
import {isEmpty} from 'lodash';

import InfoBlock from '../../../components/InfoBlock';
import GenerateKeyPairForActorBtn from "../GenerateKeyPairForActorBtn";
import ChangeTypeOfUserBtn from "../ChangeTypeOfUserBtn";
import CollapseGroupsForActor from "../CollapseGroupsForActor";
import AddActorsForNewActor from "../AddActorsForNewActor";

import { capitalize } from '../../../utils';


const UserInfoFormItems = (props) => {
  const {
    actorData,
    hideElements = [],
    actorForm,
    actorType,
    actorUUID,
    creating,
    editMode,
    isProfile,
    rules,
  } = props;

  const { t } = useTranslation();

  const { setFieldsValue, getFieldsValue } = actorForm;
  const {
    typeOfUser = actorType,
    login,
    phone_number,
    email,
    newKeyPair = false,
  } = getFieldsValue(['typeOfUser', 'newKeyPair', 'phone_number', 'login', 'email']);

  const [, changeLocalState] = useState();

  const checkOneOfRequiredInput = () => {
   if(isEmpty(login) && isEmpty(phone_number) && isEmpty(email)){
     return true
   } else {
     return !(isEmpty(login) || isEmpty(phone_number) || isEmpty(email))
   }
  };

  const customRules = {
    'email': [{ type: 'email' }, {
      required: checkOneOfRequiredInput(),
      message: 'Please input your email ',
    }],
    'login': [{ required: checkOneOfRequiredInput() }],
    'phone_number': [{ required: checkOneOfRequiredInput() }],
  }

  const switchValueCallback = (value) => {
    changeLocalState({rerenderComponent: value})

    setFieldsValue({newKeyPair: value})
  }

  const changeTypeOfUserCallback = (value) => {
    setFieldsValue({
      typeOfUser: value,
    })

    changeLocalState({rerenderComponent: value})
  }

  const changeInput = () => {
    changeLocalState({rerenderComponent: true})
  }

  const getSelectedActors = (actors) => {
    setFieldsValue({
      newActorGroups: actors.map((item) => item.uuid),
    })
  }

  const userIsClassic = actorType === 'classic_user' || typeOfUser === 'classic_user';

  return (
    <div>
      <InfoBlock
          iconPath={mdiAccountDetailsOutline}
          title={capitalize(t('auth.headers.user_detail', 'user detail'))}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
                label={capitalize(t('auth.headers.first_name', 'first name'))}
                name={'first_name'}
                rules={rules.first_name}
            >
              <Input
                  placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.first_name') }))}
                  disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
                label={capitalize(t('auth.headers.last_name', 'last name'))}
                name={'last_name'}
                rules={rules.last_name}
            >
              <Input
                  placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.last_name_enter')}))}
                  disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          {userIsClassic &&
          <Col span={12}>
            <Form.Item
                label={capitalize(t('auth.headers.login', 'login'))}
                name={'login'}
                rules={customRules.login}
            >
              <Input
                  onChange={changeInput}
                  placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.login')}))}
                  disabled={!editMode}
              />
            </Form.Item>
          </Col>
          }
          <Col span={12}>
            <Form.Item
                label={capitalize(t('auth.headers.email', 'email'))}
                name={'email'}
                rules={customRules.email}
            >
              <Input
                  placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.email')}))}
                  disabled={!editMode}
                  onChange={changeInput}
              />
            </Form.Item>
          </Col>
          {userIsClassic && !hideElements.includes('phone') &&
          <Col span={12}>
            <Form.Item
                label={capitalize(t('auth.headers.phone', 'phone number'))}
                name={'phone_number'}
                rules={customRules.phone_number}
            >
              <Input
                  placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.phone') }))}
                  disabled={!editMode}
                  onChange={changeInput}
              />
            </Form.Item>
          </Col>
          }
          <Col span={12}>
            <Form.Item
                label={capitalize(t('auth.headers.birthday', 'birthday'))}
                name={'birthday'}
            >
              <DatePicker
                  format={"YYYY-MM-DD"}
                  className={'w-100'}
                  placeholder={capitalize(t('auth.placeholders.select', { value: t('auth.headers.birthday') }))}
                  disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          {userIsClassic && (
              <>
                {!hideElements.includes('oldPassword') &&
                <Col span={12}>
                  <Form.Item
                      label={capitalize(t('auth.headers.old_password', 'old password'))}
                      name={'old_password'}
                      rules={rules.old_password}
                  >
                    <Input.Password
                        placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.password') }))}
                        disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                }

                <Col span={12}>
                  <Form.Item
                      label={creating ?
                          capitalize(t('auth.headers.password', 'password')) :
                          capitalize(t('auth.headers.new_password', 'new password'))}
                      name={'password'}
                      rules={rules.password}
                  >
                    <Input.Password
                        placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.password') }))}
                        disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                {!hideElements.includes('passwordConfirmation') &&
                <Col span={12}>
                  <Form.Item
                      label={capitalize(t('auth.headers.old_password', 'password confirmation'))}
                      name={'password_confirmation'}
                      rules={rules.password_confirmation}
                  >
                    <Input.Password
                        placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.password') }))}
                        disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                }
              </>
          )}
        </Row>
        {!creating && editMode &&
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
                label={capitalize(t('auth.headers.user type', 'type of user'))}
                name={'typeOfUser'}
            >
              <ChangeTypeOfUserBtn
                  defaultUserType={actorType}
                  changeTypeOfUserCallback={changeTypeOfUserCallback}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {!userIsClassic &&
            <Form.Item
                label={capitalize(t('auth.headers.user type', 'generate new key pair'))}
                name={'newKeyPair'}
            >
              <GenerateKeyPairForActorBtn
                  switchValueCallback={switchValueCallback}
              />
            </Form.Item>
            }
          </Col>
        </Row>
        }
        {/*1111*/}
        {/*{typeOfUser === 'classic_user' &&*/}
        {/*<Row>*/}
        {/*  <Col>*/}
        {/*    <Form.Item*/}
        {/*        label={capitalize(t('auth.headers.password', 'password'))}*/}
        {/*        name={'password'}*/}
        {/*        rules={customRules.password}*/}
        {/*    >*/}
        {/*      <Input.Password*/}
        {/*          placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.password') }))}*/}
        {/*          disabled={!editMode}*/}
        {/*      />*/}
        {/*    </Form.Item>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        {/*}*/}
        {/*222*/}
        <Row gutter={[16, 0]}>
          {!creating && !isProfile && !userIsClassic && (
              <Col span={24}>
                <Form.Item
                    label={capitalize(t('auth.headers.initial_key', 'initial key'))}
                    name={'initial_key'}
                    rules={rules.initial_key}
                >
                  <Input.TextArea
                      autoSize={{
                        minRows: 3,
                        maxRows: 5
                      }}
                      placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.initial_key') }))}
                      disabled={!editMode || newKeyPair}
                  />
                </Form.Item>
              </Col>
          )}
        </Row>
      </InfoBlock>
      <InfoBlock
          iconPath={mdiAccountDetailsOutline}
          title={capitalize(t('auth.headers.user_groups', 'user groups'))}
      >
        <Row>
          <Col span={24}>
            {!hideElements.includes('actorsModal') && actorUUID && (
                <CollapseGroupsForActor
                    actorData={actorData}
                    btnModalLabel={'Add or remove groups'}
                    iconModalPath={mdiAccountGroupOutline}
                />
            )}
            {creating &&
            <AddActorsForNewActor
                actorType={'user'}
                getSelectedActors={getSelectedActors}
                btnModalLabel={'add groups'}
            />
            }
          </Col>
        </Row>
      </InfoBlock>

      {!isProfile && (
          <InfoBlock
              iconPath={mdiNoteEditOutline}
              title={capitalize(t('auth.headers.comment', 'comment'))}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                    name={'comment'}
                    rules={rules.comment}
                >
                  <Input.TextArea
                      placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.comment') }))}
                      autoSize={{
                        minRows: 3,
                        maxRows: 5
                      }}
                      disabled={!editMode}
                  />
                </Form.Item>
              </Col>
            </Row>
          </InfoBlock>
      )}
    </div>
  );
};

export default UserInfoFormItems;

UserInfoFormItems.propTypes = {
  actorForm: PropTypes.object.isRequired,
  actorType: PropTypes.string.isRequired,
  creating: PropTypes.bool,
  editMode: PropTypes.bool,
  isProfile: PropTypes.bool,
  rules: PropTypes.object,
  validateMessages: PropTypes.object
};
