import {
  mdiClose,
  mdiContentCopy,
  mdiContentSaveOutline,
  mdiInformationOutline,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiTune,
  mdiFilterOutline
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Row,
  Skeleton,
  Space,
  Spin,
  Tag
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DeleteButton from '../../components/DeleteButton';
import GroupInfoFormItems from './infoForms/GroupInfoFormItems';
import ServiceInfoFormItems from './infoForms/ServiceInfoFormItems';
import UserInfoFormItems from './infoForms/UserInfoFormItems';

import { ApiContext } from '../../api/ApiContextProvider';
import {
  antNotification,
  capitalize,
  // clearObject,
  textToClipboard,
  paramsURL
} from '../../utils';
import {
  isActorsFetching,
  isInfoFetching
} from '../selectors';

const ActorInfo = ({
  actorURLUUID,
  actorType,
  onSuccessUpdate,
  onSuccessDelete,
  switchView,
  actorData,
  showPerms,
  applyFilter
}) => {

  const { t } = useTranslation();
  const [actorForm] = Form.useForm();
  const {
    requestUpdateActor,
    requestGetActor
  } = useContext(ApiContext);

  const dataFromUrl = useSelector(paramsURL.get);
  const isFetching = useSelector(isInfoFetching);
  const isActorListFetching = useSelector(isActorsFetching);

  const [editMode, setEditMode] = useState(false);

  const [collapsed, setCollapsed] = useReducer((state) => !state, false);

  const {
    initial_key = '',
    root_perms_signature = false,
    uuid: actorUUID,
    uinfo: {
      birthday = null,
      comment = '',
      description = '',
      email = '',
      first_name = '',
      groups = [],
      last_name = '',
      listing_group = '',
      login = '',
      // password = '',
      phone_number = '',
      group_name = '',
      weight = '',
      service_domain = '',
      service_name = '',
    } = {}
  } = actorData || {};

  const serviceInitialValues = {
    comment,
    description,
    groups,
    initial_key,
    listing_group,
    service_domain,
    service_name,
  }

  const userInitialValues = {
    birthday: birthday ? moment(birthday, 'YYYY-MM-DD') : null ,
    comment,
    email,
    first_name,
    groups,
    initial_key,
    last_name,
    login,
    // password,
    phone_number,
  }

  const groupInitialValues = {
    group_name,
    weight,
    description,
    comment,
  }

  const filterActive = _.isString(dataFromUrl.groups)
    && dataFromUrl.groups === actorURLUUID
    && !dataFromUrl.actor_type;

  const tMsg = (
    path,
    defaultValue,
  ) => capitalize(
    t(
      `auth.validation.messages.${path}`,
      defaultValue || path,
    ));

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters')
    },
    types: {
      email: tMsg('email', 'is not a valid email'),
      number: tMsg('number', 'must be a number type'),
      url: tMsg('url', 'is not a valid URL')
    }
  };

  const rules = {
    'user': {
      'first_name': [{ max: 200 }],
      'last_name': [{ max: 200 }],
      // 'email': [{ type: 'email' }, { required: true }],
      'password': [{ min: 4 }, { max: 200 }],
      'initial_key': [{ len: 130 }]
    },
    'group': {
      'group_name': [{ required: true }, { min: 1 }, { max: 200 }],
      'weight': [{ type: 'number' }, { required: true }]
    },
    'service': {
      'service_name': [{ required: true }, { min: 1 }, { max: 200 }],
      'service_domain': [{ type: 'url' }, { required: true }],
      'initial_key': [{ len: 130 }, { required: true }]
    }
  };

  const getActorTitle = () => {
    switch (actorType) {
      case 'service':
        return service_name;
      case 'group':
        return group_name;
      case 'classic_user':
      case 'user':
        return `${first_name} ${last_name}`;
      default:
        return '';
    }
  };

  const isDefault = actorType === 'group' && [
    'ADMIN',
    'DEFAULT',
    'BAN'
  ].includes(group_name);

  const additionalTag = () => {
    const isRoot = root_perms_signature;
    const tagName = isRoot ? 'root' : 'default';

    return (
      <>
        {(
          isRoot || isDefault
        ) && (
          <Tag className={`tag-${isRoot ? 'purple' : 'gray'} ml-2`}>
            {t(`auth.headers.${tagName}`, tagName).toUpperCase()}
          </Tag>
        )}
      </>
    );
  };

  const actorTypeActor = () => {
    return (
      <Tag className={`tag-${actorType} ml-2`}>
        {capitalize(t(`auth.headers.${actorType}`, actorType))}
      </Tag>
    );
  };

  const onSubmitFunc = async () => {
    const changedInputs = actorForm.getFieldsValue(null, meta => meta.touched);

    const {
      birthday,
      comment,
      email,
      first_name,
      initial_key,
      last_name,
      password,
      phone_number,
      login,
      newKeyPair: generate_new_keys_for_user,
      addActorsList: add_actors_list,
      removeActorsList: remove_actors_list,
      typeOfUser: actor_type
    } = changedInputs;

    try {
      await actorForm.validateFields();

      const data = {
        ...actorData,
        ...(actor_type ? { actor_type } : {}),
        ...(initial_key ? { initial_key } : {}),
        ...(generate_new_keys_for_user ? { generate_new_keys_for_user } : {}),
        ...(add_actors_list ? { add_actors_list } : {}),
        ...(remove_actors_list ? { remove_actors_list } : {}),
        uinfo: {
          ...actorData.uinfo,
          ...(first_name ? { first_name } : {}),
          ...(last_name ? { last_name } : {}),
          ...(email ? { email } : {}),
          ...(comment ? { comment } : {}),
          ...(password ? { password } : {}),
          ...(phone_number ? { phone_number } : {}),
          ...(login ? { login } : {}),
        },
      }

      if(birthday) {
        data.uinfo.birthday = moment(birthday).format('YYYY-MM-DD')
      }

      requestUpdateActor(data).then(() => {
        antNotification.success(capitalize(t('auth.notifications.updated_successfully', 'updated successfully')));

        if(onSuccessUpdate) {
          onSuccessUpdate();
        }
      });

      setEditMode(false);
    } catch (e) {
      antNotification.error(capitalize(t('auth.notifications.invalid_data', 'invalid data entered')));
    }
  }

  const onCancel = () => {
    setEditMode(false);
    actorForm.resetFields();
  };

  const onSetEditMode = (editState) => {
    setEditMode(editState);
  };

  const onApplyFilter = () => {
    applyFilter([
      {
        key: 'uinfo.groups',
        value: actorURLUUID,
        label: 'Group with perms 2',
        className: ''
      }
    ]);
  };

  const getActorInitFormValues = () => {
    switch (actorType) {
      case 'classic_user':
      case 'user':
      case 'phantom':
        return userInitialValues
      case 'group':
        return groupInitialValues
      case 'service':
        return serviceInitialValues
      default:
        return [];
    }
  }

  const getActorForm = () => {
    const infoProps = {
      actorForm,
      actorType,
      editMode,
      actorData,
      actorUUID,
      validateMessages
    };

    switch (actorType) {
      case 'classic_user':
      case 'user':
      case 'phantom':
        return (
          <UserInfoFormItems
              {...infoProps}
              // initialValues={userInitialValues}
              rules={rules.user}
              hideElements={['oldPassword', 'passwordConfirmation']}
          />
        );
      case 'group':
        return (
          <GroupInfoFormItems
              {...infoProps}
              rules={rules.group}
              // initialValues={groupInitialValues}
          />
        );
      case 'service':
        return (
          <ServiceInfoFormItems
              {...infoProps}
              // initialValues = {serviceInitialValues}
              rules={rules.service}
              onSuccessActorUpdate={onSuccessUpdate}
          />
        );
      default:
        return null;
    }
  };

  const collapseButton = () => (
    <div
      key={'go_to_permissions_button'}
      className={'card-action'}
      onClick={setCollapsed}
    >
      <Icon
        path={mdiInformationOutline}
        size={1.6}
        className={'mr-2'}
      />
      {capitalize(t(
        'auth.headers.detailed_information',
        'detailed information',
      ))}
    </div>
  );

  const initFunc = async () => {
    if (actorURLUUID && actorURLUUID !== actorUUID) {

      await requestGetActor({uuid: actorURLUUID}).then((res) => {
        // actorForm.setFieldsValue(getInitValuesFromResponse(res?.actor || {}));
      }).catch(() => switchView());
    }
  };


  useEffect(() => {
    initFunc();
    onCancel();
  }, [actorURLUUID]);

  useEffect(() => {
    if(!isFetching) {
      actorForm.setFieldsValue(getActorInitFormValues())
    }
  }, [actorUUID, isFetching])


  return (
    <Spin
      spinning={isFetching || isActorListFetching || !actorUUID}
    >
      <Form
          layout={'vertical'}
          autoComplete={'off'}
          form={actorForm}
          validateMessages={validateMessages}
          // initialValues = {getActorInitFormValues()}
      >
        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Card
                className={`card ${showPerms ? '' : 'border-blue'}`}
                actions={showPerms ? [collapseButton()] : null}
            >
              <Row
                  gutter={[16, 0]}
                  wrap={false}
              >
                <Col flex={'none'}>
                  <h4 className={'header-primary'}>
                    {capitalize(t('auth.headers.actor_info', 'actor info'))}
                  </h4>
                </Col>
                <Col
                    flex={'auto'}
                    className={'d-flex justify-content-end'}
                >
                  <Space
                      size={[5, 1]}
                      wrap
                      className={'justify-content-end'}
                  >
                    {actorType === 'group' && (
                        <Button
                            className={'button-secondary-outlined'}
                            size={'small'}
                            onClick={onApplyFilter}
                            disabled={isFetching || filterActive}
                        >
                          <Icon
                              path={mdiFilterOutline}
                              size={1}
                              className={'mr-1'}
                          />
                          {capitalize(t('auth.buttons.apply_filter', 'apply filter'))}
                        </Button>
                    )}
                    {editMode && (
                        <>
                          <Button
                              className={'button-secondary-outlined'}
                              size={'small'}
                              onClick={onCancel}
                              disabled={isDefault || isFetching}
                          >
                            <Icon
                                path={mdiClose}
                                size={1}
                                className={'mr-1'}
                            />
                            {capitalize(t('auth.buttons.cancel', 'cancel'))}
                          </Button>
                          <Form.Item
                              className={'m-0'}
                              shouldUpdate
                          >
                            {() => (
                                <Button
                                    className={'button-primary'}
                                    size={'small'}
                                    // onClick={onSubmitForm}
                                    onClick={onSubmitFunc}
                                    disabled={isDefault || isFetching || !actorForm.isFieldsTouched()}
                                >
                                  <Icon
                                      path={mdiContentSaveOutline}
                                      size={1}
                                      className={'mr-1'}
                                  />
                                  {capitalize(t('auth.buttons.save', 'save'))}
                                </Button>
                            )}
                          </Form.Item>
                        </>
                    )}
                    {!editMode && (
                        <Button
                            className={'button-primary-outlined'}
                            size={'small'}
                            onClick={() => onSetEditMode(true)}
                            disabled={isDefault || isFetching}
                        >
                          <Icon
                              path={mdiSquareEditOutline}
                              size={1}
                              className={'mr-1'}
                          />
                          {capitalize(t('auth.buttons.edit', 'edit'))}
                        </Button>
                    )}
                    <DeleteButton
                        onSuccess={onSuccessDelete}
                        uuid={actorURLUUID}
                        disabled={isDefault || isFetching}
                    >
                      <Button
                          className={'button-danger-outlined'}
                          size={'small'}
                          disabled={isDefault || isFetching}
                      >
                        <Icon
                            path={mdiTrashCanOutline}
                            size={1}
                            className={'mr-1'}
                        />
                        {capitalize(t('auth.buttons.remove_actor', 'remove actor'))}
                      </Button>
                    </DeleteButton>
                  </Space>
                </Col>
              </Row>
              <hr className={'my-4'} />
              <Row>
                <Col
                    span={24}
                    className={'d-flex align-items-center'}
                >
                  <Skeleton
                      loading={isFetching}
                      paragraph={false}
                      title={{
                        width: '50%',
                        style: {
                          margin: 0,
                          height: 24
                        }
                      }}
                      active
                      round
                  >
                    <h3 className={'header-primary m-0'}>
                      {getActorTitle()}
                    </h3>
                    {actorTypeActor()}
                    {additionalTag()}
                  </Skeleton>
                </Col>
                <Col
                    span={24}
                    className={'d-flex align-items-center mt-2'}
                >
                  <h5 className={'header-primary'}>
                    UUID: {actorURLUUID}
                  </h5>
                  <Button
                      className={'button-primary-link ml-2'}
                      size={'small'}
                      onClick={() => textToClipboard(actorURLUUID)}
                  >
                    <Icon
                        path={mdiContentCopy}
                        size={1}
                    />
                  </Button>
                </Col>
                {!showPerms && !editMode && (
                    <Col
                        span={24}
                        className={'mt-3'}
                    >
                      <Button
                          className={'button-primary-outlined w-100'}
                          onClick={() => switchView('permissions', actorURLUUID)}
                      >
                        <Icon
                            path={mdiTune}
                            size={1.5}
                            className={'mr-2'}
                        />
                        {capitalize(t('auth.headers.permissions_settings', 'permissions settings'))}
                      </Button>
                    </Col>
                )}
              </Row>
              <hr className={collapsed ? 'my-4' : 'mt-4 mb-0'} />
              {showPerms ? (
                  <Collapse
                      className={'card-collapse'}
                      activeKey={collapsed ? '1' : '0'}
                  >
                    <Collapse.Panel
                        header={capitalize(t('auth.headers.detailed_information', 'detailed information'))}
                        key={'1'}
                        forceRender
                    >
                      {getActorForm()}
                    </Collapse.Panel>
                  </Collapse>
              ) : getActorForm()}
            </Card>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ActorInfo;

ActorInfo.propTypes = {
  actorType: PropTypes.string.isRequired,
  actorURLUUID: PropTypes.string.isRequired,
  applyFilter: PropTypes.func,
  actorData: PropTypes.object,
  onSuccessDelete: PropTypes.func,
  onSuccessUpdate: PropTypes.func,
  showPerms: PropTypes.bool,
  switchView: PropTypes.func.isRequired
};
