import PropTypes from 'prop-types';
import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import { Empty } from 'antd';


const PolicyPreviewFile = ({
  documentData
}) => {
  return (
    <>
      {documentData ? (
        <MDEditor.Markdown
          id={'divToPrint'}
          source={documentData}
        />
      ) : (
        <Empty />
      )}
    </>

  );
};

export default PolicyPreviewFile;

PolicyPreviewFile.propTypes = {
  documentData: PropTypes.string
};
