import React, {useCallback, useContext, useEffect, useReducer, useState} from 'react';
import moment from "moment";
import _ from "lodash";
import {Button, Tag, Tooltip, Checkbox} from "antd";
import Icon from "@mdi/react";
import { mdiTrashCanOutline} from "@mdi/js";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ListOfActorsBasic from "./ListOfActorsBasic";
import TableTitleWrapper from "../../components/TableTitleWrapper";
import GetActorAndOpenModalWrapper from "./GetActorAndOpenModalWrapper";
import DeleteButton from "../../components/DeleteButton";

import {capitalize} from "../../utils";
import {getMeIsAdmin, getMeIsMajorGroup, getMeIsRoot} from "../../profile/selectors";
import {getAllActorsWithUnifiedName, getAllGroups, getAllGroupsMap} from "../selectors";
import {getLocale} from "../../locale/selectors";


const ColumnsWrapperForBasicActorList = ({
    getActorTypes,
    afterDeleteActor,
    typeOfColumns,
    withCheckBox,
    action = '',
    getSelectedActors,
}) => {
    const {t} = useTranslation();

    const [selectedActors, setSelectedActors] = useState(new Set());

    const allActors = useSelector(getAllActorsWithUnifiedName);
    const meIsRoot = useSelector(getMeIsRoot);
    const meIsAdmin = useSelector(getMeIsAdmin);
    const myMajorGroup = useSelector(getMeIsMajorGroup);

    const allGroups = useSelector(getAllGroups);
    const allGroupsMap = useSelector(getAllGroupsMap);
    const locale = useSelector(getLocale) || 'en';

    const [orderRules, changeOrderRules] = useState({
        'order_by_column': 'created',
        'order_by_rule': 'desc'
    });

    const changeSort = (param, sortOrder) => {
        changeOrderRules({
            'order_by_column': param,
            'order_by_rule': sortOrder === 'ascend' ? 'asc' : 'desc'
        })
    }

    const onSelectActors = (value) => {
        if (value === 'all') {
            setSelectedActors((prev) => {
                if (prev.size !== allActors.length) {
                    return new Set(allActors.map(({ uuid }) => uuid));
                } else {
                    return new Set();
                }
            });
        } else {
            setSelectedActors((prev) => {
                if (prev.has(value)) {
                    prev.delete(value);
                } else {
                    prev.add(value);
                }
                return new Set(prev);
            });
        }
    };

    const formatName = (cell, row) => {
        // console.log('cell cell', cell)
        // console.log('row row', row)

        const isRoot = row.root;
        const isDefault = row.default;
        const tagName = isRoot ? 'root' : 'default';

        let tagNames = [];

        if (row.groups && row.groups.length > 0 && allGroups.length > 0) {
            tagNames = row.groups
                .map((uuid) => _.get(allGroupsMap.get(uuid), 'uinfo.group_name'))
                .filter((item) => ['BAN', 'ADMIN'].includes(item));
        }

        return (
            <div className={'d-flex align-items-center'}>
                {cell}
                {(
                    isRoot || isDefault
                ) && (
                    <Tag className={`tag-${isRoot ? 'purple' : 'gray'} ml-2`}>
                        {t(`auth.headers.${tagName}`, tagName).toUpperCase()}
                    </Tag>
                )}
                {tagNames.map((tag) => (
                    <Tag
                        key={`default_tag_${row.uuid}_${tag}`}
                        className={`tag-${tag === 'BAN' ? 'red' : 'volcano'} ml-2`}
                    >
                        {t(`auth.headers.${tag.toLowerCase()}`, tag)}
                    </Tag>
                ))}
            </div>
        );
    };

    const formatType = (cell) => {
        return (
            <Tag className={`tag-${cell}`}>
                {capitalize(t(`auth.headers.${cell}`, cell))}
            </Tag>
        );
    };

    const updateActorCallback = useCallback(
        () => {
            initFunc();
        }, [],
    );

    const formatGroup = (cell, row) => {
        const {type, groups, uuid } = row;



        return (
            <GetActorAndOpenModalWrapper
                actorUUID={uuid}
                actorType={type}
            />
        );
    };

    const formatWeight = (cell, row) =>
        row.type === 'group' ? (
            <Tooltip title={cell}>
        <span
            className={'d-inline-block text-truncate'}
            style={{ maxWidth: 75 }}
        >
          {cell > 4294967297 ? 'MAX' : cell}
        </span>
            </Tooltip>
        ) : null;

    const formatActions = (uuid, row) => {
        const weight = +_.get(row, 'weight');
        const disabled = weight === 0 || weight > 4294967297;

        const actorType = _.get(row, 'type');
        const actorIsUser = actorType === 'user' || actorType === 'classic_user';
        const masqueradingDisabled = !actorIsUser || (myMajorGroup === 'ADMIN' && row.root);

        return (
            <div className={'d-flex align-items-center h-100'}>
                <DeleteButton
                    targetType={'actor'}
                    onSuccess={afterDeleteActor}
                    uuid={uuid}
                    disabled={disabled}
                >
                    <Button
                        className={'button-danger-outlined px-2'}
                        size={'small'}
                        disabled={disabled}
                    >
                        <Icon
                            path={mdiTrashCanOutline}
                            size={1}
                        />
                    </Button>
                </DeleteButton>
                {/*{(*/}
                {/*    meIsRoot || meIsAdmin*/}
                {/*) && (*/}
                {/*    <Tooltip*/}
                {/*        title={capitalize(t(*/}
                {/*            'auth.headers.masquerading',*/}
                {/*            'masquerading',*/}
                {/*        ))}*/}
                {/*    >*/}
                {/*        <Button*/}
                {/*            className={'button-warning-outlined px-2 ml-2'}*/}
                {/*            size={'small'}*/}
                {/*            disabled={masqueradingDisabled}*/}
                {/*            onClick={(e) => {*/}
                {/*                e.stopPropagation();*/}
                {/*                setMasqueradingUser(uuid);*/}
                {/*            }}*/}
                {/*            onDoubleClick={(e) => e.stopPropagation()}*/}
                {/*        >*/}
                {/*              <span style={{ padding: '0 1px' }}>*/}
                {/*                M*/}
                {/*              </span>*/}
                {/*            /!* <Icon path={mdiGhostOutline} size={1} />*!/*/}
                {/*        </Button>*/}
                {/*    </Tooltip>*/}
                {/*)}*/}
            </div>
        );
    };


    const titleDeleteCheckBox = (
      <Checkbox
        onClick={(e) => e.stopPropagation()}
        indeterminate={selectedActors.size !== 0 && selectedActors.size !== allActors.length}
        checked={selectedActors.size === allActors.length}
        onChange={() => onSelectActors('all')}
      />
    );

    const formatDeleteCheckBox = (cell) => (
      <Checkbox
        onClick={(e) => e.stopPropagation()}
        onChange={() => onSelectActors(cell)}
        checked={selectedActors.has(cell)}
      />
    );


    const colCheckbox = {
      dataIndex: 'uuid',
      key: 'delete_checkbox',
      className: 'p-2 pl-4',
      title: titleDeleteCheckBox,
      render: formatDeleteCheckBox
    }

    const colActorName =  {
        dataIndex: ['uinfo','name'],
        key: 'name',
        className: 'p-2',
        // width: 100,
        title: (
            <TableTitleWrapper
                minWidth={200}
            >
                {capitalize(t('auth.headers.title', 'title'))}
            </TableTitleWrapper>
        ),
        // sorter: (a, b) => _.get(a, 'name', '').localeCompare(_.get(b, 'name', '')),
        sorter: (a, b, sortOrder) => changeSort('name', sortOrder),
        render: formatName
    }

    const colActorType = {
        dataIndex: 'actor_type',
        key: 'type',
        title: (
            <TableTitleWrapper minWidth={10}>
                {capitalize(t('auth.headers.type', 'type'))}
            </TableTitleWrapper>
        ),
        // sorter: (a, b) => _.get(a, 'type', '').localeCompare(_.get(b, 'type', '')),
        sorter: (a, b, sortOrder) => changeSort('actor_type', sortOrder),
        className: 'p-2',
        align: 'left',
        render: formatType
    }

    const colActorCreated = {
        dataIndex: 'created',
        key: 'created',
        title: (
            <TableTitleWrapper minWidth={120}>
                {capitalize(t('auth.headers.created', 'created'))}
            </TableTitleWrapper>
        ),
        className: 'p-2',
        textWrap: 'word-break',
        // sorter: (a, b) => {
        //   const dateA = new Date(a.created).getTime();
        //   const dateB = new Date(b.created).getTime();
        //
        //   return dateB - dateA;
        // },
        sorter: (a, b, sortOrder) => changeSort('created', sortOrder),
        render: (cell) => capitalize(moment.utc(cell)
            .local()
            .locale(locale)
            .format('MMM D, LT')),
        defaultSortOrder: 'descend'
    }

    const columns = [
        // {
        //   dataIndex: 'uuid',
        //   key: 'delete_checkbox',
        //   className: 'p-2 pl-4',
        //   title: titleDeleteCheckBox,
        //   render: formatDeleteCheckBox
        // },
        colActorName,
        colActorType,
        // {
        //   dataIndex: 'groups',
        //   key: 'groups',
        //   title: (
        //     <TableTitleWrapper minWidth={50}>
        //       {capitalize(t('auth.headers.groups', 'groups'))}
        //     </TableTitleWrapper>
        //   ),
        //   className: 'p-2',
        //   // sorter: (a, b) => _.get(a, 'groups.length', 0) - _.get(b, 'groups.length', 0),
        //   render: formatGroup
        // },
        // {
        //   dataIndex: 'weight',
        //   key: 'weight',
        //   title: (
        //     <TableTitleWrapper minWidth={60}>
        //       {capitalize(t('auth.headers.weight', 'weight'))}
        //     </TableTitleWrapper>
        //   ),
        //   className: 'p-2',
        //   // sorter: (a, b) => +_.get(a, 'weight', 0) - +_.get(b, 'weight', 0),
        //   sorter: (a, b, sortOrder) => changeSort('weight', sortOrder),
        //   render: formatWeight
        //
        // },
        colActorCreated,
        // {
        //     dataIndex: 'uuid',
        //     key: 'actions',
        //     className: 'p-2 pr-4',
        //     title: '',
        //     render: formatActions
        // }
    ];

    const servicesColumns = [
        colActorName,
        colActorType,
        colActorCreated,
        action
    ]


    const getColumns = () => {
        const addCheckBoxForColumns = (columns) => withCheckBox ? [colCheckbox, ...columns] : columns;

        switch (typeOfColumns) {
            case 'servicesKeyPairs':
                return addCheckBoxForColumns(servicesColumns)
            case 'servicesSessions':
                return addCheckBoxForColumns(servicesColumns)
            default:
                return addCheckBoxForColumns(columns)
        }
    }


    useEffect(() => {
        if(getSelectedActors) {
            getSelectedActors(selectedActors)
        }
    }, [selectedActors])


    return (
        <ListOfActorsBasic
            columns={getColumns()}
            getActorTypes={getActorTypes}
            orderRules={orderRules}
        />
    )
}

export default ColumnsWrapperForBasicActorList;
