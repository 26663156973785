import React, {memo, useContext, useEffect, useReducer, useState} from "react";
import {Button, Col, Row, Tooltip} from "antd";
import Icon from "@mdi/react";
import {mdiReload} from "@mdi/js";
import {useSelector} from "react-redux";
import {getAllActors} from "../../selectors";

import ColumnsWrapperForBasicActorList from "../ColumnsWrapperForBasicActorList";

import {ApiContext} from "../../../api/ApiContextProvider";
import {antNotification} from "../../../utils";



const ServiceVersionBtn = memo(  ({
    uuid,
    checkServiceVersion,
    outerUUID,
    serviceBtnCallback
}) => {
    const [fetching, setFetching] = useReducer((state) => !state, false);
    const [version, setVersion] = useState('')

    const checkVersion = () => {
        setFetching()

        return checkServiceVersion(uuid)
            .then(({version}) => {
                setFetching()
                antNotification.success('Success')

                if(version) {
                    setVersion(version)
                }
            })
            .catch(() => {
                setFetching()
            })
            .finally(() => {
                if(serviceBtnCallback) {
                    serviceBtnCallback(uuid)
                }
            })
    }

    useEffect(() => {
        if(outerUUID === uuid) {
            checkVersion().then(() => {}).catch(() => {})
        }
    }, [outerUUID])

    return(
        <Button
            className={'mx-auto button-primary-outlined'}
            size={'small'}
            disabled={fetching}
            onClick={checkVersion}
        >
            {fetching ? 'Pending...' : !version && 'Check version'}
            {!fetching && version &&
            <>
                <Icon
                    className={'ml-1'}
                    path={mdiReload}
                    size={1}
                />
                <span>version {version}</span>
            </>
            }
        </Button>
    )
})

const ServicesVersioning = () => {
    const {checkServiceVersion} = useContext(ApiContext);

    const serviceVersioningFetching = useSelector(state => state.actors.serviceVersioning.fetching)
    const allServices = useSelector(getAllActors)

    const [currentService, setCurrentService] = useState(null);
    const [callbackServiceUUID, setCallbackServiceUUID] = useState(null);


    const checkAllVersions = () => {
        setCallbackServiceUUID(null)

        if(currentService === null) {
            setCurrentService(allServices[0].uuid)
        } else {
            const nextServiceIndex = allServices.findIndex(el => el.uuid === currentService) + 1;
            const nextServiceUUID = allServices[nextServiceIndex]?.uuid || null;

            setCurrentService(nextServiceUUID)
        }
    }

    const actionFormatter = (cell, row) => {
        return(

            <Row justify="space-between">
                <Col span={24} className={'d-flex'}>
                    <ServiceVersionBtn
                        uuid={cell}
                        outerUUID = {currentService}
                        checkServiceVersion={checkServiceVersion}
                        serviceBtnCallback={setCallbackServiceUUID}
                    />
                </Col>
            </Row>
        )
    }

    const actionSessions = {
        dataIndex: 'uuid',
        key: 'actions',
        className: 'd-flex p-2 pr-4',
        title: '',
        render: actionFormatter,
        shouldCellUpdate: (record, prevRecord) => record.uuid === currentService || currentService === null
    }

    useEffect(() => {
        if(currentService && currentService === callbackServiceUUID) {
            checkAllVersions()
        }
    }, [currentService, callbackServiceUUID])

    return(
        <>
            <Row>
                <Col span={24}>
                    <Button
                        type={'primary'}
                        size={'small'}
                        onClick={checkAllVersions}
                        disabled={serviceVersioningFetching}
                    >
                        {serviceVersioningFetching ? 'Pending...' : 'Check all service versions'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <ColumnsWrapperForBasicActorList
                        getActorTypes={['service']}
                        typeOfColumns={'servicesSessions'}
                        // withCheckBox
                        action={actionSessions}
                        // getSelectedActors={setSelectedActors}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ServicesVersioning;
