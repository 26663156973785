import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiCheck, mdiMinus, mdiPlus } from '@mdi/js';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { isResetTags } from '../actors/selectors';
import { ActorsConstants } from '../actors/constants/actionTypes';

// TAG STRUCTURE:
//   value - Tag value
//   label - Tag display
//   className - Tag DOM class
//
//   [
//     {
//       tags: [
//         {
//           value: 'key',
//           label: 'Display Name',
//           className: 'key_tag' (optional)
//         },
//         ...
//       ],
//       isRadio: true,
//       ...
//     },
//     ...
//   ]
//
// FLAGS:
//   isReset - To reset all tags
//   isRow - Put tags on a separate line
//   rowText - Text above the row
//   isGroup - Combine tags into a group
//   isExcluded
//   noReset - Non resettable tag
//   isRadio - Switching mode
//   noDeselect - Repeated click does not deselect tag
//   className - Its work, if tags is row or is group

// TAGS FLAGS:
//   unselectable - tag will be select and deselect

const TagsWrapper = ({
  children,
  isGroup,
  isRow,
  rowText,
  className,
  customClassName
}) => {
  if (isGroup || isRow) {
    return (
      <Col
        className={
          `${isGroup ? 'areas_filter-group' :
            'areas_filter-container'} ${isRow ? 'flex-wrap' :
            ''}  ${customClassName} ${className}`
        }
        span={isRow && 24}
      >
        {isRow && rowText &&
        <Col
          span={24}
          className={`ml-2 mt-2 pb-0 d-flex align-items-center ${customClassName || ''}`}
        >
          <span className={'rowText'}>
            {rowText}
          </span>
          <div className={'rowText-line flex-fill ml-2'} />
        </Col>}
        {children}
      </Col>
    );
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

const TagsSettingList = ({
  allTags = [],
  id,
  defaultSelectedTags,
  onSelectTags,
  small,
  customClassName
}) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const tagsReset = useSelector(isResetTags);

  const dispatch = useDispatch();

  const handleSelectTags = (tags) => {
    if (onSelectTags) {
      onSelectTags(tags);
    }
  };

  const filterTagsByParam = (param) => allTags
    .filter((item) => _.get(item, param, false))
    .map(({ tags }) => tags)
    .flat();

  const isActive = (tag) => selectedTags
    .map((item) =>
      (
        {
          value: item.value,
          key: _.get(item, 'key', null)
        }
      ))
    .some((item) =>
      item.key === _.get(tag, 'key', null) && item.value === tag.value);

  const toDeselect = (tag) => setSelectedTags((prev) => {
    const result = prev.filter((item) => !(
      item.value === tag.value && item.key === tag.key
    ));
    handleSelectTags(result);
    return result;
  });

  const toSelect = (tag, deselectTags) => setSelectedTags((prev) => {
    let result;

    if (deselectTags) {
      result = [...prev].filter(
        (item) => !deselectTags.some(({ value }) => item.value === value));
      result = [...result, tag];
    } else {
      result = [...prev, tag];
    }

    handleSelectTags(result);
    return result;
  });

  const toReset = () => {
    const exclusions = filterTagsByParam('noReset')
      .filter((item) => isActive(item));
    handleSelectTags([...exclusions]);
    setSelectedTags([...exclusions]);
  };

  const handleChange = (data) => {
    const {
      tag,
      tags,
      isRadio,
      isReset,
      noDeselect
    } = data;

    if (isReset) {
      toReset();
      return;
    }

    if (isActive(tag)) {
      if (!noDeselect) {
        toDeselect(tag);
      }
    } else if (isRadio) {
      toSelect(tag, tags);
    } else {
      toSelect(tag);

      if(tag.unselectable) {
        toDeselect(tag);
      }
    }
  };

  const handleChangeExcluded = (tag, sender) => {
    const currentTag = selectedTags.find(
      ({
        key,
        value
      }) => key === tag.key && value === tag.value);

    const toChangeTagStatus = (newStatus) => {
      const newTags = selectedTags.map((item) =>
        tag.key === item.key && tag.value === item.value ?
          (
            {
              ...item,
              status: newStatus
            }
          ) :
          item
      );

      handleSelectTags(newTags);
      setSelectedTags(newTags);
    };

    const getNewIncluded = (currentIncluded) => {
      switch (currentIncluded) {
        case 'included':
          return sender === 1 ? 'none' : 'excluded';
        case 'excluded':
          return sender === 1 ? 'included' : 'none';
        default:
          return sender === 3 ? 'excluded' : 'included';
      }
    };

    let status;

    if (currentTag) {
      status = getNewIncluded(currentTag.status);
    } else {
      status = getNewIncluded('none');
    }

    if (status === 'none') {
      toDeselect(currentTag);
    } else if (isActive(tag)) {
      toChangeTagStatus(status);
    } else {
      toSelect({
        ...tag,
        status
      });
    }
  };

  const checkBoxes = (data) => {
    const isRadio = _.get(data, 'isRadio', false);
    const isExcluded = _.get(data, 'isExcluded', false);
    const isReset = _.get(data, 'isReset', false);
    const isGroup = _.get(data, 'isGroup', false);
    const isRow = _.get(data, 'isRow', false);
    const noDeselect = _.get(data, 'noDeselect', false);
    const tags = _.get(data, 'tags', []);

    return tags.map((item, id) => {
      const checked = isActive(item) ||
        (
          isReset && selectedTags
            .filter(({ value }) =>
              filterTagsByParam('noReset')
                .map(({ value }) => value)
                .indexOf(value) === -1)
            .length === 0
        );

      const classTag = _.get(item, 'className', '');
      const key = `tag_${item.value}_${id}`;

      const data = {
        tag: item,
        tags,
        isRadio,
        isReset,
        noDeselect
      };
      let disabled = !_.isNil(item.disabled) && item.disabled;

      const filterComponent = (
        <label
          className={
            `${classTag}
             ${checked ? 'active' : ''}
            ${disabled ? 'disableTag' : ''}`}
        >
          {checked && <Icon path={mdiCheck} size={small ? 1.2 : 1.6} />}
          <input
            type={'checkbox'}
            checked={checked}
            disabled={disabled}
            onChange={() => handleChange(data)}
          />
          {item.label}
        </label>
      );

      if (isExcluded) {
        const statusTag = selectedTags.find(
          ({
            key,
            value
          }) => key === item.key && value === item.value);

        const status = _.get(statusTag, 'status', 'none');

        return (
          <div
            className={`areas_filter-excluded ${small ? 'isSmall' :
              ''} ${status}`}
          >
            <div
              onClick={() => handleChangeExcluded(item, 1)}
            >
              <Icon path={mdiPlus} size={small ? 1.2 : 1.6} />
            </div>
            <div
              onClick={() => handleChangeExcluded(item, 2)}
            >
              {item.label}
            </div>
            <div
              onClick={() => handleChangeExcluded(item, 3)}
            >
              <Icon path={mdiMinus} size={small ? 1.2 : 1.6} />
            </div>
          </div>
        );
      }

      if (!isGroup && !isRow) {
        return (
          <Col
            key={key}
            className={'areas_filter-container'}
          >
            {filterComponent}
          </Col>
        );
      }

      return (
        <Fragment key={key}>
          {filterComponent}
        </Fragment>
      );
    });
  };

  const initFunc = () => {
    if (defaultSelectedTags) {
      setSelectedTags(defaultSelectedTags);
    }
  };

  const initReset = () => {
    if (tagsReset.includes(id) && selectedTags.length > 0) {
      setSelectedTags([]);
      handleSelectTags([]);
      dispatch({
        type: ActorsConstants.FILTER_RESET_TAGS,
        payload: [id]
      });
    }
  };

  useEffect(() => {
    initReset();
  }, [tagsReset]);

  useEffect(() => {
    initFunc();
  }, [JSON.stringify(defaultSelectedTags)]);


  return (
    <Row
      className={`areas_filter-wrapper ${customClassName} ${small ? 'isSmall' : ''}`}
    >
      {allTags.map((data, id) => {
        const isGroup = _.get(data, 'isGroup', false);
        const isRow = _.get(data, 'isRow', false);
        const rowText = _.get(data, 'rowText', false);
        const tags = _.get(data, 'tags', []);
        const className = _.get(data, 'className', '');

        const key = `tags_${id}_${tags.map(({ value }) => value).join('_')}`;

        if (data.tags.length > 0) {
          return (
            <TagsWrapper
              isGroup={isGroup}
              isRow={isRow}
              rowText={rowText}
              key={key}
              className={className}
              customClassName={customClassName}
            >
              <>
                {checkBoxes(data)}
              </>
            </TagsWrapper>
          );
        } else {
          return null;
        }
      })}
    </Row>
  );
};

TagsSettingList.propTypes = {
  allTags: PropTypes.array,
  defaultSelectedTags: PropTypes.array,
  id: PropTypes.string,
  onSelectTags: PropTypes.func,
  small: PropTypes.bool,
  customClassName: PropTypes.string
};

export default TagsSettingList;

TagsWrapper.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  isGroup: PropTypes.bool,
  isRow: PropTypes.bool,
  rowText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  customClassName: PropTypes.string
};
