import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CookiesProvider } from 'react-cookie';
import { Spin } from 'antd';

import 'moment/locale/ru';
import 'sanitize.css/sanitize.css';
import './fonts/noir-pro/styles.css';
import 'antd/dist/antd.css';
import './index.css';
import './styles/main.css';
import './styles/ant.scss';
import './styles/custom.css';
import './styles/json.scss';
import './index.scss';
import './i18n';

import App from './containers/app';
import ApiContextProvider from './api/ApiContextProvider';

import store from './store';
import { history } from './history';


// TODO: add ErrorBoundary as a wrapper for app

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <CookiesProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ApiContextProvider>
          <Suspense fallback={<Spin />}>
            <div className={'dt-sidebar--fixed dt-header--fixed dt-layout--full-width'}>
              <Router history={history}>
                <App />
              </Router>
            </div>
          </Suspense>
        </ApiContextProvider>
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>
);
