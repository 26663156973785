// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2022.07.07

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';

const { Search } = Input;

let timerSearch;

function AntDesignSearchBox54origins({
  htmlFor,
  id,
  maxWidth = 350,
  onSearch,
  placeholder,
  valueSearch,
}) {
  const refInput = useRef();
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      onSearch(e.target.value);
    }, 700);
  };

  const styleInput = { maxWidth };

  const styleIcon = { position: 'absolute', left: 8, top: 3 };
  const styleLabel = { position: 'relative', marginBottom: '0px' };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      clearTimeout(timerSearch);
      onSearch(e.target.value);
    }
  };

  const stopPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    if (valueSearch !== value) {
      setValue(valueSearch);
    }
  }, [valueSearch]);

  useEffect(() => {
    if (valueSearch && !value) {
      refInput?.current?.focus();
    }
  }, []);
  return (
      <label
          htmlFor={htmlFor}
          style={styleLabel}
      >
        <Search
            id={id}
            onClick={stopPropagation}
            style={styleInput}
            type="text"
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            ref={refInput}
            value={value}
            onChange={handleChange}
        />
      </label>
  );
}

AntDesignSearchBox54origins.propTypes = {
  valueSearch: PropTypes.string,
  htmlFor: PropTypes.string,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AntDesignSearchBox54origins;
