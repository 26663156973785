import { PublicUIConstants } from '../constants/types';


const initialState = {
  fetching: false,
  interfaceFetching: false,
  interfaces: [],
  openInterfaces: [],
  interfaceData: {},
  error: ''
};

export default (state = initialState, action = {}) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    // GET interfaces LIST

    case PublicUIConstants.GET_ALL_PUBLIC_UI_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case PublicUIConstants.GET_ALL_PUBLIC_UI_SUCCESS:
      return {
        ...state,
        fetching: false,
        interfaces: payload
      };

    case PublicUIConstants.GET_ALL_PUBLIC_UI_FAILURE:
      return {
        ...state,
        fetching: false,
        error: ''
      };

    case PublicUIConstants.GET_ALL_OPEN_UI_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case PublicUIConstants.GET_ALL_OPEN_UI_SUCCESS:
      return {
        ...state,
        fetching: false,
        openInterfaces: payload
      };

    case PublicUIConstants.GET_ALL_OPEN_UI_FAILURE:
      return {
        ...state,
        fetching: false,
        error: ''
      };

    case PublicUIConstants.REMOVE_PUBLIC_UI_REQUEST:
      return {
        ...state,
        fetching: true,
        interfaces: state.interfaces
      };

    case PublicUIConstants.REMOVE_PUBLIC_UI_SUCCESS:
      return {
        ...state,
        fetching: false,
        interfaces: state.interfaces.filter(item => item.uuid !== payload)
      };

    case PublicUIConstants.REMOVE_PUBLIC_UI_FAILURE:
      return {
        ...state,
        fetching: false,
        interfaces: state.interfaces,
        error: ''
      };

    case PublicUIConstants.CREATE_PUBLIC_UI_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case PublicUIConstants.UPDATE_PUBLIC_UI_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case PublicUIConstants.UPDATE_PUBLIC_UI_SUCCESS:
      return {
        ...state,
        interfaceData: payload,
        fetching: false
      };

    case PublicUIConstants.UPDATE_PUBLIC_UI_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case PublicUIConstants.CREATE_PUBLIC_UI_SUCCESS:
      return {
        ...state,
        fetching: false,
        interfaces: state.interfaces.concat(payload)
      };

    case PublicUIConstants.CREATE_PUBLIC_UI_FAILURE:
      return {
        ...state,
        fetching: false,
        interfaces: state.interfaces,
        error: ''
      };

    case PublicUIConstants.GET_PUBLIC_UI_DATA_REQUEST:
      return {
        ...state,
        interfaceFetching: true,
        interfaceData: {}
      };

    case PublicUIConstants.GET_PUBLIC_UI_DATA_SUCCESS:
      return {
        ...state,
        interfaceFetching: false,
        interfaceData: payload
      };

    case PublicUIConstants.GET_PUBLIC_UI_DATA_FAILURE:
      return {
        ...state,
        interfaceFetching: false,
        interfaceData: state.interfaceData
      };

    default:
      return state;
  }
};

