import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';

import ErrorBoundary from '../error/ErrorBoundary';
import StartPage from '../../startPage/components/StartPage';
import Header from '../../components/Header';
import SidebarComponent from '../../components/SidebarComponent';
import FooterComponent from '../../components/Footer';
import ActorsPage from '../../actors/components/ActorsPage';
import GroupsPriorityPage from '../../groups/components/GroupsPriorityPage';
import SecurityPolicyComponent from '../../securityPolicy/components/SecurityPolicyPage';
import LogsPage from '../../logsView/components/LogsPage';
import InterfacesPage from '../../publicInterfaces/components/InterfacesPage';
import LoginPage from '../../auth/components/LoginPage';
import BiomeInfoPage from '../../BIOME/BiomeInfoPage';
import CertificatesPage from '../../certificates/components/CertificatesPage';
import ProfilePage from '../../profile/components/ProfilePage';
import BiomeDashboard from "../../BIOME/BiomeDashboard";

import { getAccess, isProfileFetching } from '../../profile/selectors';
import { AuthHelper } from '../../auth/utils';
import { ApiContext } from '../../api/ApiContextProvider';


const App = ({ cookies }) => {
  const access = useSelector(getAccess);
  const isFetching = useSelector(isProfileFetching);

  const { requestGetProfile, requestGetMyPermissions, requestAbout, requestGetAllGroups } = useContext(ApiContext);

  const checkApt = renderComponent => (
    <ErrorBoundary>
      {AuthHelper.isAuthorised() ? renderComponent : <Redirect to={'/admin'} />}
    </ErrorBoundary>
  );

  const routes = [
    {
      path: '/',
      exact: true,
      render: <Redirect to={'/admin'} />
    },
    {
      path: '/admin',
      exact: true,
      render: <LoginPage />
    },
    {
      path: '/admin/biome-info',
      render: checkApt(<BiomeInfoPage />)
    },
    {
      path: '/admin/biome-dashboard',
      render: checkApt(<BiomeDashboard />)
    },
    {
      path: '/admin/actors',
      render: checkApt(<ActorsPage />)
    },
    {
      path: '/admin/groups-priority',
      render: checkApt(<GroupsPriorityPage />)
    },
    {
      path: '/admin/public-interfaces',
      render: checkApt(<InterfacesPage />)
    },
    {
      path: '/admin/security-policy',
      render: checkApt(<SecurityPolicyComponent />)
    },
    {
      path: '/admin/logs',
      render: checkApt(<LogsPage />)
    },
    {
      path: '/admin/certificates',
      render: checkApt(<CertificatesPage />)
    },
    {
      path: '/admin/start-page',
      render: checkApt(<StartPage />)
    },
    {
      path: '/admin/profile',
      render: checkApt(<ProfilePage />)
    },
    {
      render: checkApt(<Redirect to={'/admin/profile'} />)
    }
  ];

  const pathname = window.location.href;

  if (pathname.includes('?identifier') || pathname.includes('&identifier')) {
    localStorage.setItem('identifier', pathname.split('identifier=')[1]);
  }

  useEffect(() => {
    window.addEventListener('message', event => {
      const authToken = _.get(event, 'data.authToken');

      if (authToken) {
        cookies.set('Auth', authToken, { path: '/' });
        window.location.reload();
      }
    });

    AuthHelper.removeSession();

    if (AuthHelper.isAuthorised()) {
      requestGetProfile()
        .then((response) => AuthHelper.authorizedAccess(
          _.get(response, 'access', false
          )));
      requestGetMyPermissions();
      requestAbout();
      requestGetAllGroups()
    }
  }, [AuthHelper.isAuthorised(), access]);


  return (
    <div className={'dt-root'}>
      {AuthHelper.isAuthorised() ? (
        <Header />
      ) : null}
      <main className={`dt-main ${AuthHelper.isAuthorised() ? '' : 'mt-0'}`}>
        {AuthHelper.isAuthorised() ?
          (
            <SidebarComponent
              isFetching={isFetching}
            />
          ) :
          null}
        <Switch>
          {routes.map((item, id) => {
            const {
              path,
              exact,
              render
            } = item;

            return (
              <Route
                key={`${id}_${path}`}
                exact={exact || false}
                path={path}
                render={() => render}
              />
            );
          })}
        </Switch>
      </main>
      {AuthHelper.isAuthorised() ? <FooterComponent /> : null}
    </div>
  );
};

export default compose(withRouter, withCookies)(App);

App.propTypes = {
  cookies: PropTypes.object
};
