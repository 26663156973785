import keyMirror from 'keymirror';


export const PublicUIConstants = keyMirror({
  GET_ALL_PUBLIC_UI_REQUEST: null,
  GET_ALL_PUBLIC_UI_SUCCESS: null,
  GET_ALL_PUBLIC_UI_FAILURE: null,

  GET_ALL_OPEN_UI_REQUEST: null,
  GET_ALL_OPEN_UI_SUCCESS: null,
  GET_ALL_OPEN_UI_FAILURE: null,

  REMOVE_PUBLIC_UI_REQUEST: null,
  REMOVE_PUBLIC_UI_SUCCESS: null,
  REMOVE_PUBLIC_UI_FAILURE: null,

  UPDATE_PUBLIC_UI_REQUEST: null,
  UPDATE_PUBLIC_UI_SUCCESS: null,
  UPDATE_PUBLIC_UI_FAILURE: null,

  GET_PUBLIC_UI_DATA_REQUEST: null,
  GET_PUBLIC_UI_DATA_SUCCESS: null,
  GET_PUBLIC_UI_DATA_FAILURE: null,

  CREATE_PUBLIC_UI_REQUEST: null,
  CREATE_PUBLIC_UI_SUCCESS: null,
  CREATE_PUBLIC_UI_FAILURE: null
});
