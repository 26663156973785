import { AuthConstants } from '../constants';


const initialState = {
  fetching: false,
  message: ''
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AuthConstants.GET_LOGIN_TEMPLATE_REQUEST:
      return {
        ...state,
        fetching: true,
        message: ''
      };

    case AuthConstants.GET_LOGIN_TEMPLATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        message: ''
      };

    case AuthConstants.GET_LOGIN_TEMPLATE_FAILURE:
      return {
        ...state,
        fetching: false,
        message: payload
      };

    default:
      return state;
  }
};
