import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';

import { AuthHelper } from '../utils';
import { ApiContext } from '../../api/ApiContextProvider';


const LoginPage = () => {
  const { requestAuthorization } = useContext(ApiContext);
  const history = useHistory();

  const initHTML = '<div style=\'display: flex;align-items: center;justify-content: center;height: 100%;\'>Loading...</div>';
  const [serverHtml, changeServerHtml] = useState(initHTML);
  const [documentIDs, changeDocumentIDs] = useState([])

  const initFunc = () => {
    if (AuthHelper.isAuthorised()) {
      history.push('/admin/profile');
    } else {
      requestAuthorization().then(getLoginForm);
    }
  }

  const appendScript = async (typeOfElement, scriptToAppend, id) => {
    const script = document.createElement("script");
    let scriptID = `customID${id}`;
    script.async = '';
    script.id = scriptID

    changeDocumentIDs((prev) => [...prev, scriptID]);

    if(typeOfElement === 'scriptSrc') {
      script.src = scriptToAppend;
    } else {
      script.innerHTML = scriptToAppend;
    }
    document.body.appendChild(script);
  }

  const makePromiseStyle = (href) => {
    return new Promise((resolve, reject) => {
      const link = document.createElement("link");
      link.href = href;
      link.rel="stylesheet";
      link.onload = resolve;

      document.body.appendChild(link);
    })
  }

  const appendScriptsToDocument = async (form) => {
    const listOfScripts = form.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi);
    // console.log('listOfScripts', listOfScripts)

    for(let i = 0; i< listOfScripts.length; i++) {
      let singleScript = listOfScripts[i];

      if(singleScript.includes('src=')) {
        // console.log('IF')
        await appendScript('scriptSrc', singleScript.split('"')[1], i)
      } else {
        // console.log('ELSE')
        await appendScript('scriptBody', singleScript.substring(singleScript.indexOf('<script>') + 8, singleScript.indexOf('</script>')), i)
      }
    }
  }

  const appendStylesToDocument = async (form) => {
    const findAllStyles = form.match(/<link(.*)>/g);

    for(let i =0; i<findAllStyles.length; i++) {
      await makePromiseStyle(findAllStyles[i].split('"')[1])
    }
  }

  const getLoginForm = async (form) => {
   await appendStylesToDocument(form)
   await appendScriptsToDocument(form);

   await changeServerHtml(form.substring(form.indexOf('<!-- Start embed -->'), form.indexOf('<!-- JS -->')));

   let logo = document.querySelector('#logo');

   if (logo) {
     logo.innerHTML = 'AUTH';
   }
  };

  useEffect(() => {
    initFunc();

    return () => {
      for (const id in documentIDs) {
        const el = document.getElementById(id);
        el.remove();
      }
    }
  }, [AuthHelper.isAuthorised()]);

  return (
      <div
          className={'dt-content-wrapper'}
          dangerouslySetInnerHTML={{
            __html: serverHtml
          }}
      />
  );
};

export default LoginPage;
