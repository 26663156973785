import PropTypes from 'prop-types';
import React from 'react';

const TableTitleWrapper = ({
  children,
  minWidth,
  className
}) => {
  return (
    <div
      style={{ minWidth }}
      className={className}
    >
      {children}
    </div>
  );
};

export default TableTitleWrapper;

TableTitleWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  className: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
