import React, {useEffect, useState} from 'react';
import {Card, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import Icon from "@mdi/react";
import {
    mdiCertificateOutline,
    mdiInformationOutline,
    mdiKeyOutline,
    mdiRefresh,
    mdiShieldCheckOutline
} from "@mdi/js";
import {useSelector} from "react-redux";

import PageWrapper from "../components/PageWrapper";
import SyncServicesPageCopy from "../syncServices/components/SyncServicesPageCopy";
import BiomeInfoPage from "./BiomeInfoPage";
import CertificatesPage from "../certificates/components/CertificatesPage";
import SecurityPolicyComponent from "../securityPolicy/components/SecurityPolicyPage";
import ServicesKeyPairs from "../actors/components/service/ServicesKeyPairs";
import ServicesSessions from "../actors/components/service/ServicesSessions";
import ServicesVersioning from "../actors/components/service/ServicesVersioning";

import {capitalize, paramsURL} from "../utils";



const BiomeDashboard = () => {
    const {t} = useTranslation();

    const dataFromUrl = useSelector(paramsURL.get);

    const {
        tab
    } = dataFromUrl;

    const [activeKey, setActiveKey] = useState('synchronization')

    const getLabel = (label, iconPath) => {
        return(
            <span>
                <Icon path={iconPath} size={1.3}/>
                <span className={'ml-1'}>{label}</span>
            </span>
        )
    }

    const onTabClick = (key) => {
        paramsURL.set({'tab': key});
        setActiveKey(key)
    }

    const items = [
        {
            label: getLabel('Biome info', mdiInformationOutline),
            key: 'info',
            children: <BiomeInfoPage />
        },
        {
            label: getLabel('Synchronization', mdiRefresh),
            key: 'synchronization',
            children: <SyncServicesPageCopy />
        },
        {
            label: getLabel('Services Keypairs', mdiKeyOutline),
            key: 'serviceskeypairs',
            children: <ServicesKeyPairs/>
        },
        {
            label: 'Services sessions',
            key: 'servicessessions',
            children:  <ServicesSessions />
        },
        {
            label: 'Versioning',
            key: 'versioning',
            children: <ServicesVersioning />
        },
        {
            label: getLabel('SSL certificates', mdiCertificateOutline),
            key: 'ssl',
            children: <CertificatesPage />
        },
        {
            label: getLabel('Security policy', mdiShieldCheckOutline),
            key: 'secpolicy',
            children: <SecurityPolicyComponent />
        },
    ];

    useEffect(() => {
        if(!tab) {
            paramsURL.set({'tab': 'synchronization'});
        } else {
            setActiveKey(tab)
        }
    },[])

    return(
        <PageWrapper
            title={capitalize(t('auth.pages.biome_info', 'biome dashboard'))}
        >
            <Card className={'card'}>
                <Tabs
                    onTabClick={onTabClick}
                    defaultActiveKey={'synchronization'}
                    activeKey={activeKey}
                    destroyInactiveTabPane
                    items={items}
                />
            </Card>
        </PageWrapper>
    )
}

export default BiomeDashboard;
