import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { COLORS, ICONS } from '../constants/icons';
import { Tag } from 'antd';


const IconSwitch = ({
  checked,
  onClick,
  icon
}) => {
  return (
    <Tag
      className={`icon-switch ${checked ? 'checked' : ''}`}
      onClick={() => onClick(icon)}
    >
      <Icon
        className={'icon-shadow-gray'}
        path={ICONS[icon]}
        color={COLORS.GRAY}
        size={1.8}
      />
    </Tag>
  );
};

export default IconSwitch;

IconSwitch.propTypes = {
  checked: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
