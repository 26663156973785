import React, {useEffect, useState} from 'react';

import AntTable from "./AntTable";


const AntTableWithPagination = (props) => {
    const {
        paginationSize = 'default',
        disablePagination = false,
        getPaginationOptions,
        defaultPage = 1,
        defaultPageLimit = 25,
        newCurrentPage,
        total
    } = props;

    const [currentPage, changePage] = useState(defaultPage)
    const [pageLimit, changePageLimit] = useState(defaultPageLimit)

    const offset = pageLimit * (currentPage - 1);

    const onChangePagination = (page) => {
        changePage(page)
    }

    const onShowSizeChange = (current, size) => {
        changePage(1)
        changePageLimit(size)
    }

    useEffect(() => {
        if(getPaginationOptions) {
            getPaginationOptions({
                pageLimit,
                currentPage,
                offset
            })
        }
    }, [pageLimit, currentPage]);

    useEffect(() => {
        if(newCurrentPage && newCurrentPage !== currentPage) {
            changePage(newCurrentPage)
        }
    }, [newCurrentPage])

    return (
        <AntTable
            current={currentPage}
            disablePagination={disablePagination}
            onChangePagination={onChangePagination}
            onShowSizeChange={onShowSizeChange}
            paginationSize={paginationSize}
            paginationPageSize={pageLimit}
            {...props}
        />
    )
}

export default AntTableWithPagination;
