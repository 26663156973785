import React, { Component } from 'react';
// import { Button } from 'reactstrap';
import SVG from '../../images/no-data.svg';
import PropTypes from 'prop-types';
import { Button } from 'antd';


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
    this.refreshPage = this.refreshPage.bind(this);
  }

  refreshPage() {
    window.location.reload();
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: error,
      errorInfo: errorInfo
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className={'container error-container'}>
          <div className={'row justify-content-center'}>
            <div className={'col align-self-center text-center'}>
              <div className={'row'}>
                <div className={'col-md-6 error-first-section'}>
                  <p>
                    <h1>No data :(</h1>
                  </p>
                  <p>
                    <h3>Something went wrong. Caught by error boundary.</h3>
                  </p>
                  <div className={'d-flex justify-content-center'}>
                    <Button
                      className={'button-primary'}
                      onClick={this.refreshPage}
                    >
                      Reload page
                    </Button>
                  </div>
                </div>
                <div className={'col-md-6'}>
                  <img src={SVG} alt={'error'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}


ErrorBoundary.propTypes = {
  children: PropTypes.element
};

export default ErrorBoundary;
