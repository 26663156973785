import React, {useContext, useReducer, useState} from 'react';

import ActorsModalBtn from "./ActorsModalBtn";

import {Button} from "antd";
import {ApiContext} from "../../api/ApiContextProvider";


const GetActorAndOpenModalWrapper = ({
    actorUUID,
    actorType,
}) => {
    const {requestGetActor} = useContext(ApiContext)

    const [currentActors, setCurrentActors] = useState([])
    const [modalVisible, changeModalVisible] = useReducer((state) => !state, false);

    const getBtnModalLabel = () => {
        switch (actorType) {
            case 'user':
            case 'classic_user':
            case 'service':
                return 'groups'
            case 'group':
            case 'newGroup':
                return 'users'
            default:
                return 'actor'
        }
    }

    const getActorAndOpenModal = (e) => {
        e?.stopPropagation();

        requestGetActor({uuid: actorUUID}).then((res) => {
            const {groups} = res || {};

            setCurrentActors(groups.map(({uuid}) => uuid))
            changeModalVisible()
        })
    }

    return(
        <>
            <Button
                type="default"
                onClick={getActorAndOpenModal}
                size={'small'}
                className={'button-primary-outlined actorsModalBtn'}
            >
                {getBtnModalLabel()}
            </Button>
            <ActorsModalBtn
                openModalFromOuter={modalVisible}
                actorUUID={actorUUID}
                actorType={actorType}
                currentActors={currentActors}
                hideElements={['mainBtn']}
                onCancelModalCallback={changeModalVisible}
                onOkModalCallback={changeModalVisible}
            />
        </>

    )
}

export default GetActorAndOpenModalWrapper;
