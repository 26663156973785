import React, {useEffect, useContext, useState} from 'react';
import {Col, Row, Button, Input} from "antd";
import _, {get} from "lodash";
import {useTranslation} from "react-i18next";

import TagsSettingList from "../../components/TagsSettingList";

import {capitalize} from "../../utils";
import {ApiContext} from "../../api/ApiContextProvider";

const ListOfGroupsAsTagsInModal = ({
    getSelectedTags,
   groupTagsOnActorsPage
}) => {
    const { t } = useTranslation();

    const [previousGroups, changePreviousGroups] = useState([])
    const [localTags, changeLocalTags] = useState([])
    const [currentPage, changePage] = useState(1)
    const [searchValue, setSearchValue] = useState('');
    const [totalTags, setTotalTags] = useState(0);


    const limit = 10;
    const offset = limit * (currentPage - 1);

    const {
        requestGetAllGroups
    } = useContext(ApiContext);


    const getGroupsForTags = async (params) => {
        let groups = await requestGetAllGroups(params, [])

        const {
            actors = [],
            total = 0
        } = groups;

        setTotalTags(total)
        changePreviousGroups([...previousGroups, ...actors.map((item) => {
            const label = _.get(item, 'uinfo.group_name');
            let className = '';

            if (['ADMIN', 'DEFAULT', 'BAN'].includes(label)) {
                className = label;
            }

            return {
                key: 'uinfo.groups',
                value: item.uuid,
                label,
                className
            };
        })])

        changeLocalTags([{
                tags: [
                    ...previousGroups,
                    ...actors.map((item) => {
                        const label = _.get(item, 'uinfo.group_name');
                        let className = '';

                        if (['ADMIN', 'DEFAULT', 'BAN'].includes(label)) {
                            className = label;
                        }

                        return {
                            key: 'uinfo.groups',
                            value: item.uuid,
                            label,
                            className
                        };
                    })
                ],
                isRow: true,
                rowText: capitalize(t('auth.headers.groups', 'groups')),
                className: 'isGroups'
            }]
        )
    }

    const getMoreTags = async () => {
        await changePage(currentPage + 1);

        await getGroupsForTags({
            limit: limit,
            offset: offset,
            order_by_column: "created",
            order_by_rule: "asc",
            uuid__not: groupTagsOnActorsPage.map((item) => item.uuid || item.value)
        })
    }

    const onSearchClick = () => {
        const data = {
            order_by_column: "created",
            order_by_rule: "asc"
        };

        if(searchValue) {
            data.search_data = {
                value: searchValue,
                ignore_case: true,
                fields: {
                    'base': ['uuid'],
                    'uinfo': ['group_name']
                }
            }
        }

        getGroupsForTags(data)
    }


    useEffect(() => {
        getMoreTags();
    }, []);

    return (
        <>
        <Row>
            <Col span={24}>
                <Input.Search
                    allowClear
                    onSearch={onSearchClick}
                    className={'w-100'}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    placeholder={capitalize(t('auth.placeholders.search_actor', 'enter group name or UUID'))}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <TagsSettingList
                    // defaultSelectedTags={selectedFilters}
                    allTags={localTags}
                    onSelectTags={getSelectedTags}
                    id={'unselectedGroups'}
                    small
                />
                {previousGroups.length !== totalTags && totalTags !== 0 ?
                    <Button type="link" onClick={getMoreTags}>show more</Button> : null
                }
            </Col>
        </Row>
        </>
    )
}

export default ListOfGroupsAsTagsInModal;
