import React, {useContext, useEffect, useReducer, useState} from 'react';
import { Alert, Button, Modal, Col, Row, Tag, Tooltip } from 'antd';
import { antNotification, capitalize } from '../../utils';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {mdiAutorenew, mdiCheck, mdiClose, mdiRefresh} from '@mdi/js';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import AntTable from '../../components/AntTable';

import { ApiContext } from '../../api/ApiContextProvider';
import {
  getSyncServicesData,
  getSyncServicesFetching
} from '../../permissions/selectors';
import { getAllServicesMap } from '../../actors/selectors';
import { getAuthServiceInfo } from '../../auth/selectors';
import { getMeIsRoot } from "../../profile/selectors";
import HelpIconWithHover from "../../components/HelpIconWithHover";


const SyncServicesPage = () => {
  const {
    requestGetSyncServicesInfo,
    requestGetAllServices,
    requestForceSyncService
  } = useContext(ApiContext);

  const { t } = useTranslation();

  const meIsRoot = useSelector(getMeIsRoot);
  const { uuid: authUUID } = useSelector(getAuthServiceInfo);
  const allServicesMap = useSelector(getAllServicesMap);
  const syncServicesData = useSelector(getSyncServicesData);
  const fetching = useSelector(getSyncServicesFetching);

  const [permActionsHashData, setPermActionsHashData] = useState(null);
  const [celeryActive, setCeleryActive] = useState(true);
  const [forceSyncService, setForceSyncService] = useState('');

  const [isModalOpen, setModal] = useReducer((state) => !state, false);

  const initFunc = () => {
    if (allServicesMap.size === 0) {
      requestGetAllServices();
    } else {
      requestGetSyncServicesInfo()
        .then((response) => {
          const permData = _.get(response, `sync_data.${authUUID}.permactions_hash_data`);


          console.log('permData', permData)


          setPermActionsHashData(permData);
          setCeleryActive(_.get(response, 'celery_active', false));
        })
        .catch(() => {
          antNotification.error(capitalize(t('auth.notifications.response_error', 'data retrieval error')))});
    }
  };

  const saveCurrentForceSyncServiceAndOpenModal = (cell) => {
    setForceSyncService(cell)

    setModal()
  }

  const forceSync = () => {
    requestForceSyncService(forceSyncService)
      .then(({message}) => {
        antNotification.success(message);
        initFunc();
      });

    setModal()
  };

  const formatHash = (hash) => (
    <Tooltip title={hash}>
      {hash}
    </Tooltip>
  );

  const formatService = (serviceName) => serviceName;

  const formatAvailable = (cell) => {
    return (
      <div className={'d-flex justify-content-center'}>
        <Tooltip title={capitalize(
          cell
            ? t('auth.labels.service_active', 'service active')
            : t('auth.labels.service_inactive', 'service inactive'))}>
          <Tag
            className={`tag-icon tag-${cell ? 'green' : 'red'}`}
          >
            <Icon path={cell ? mdiCheck : mdiClose} size={1.2}/>
          </Tag>
        </Tooltip>
      </div>
    );
  };

  const formatHardSync = (cell, row) => {
    if (row.uuid === authUUID) {
      return null;
    }

    return (
        <div className={'w-100'}>
          <Tooltip
              title={!meIsRoot ? 'Only for root user' : ''}
              placement={'left'}
          >
            <Button
                className={'button-primary-outlined'}
                size={'small'}
                onClick={() => saveCurrentForceSyncServiceAndOpenModal(cell)}
                disabled={!row.is_available || !meIsRoot}
            >
              <Icon path={mdiAutorenew} size={1} className={'mr-1'}/>
              {capitalize(t('auth.buttons.force_sync', 'force sync'))}
            </Button>
          </Tooltip>
        </div>
    );
  };

  const formatActorsHash = (cell, row, type) => {
    const authService = syncServicesData[authUUID];
    const hashesIsEqual = authService[type === 'groups' ? 'groups_hash' : 'services_hash'] === cell;
    const hashColor = row.is_auth_service ? null : {color: hashesIsEqual? 'green' : 'red' }

    return (
        <span
            style={hashColor}
        >
          {cell}
      </span>
    );
  }

  const formatPermsHash = (cell, row, type) => {
    if (row.uuid === authUUID || !cell) {
      return null;
    }

    const authPermActionsHash = _.get(permActionsHashData, `${row.uuid}.${type}_permactions_hash`);
    const servicePermActionsHash = _.get(cell, `${type}_permactions_hash`);
    const hashesIsEqual = (authPermActionsHash === servicePermActionsHash);

    return (
      <span style={{color: hashesIsEqual? 'green' : 'red' }}>
        Auth: {formatHash(authPermActionsHash)}
        <br/>
        Service: {formatHash(servicePermActionsHash)}
      </span>
    );
  };

  const columns = [
    {
      dataIndex: 'is_available',
      key: 'is_available',
      // title: capitalize(
      //   t('auth.headers.service_available', 'service available')),
      className: 'p-2',
      width: 38,
      render: formatAvailable
    },
    {
      dataIndex: 'title',
      key: 'title',
      title: capitalize(t('auth.headers.service_name', 'service name')),
      textWrap: 'word-break',
      className: 'p-2 pl-3',
      render: formatService
    },
    {
      title: () => (
          <>
            <HelpIconWithHover
                iconClassName={'mr-2'}
                toolTipText={
                  'The \'Actors\' section presents the results of comparing hashes for all Biome services by \'group\' and \'service\' types of actors.\n' +
                  'Highlighting the value in red indicates a mismatch between the data on the service and the Auth service, which can lead to unexpected results.\n' +
                  'Changes may be in sync if the number of \'In Progress\' sync packages is not 0, or have been lost/rejected by the service.'
                }
            />
            {capitalize(t('auth.headers.actors', 'actors'))}
          </>
      ),
      className: 'p-2',
      children: [
        {
          dataIndex: 'groups_hash',
          key: 'groups_hash',
          title: () => (
              <>
                {capitalize(t('auth.headers.groups', 'groups'))}
              </>
          ),
          // textWrap: 'word-break',
          className: 'p-2',
          ellipsis: true,
          render: (cell, row) => formatActorsHash(cell, row, 'groups')
        },
        {
          dataIndex: 'services_hash',
          key: 'services_hash',
          title: () => (
              <>
                {capitalize(t('auth.headers.services', 'services'))}
              </>
          ),
          // textWrap: 'word-break',
          className: 'p-2',
          ellipsis: true,
          render: (cell, row) => formatActorsHash(cell, row, 'services')
        }
      ]
    },
    {
      title: () => (
          <>
            <HelpIconWithHover
                iconClassName={'mr-2'}
                toolTipText={
                  'The \'Permissions\' section presents the results of comparing hashes for all Biome services by permissions for users and groups.\n' +
                  'Highlighting the value in red indicates a mismatch between the data on the service and the Auth service, which can lead to unexpected results.\n' +
                  'Changes may be in sync if the number of \'In Progress\' sync packages is not 0, or have been lost/rejected by the service.'
                }
            />
            {capitalize(t('auth.headers.permissions', 'permissions'))}
          </>
      ),
      className: 'p-2',
      children: [
        {
          dataIndex: 'permactions_hash_data',
          key: 'actor_permactions_hash',
          title: () => (
              <>
                {capitalize(t('auth.headers.actors', 'actors'))}
              </>
          ),
          // textWrap: 'word-break',
          ellipsis: true,
          className: 'p-2',
          render: (cell, row) => formatPermsHash(cell, row, 'actor')
        },
        {
          dataIndex: 'permactions_hash_data',
          key: 'group_permactions_hash',
          title: () => (
              <>
                {capitalize(t('auth.headers.groups', 'groups'))}
              </>
          ),
          // textWrap: 'word-break',
          ellipsis: true,
          className: 'p-2',
          render: (cell, row) => formatPermsHash(cell, row, 'group')
        }
      ]
    },
    {
      title: () => (
          <>
            <HelpIconWithHover
                iconClassName={'mr-2'}
                toolTipText={
                  'The \'Package sync\' section shows the number of such packages for each service.\n' +
                  'The \'In Progress\' column shows the number of packages that will be sent every 3 hours if Celery is running.\n' +
                  'The \'Failed\' column shows the number of packages that tried to send 10 times and failed.\n' +
                  'Nullable values indicate that all packages were successfully synchronized.'
                }
            />
            {capitalize(t('auth.headers.package_sync', 'package sync'))}
          </>
      ),
      className: 'p-2',
      children: [
        {
          dataIndex: 'sync_packages_in_progress',
          key: 'sync_packages_in_progress',
          title: capitalize(t('auth.headers.in_progress', 'in progress')),
          className: 'p-2',
          width: 100,
          render: (cell) => (<span className={cell > 0 ? 'text-blue' : 'text-green'}>{cell}</span>)
        },
        {
          dataIndex: 'sync_packages_stop',
          key: 'sync_packages_stop',
          title: capitalize(t('auth.headers.failed', 'failed')),
          className: 'p-2',
          width: 100,
          render: (cell) => (<span className={cell > 0 ? 'text-red' : 'text-green'}>{cell}</span>)
        }
      ]
    },
    {
      dataIndex: 'uuid',
      key: 'hard_sync',
      // title: capitalize(t('auth.headers.action', 'action')),
      textWrap: 'word-break',
      className: 'p-2 pr-3',
      width: 150,
      render: formatHardSync
    }
  ];

  const rowClassName = (row) => {
    if (_.get(row, 'is_available', false)) {
      return '';
    }

    return 'row-error';
  };

  const getTableData = () => {
    if (syncServicesData) {
      let newData = _.transform(syncServicesData,
        (acc, value, key) => {
          return acc.push({
            uuid: key,
            title: _.get(allServicesMap.get(key), 'uinfo.service_name', ''),
            ...value
          });
        }, []);

      return newData.sort((a, b) => b.uuid === authUUID ? 1 : -1);
    }

    return [];
  };

  useEffect(() => {
    initFunc();
  }, [allServicesMap.size]);


  return (
      <Row gutter={[0, 16]}>
        {!celeryActive && (
            <Col span={24}>
              <Alert
                  message={capitalize(t('auth.notifications.celery_inactive', 'celery inactive'))}
                  description={capitalize(t('auth.messages.celery_inactive'))}
                  type={'error'}
                  showIcon
              />
            </Col>
        )}
        <Col span={24}>
          <Row>
            <Col className={'d-flex'}>
              <h4 className={'header-primary mb-0'}>
                {capitalize(t('auth.pages.data_hash', 'data hash'))}:
              </h4>
            </Col>
            <Col flex={'auto'} className={'d-flex justify-content-end'}>
              <Button
                  className={'button-primary-outlined'}
                  size={'small'}
                  onClick={initFunc}
              >
                <Icon path={mdiRefresh} size={1} className={'mr-1'} />
                {capitalize(t('auth.buttons.refresh', 'refresh'))}
              </Button>
            </Col>
          </Row>
          <hr className={'my-4'} />
          <Row>
            <Col span={24}>
              <AntTable
                  data={getTableData()}
                  columns={columns}
                  rowKey={'uuid'}
                  loading={fetching}
                  bordered
                  rowClassName={rowClassName}
              />
            </Col>
          </Row>
        </Col>
        <Modal
            title="Force sync"
            open={isModalOpen}
            onOk={forceSync}
            onCancel={setModal}
            footer={[
              <Button key="back" onClick={setModal}>
                No
              </Button>,
              <Button type={'primary'}  key="back" onClick={forceSync}>
                Yes
              </Button>
            ]}
        >
          <p>
            The 'Force Synchronization' process involves sending special packages to fully synchronize the data of actors and permissions in accordance with the data of the Auth service
            Only available for root users
            Approximate execution timeframes depending on the number of actors in the biome:
            20000 - 3-6s
            50000 - 6-13s
            100000 - 15-25s
            etc.
          </p>
          <p>
            {/*20000 - 3-6 sec*/}

            {/*30000 - 4-8 sec*/}

            {/*50000 - 6-13 sec*/}

            {/*100000 - 15-25 sec*/}

            {/*150000 - 32-45 sec*/}


            Are you sure to force sync this service?
          </p>

        </Modal>
      </Row>
  );
};

export default SyncServicesPage;
