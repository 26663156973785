import React, {useEffect, useState} from 'react';
import {Tag} from "antd";
import Icon from "@mdi/react";
import {mdiCloseBox} from "@mdi/js";

import {getNameForActor} from "../../utils";



const styleForContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
}

const ListOfActorsTags = ({
    actors = [],
    isModal,
    getRemovedUserCallback
}) => {

    const [localActors, setLocalActors] = useState([]);

    const removeModalActors = (event) => {
        const removeUUID = event?.currentTarget?.attributes?.uuid?.value || '';

        if(getRemovedUserCallback) {
            getRemovedUserCallback(removeUUID)
        }
    }

    const getTagsTemplate = (item) => {
        if (isModal) {
            return (
                <span
                    className={'mr-3 mt-2'}
                    style={{
                        position: 'relative'
                    }}
                >
                           <Tag
                               onClick={removeModalActors}
                               color={'blue'}
                               style={{
                                   fontSize: 15
                               }}
                           >
                               {getNameForActor(item)}
                           </Tag>

                           <Icon
                               uuid={item.uuid}
                               onClick={removeModalActors}
                               style={{
                                   position:'absolute',
                                   top: '-10px',
                                   right: '-10px',
                                   zIndex: '10',
                                   cursor: 'pointer'
                               }}
                               path={mdiCloseBox}
                               size={1.1}
                               color={'red'}
                           />
                       </span>
            )
        } else {
           return (
               <Tag
                   color={'blue'}
                   className={'mr-2 mt-2'}
               >
                   {getNameForActor(item)}
               </Tag>
           )
        }
    }

    useEffect(() => {
        setLocalActors(actors)
    }, [JSON.stringify(actors)]);

    return(
        <div>
            <div style={styleForContainer}>
                {localActors.map(item => (
                    getTagsTemplate(item)
                ))}
            </div>
        </div>
    )
}

export default ListOfActorsTags;
