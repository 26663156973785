import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAllGroupsMap } from '../actors/selectors';
import {get, isObject} from 'lodash';
import { Space, Tag } from 'antd';


const GroupTags = ({
  groupUUIDs = [],
  groupNames = [],
  wrap
}) => {
  const allGroupsMap = useSelector(getAllGroupsMap);

  const getGroupName = (group) => get(group, 'uinfo.group_name', '');

  const getTagClass = (group) => {
    const groupName = isObject(group) ? getGroupName(group) : group;

    switch (groupName) {
      case 'DEFAULT':
        return 'tag-blue-light';
      case 'ADMIN':
        return 'tag-volcano-light';
      case 'BAN':
        return 'tag-red-light';
      default:
        return 'tag-default-light';
    }
  };

  const uuidToGroup = (uuid) => allGroupsMap.get(uuid);

  const weightCompare = (a, b) => {
    const getWeight = (group) => +get(group, 'uinfo.weight', 1);
    return getWeight(b) - getWeight(a);
  };

  const groupToTag = (group) => (
      <Tag
          key={`group-tag_${get(group, 'uuid', '')}`}
          className={getTagClass(group)}
      >
        {getGroupName(group)}
      </Tag>
  );

  const groupNameToTag = (groupName) => (
    <Tag
      key={`group-tag_${groupName}`}
      className={getTagClass(groupName)}
    >
      {groupName}
    </Tag>
  );

  if (groupUUIDs.length === 0 && groupNames.length === 0) {
    return null;
  }

  return (
    <Space
      size={[4, 4]}
      wrap={wrap}
    >
      {allGroupsMap.size > 0 && groupUUIDs.length > 0 ? (
        groupUUIDs.map(uuidToGroup).sort(weightCompare).map(groupToTag)
      ) : (
        groupNames.map(groupNameToTag)
      )}
    </Space>
  );
};

export default GroupTags;

GroupTags.propTypes = {
  groupNames: PropTypes.array,
  groupUUIDs: PropTypes.array,
  wrap: PropTypes.bool
};
