import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  actorsInfo: [],
  error: ''
};

export default (state = initialState, action = {}) => {
  const { type, payload, error } = action;

  switch (type) {
    case ActorsConstants.GET_ACTORS_IN_GROUP_REQUEST:
      return {
        ...state,
        fetching: true,
        actorsInfo: [],
        error: ''
      };

    case ActorsConstants.GET_ACTORS_IN_GROUP_SUCCESS:
      return {
        ...state,
        fetching: false,
        actorsInfo: payload,
        error: error ? error : ''
      };

    case ActorsConstants.GET_ACTORS_IN_GROUP_FAILURE:
      return {
        ...state,
        fetching: false,
        error: ''
      };

    default: return state;
  }
};
