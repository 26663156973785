import PropTypes from 'prop-types';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row, Switch} from 'antd';
import {
  mdiClipboardTextMultipleOutline,
  mdiHexagonMultipleOutline,
  mdiNoteEditOutline,
  mdiLinkVariant,
  mdiToggleSwitchOutline,
  mdiAccountGroupOutline,
  mdiCloudCheckOutline
} from '@mdi/js';

import InfoBlock from '../../../components/InfoBlock';
import ServiceListingGroupLink from "../service/ServiceListingGroupLink";
import CheckKeyPairOfServiceBtn from "../CheckKeyPairOfServiceBtn";
import CollapseGroupsForActor from "../CollapseGroupsForActor";
import AddActorsForNewActor from "../AddActorsForNewActor";

import {getMeIsAdmin} from "../../../profile/selectors";
import {capitalize} from '../../../utils';
import {useSelector} from "react-redux";


const ServiceInfoFormItems = (props) => {
  const {
    actorData,
    actorForm,
    actorUUID,
    creating,
    editMode,
    onSuccessActorUpdate,
    rules,
  } = props;

  const { setFieldsValue } = actorForm;

  const { t } = useTranslation();
  const meIsAdmin = useSelector(getMeIsAdmin)

  const [switchValue, changeSwitchValue] = useState(false);

  const {uinfo} = actorData || {};
  const {
    listing_group,
    service_name,
    groups = []
  } = uinfo || {};

  const isAuth = service_name === 'auth';

  const getSelectedActors = (actors) => {
    setFieldsValue({
      newActorGroups: actors.map((item) => item.uuid),
    })
  }

  return (
   <>
     <InfoBlock
         iconPath={mdiClipboardTextMultipleOutline}
         title={capitalize(t('auth.headers.service_detail', 'service detail'))}
     >
       <Row gutter={[16, 0]}>
         <Col span={12}>
           <Form.Item
               label={capitalize(t('auth.headers.service_name', 'service name'))}
               name={'service_name'}
               rules={rules.service_name}
           >
             <Input
                 placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.service_name')}))}
                 disabled={!editMode}
             />
           </Form.Item>
         </Col>
         <Col span={12}>
           <Form.Item
               label={capitalize(t('auth.headers.domain', 'domain'))}
               name={'service_domain'}
               rules={rules.service_domain}
           >
             <Input
                 placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.domain', 'domain')}))}
                 disabled={!editMode}
             />
           </Form.Item>
         </Col>
         <Col span={24}>
           <Form.Item
               label={capitalize(t('auth.headers.description', 'description'))}
               name={'description'}
               rules={rules.description}
           >
             <Input.TextArea
                 placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.description')}))}
                 autoSize={{
                   minRows: 3,
                   maxRows: 5
                 }}
                 disabled={!editMode}
             />
           </Form.Item>
         </Col>
         {creating && (
             <Col span={24}>
               <Form.Item
                   label={'UUID'}
                   name={'uuid'}
                   rules={rules.uuid}
               >
                 <Input
                     placeholder={capitalize(t('auth.placeholders.enter', { value: 'UUID' }))}
                     disabled={!editMode}
                 />
               </Form.Item>
             </Col>
         )}
         <Col span={24}>
           <Form.Item
               label={capitalize(t('auth.headers.biome_public_key', 'biome public key'))}
               name={'initial_key'}
               rules={rules.initial_key}
           >
             <Input.TextArea
                 placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.public_key')}))}
                 autoSize={{
                   minRows: 3,
                   maxRows: 5
                 }}
                 disabled={!editMode}
                 maxLength={130}
                 showCount
             />
           </Form.Item>
         </Col>
       </Row>
     </InfoBlock>
     <Row>
       <Col span={12}>
         {!isAuth && !creating &&
         <InfoBlock
             iconPath={mdiLinkVariant}
             title={capitalize(t('auth.headers.listingGroup', 'listing group'))}
         >
           <ServiceListingGroupLink
               listingGroupUUID={listing_group}
               actorUUID={actorUUID}
               onSuccessActorUpdate={onSuccessActorUpdate}
           />
         </InfoBlock>
         }
       </Col>
       <Col span={12}>
         {meIsAdmin && !creating &&
         <InfoBlock
             iconPath={mdiCloudCheckOutline}
             title={capitalize(t('auth.headers.check_key', 'check key pair'))}
         >
           <CheckKeyPairOfServiceBtn serviceUUID={actorUUID}/>
         </InfoBlock>
         }
       </Col>
     </Row>
     <InfoBlock
         iconPath={mdiHexagonMultipleOutline}
         title={capitalize(t('auth.headers.service_groups', 'service groups'))}
     >
       <Form.Item
           name={'groups'}
       >
         {creating ?
             <AddActorsForNewActor
                 actorType={'service'}
                 btnModalLabel={'add group'}
                 getSelectedActors={getSelectedActors}
             /> :
             <CollapseGroupsForActor
                 actorData={actorData}
                 iconModalPath={mdiAccountGroupOutline}
                 btnModalLabel={'add or remove group'}
             />
         }
       </Form.Item>
     </InfoBlock>
     <InfoBlock
         iconPath={mdiNoteEditOutline}
         title={capitalize(t('auth.headers.comment', 'comment'))}
     >
       <Row>
         <Col span={24}>
           <Form.Item
               name={'comment'}
               rules={rules.comment}
           >
             <Input.TextArea
                 placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.comment')}))}
                 autoSize={{
                   minRows: 3,
                   maxRows: 5
                 }}
                 disabled={!editMode}
             />
           </Form.Item>
         </Col>
       </Row>
     </InfoBlock>
     {creating && (
         <InfoBlock
             title={capitalize(t('auth.labels.create_service_without_listing_group', 'Create service without listing group'))}
             iconPath={mdiToggleSwitchOutline}
         >
           <Row>
             <Col>
               <Form.Item
                   name={'listingGroupFlag'}
               >
                 <Switch
                     className={'switch-primary'}
                     checkedChildren={capitalize(t('auth.buttons.yes', 'yes'))}
                     unCheckedChildren={capitalize(t('auth.buttons.no', 'no'))}
                     checked={switchValue}
                     onClick={() => changeSwitchValue(!switchValue)}
                 />
               </Form.Item>
             </Col>
           </Row>
         </InfoBlock>
     )}
   </>
  );
};

export default ServiceInfoFormItems;

ServiceInfoFormItems.propTypes = {
  actorForm: PropTypes.object.isRequired,
  creating: PropTypes.bool,
  editMode: PropTypes.bool,
  rules: PropTypes.object,
  validateMessages: PropTypes.object
};
