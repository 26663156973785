import { ActorsConstants } from '../../actors/constants/actionTypes';
import _ from 'lodash';


const initialState = {
  fetching: false,
  hashFetching: false,
  hash: [],
  unions: [],
  defaultPermissions: [],
  defaultPermissionsMap: new Map(),
  groupPermissions: [],
  groupPermissionsMap: new Map(),
  actorPermissions: [],
  actorPermissionsMap: new Map()
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ActorsConstants.GET_DEFAULT_PERMS_REQUEST:
    case ActorsConstants.GET_GROUPS_PERMS_REQUEST:
    case ActorsConstants.GET_ACTOR_PERMS_REQUEST:
    case ActorsConstants.SET_PERMISSIONS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case ActorsConstants.SET_PERMISSIONS_FAILURE:
    case ActorsConstants.SET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        fetching: false
      };

    case ActorsConstants.GET_DEFAULT_PERMS_SUCCESS:
      const defaultPermissionsMap = new Map([
        ...payload.map((item) => [_.get(item, 'permaction_uuid'), item])
      ]);

      const unions = _.uniq(_.flatten([...payload.map(({unions}) => unions)]));

      return {
        ...state,
        defaultPermissions: payload,
        defaultPermissionsMap,
        unions,
        fetching: false
      };

    case ActorsConstants.GET_GROUPS_PERMS_SUCCESS:
      const groupPermissionsMap = new Map([
        ...payload.map((item) => [_.get(item, 'permaction_uuid'), item])
      ]);

      return {
        ...state,
        groupPermissions: payload,
        groupPermissionsMap,
        fetching: false
      };

    case ActorsConstants.GET_ACTOR_PERMS_SUCCESS:
      const actorPermissionsMap = new Map([
        ...payload.map((item) => [_.get(item, 'permaction_uuid'), item])
      ]);

      return {
        ...state,
        actorPermissions: payload,
        actorPermissionsMap,
        fetching: false
      };

    case ActorsConstants.GET_DEFAULT_PERMS_FAILURE:
    case ActorsConstants.GET_DEFAULT_PERMS_CANCEL:
    case ActorsConstants.GET_GROUPS_PERMS_FAILURE:
    case ActorsConstants.GET_GROUPS_PERMS_CANCEL:
    case ActorsConstants.GET_ACTOR_PERMS_FAILURE:
    case ActorsConstants.GET_ACTOR_PERMS_CANCEL:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
};
