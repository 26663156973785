import React, {memo, useContext, useEffect, useReducer, useState} from 'react';
import {Button, Tag} from 'antd';
import {mdiFormatListGroup,} from '@mdi/js';
import Icon from "@mdi/react";

import ActorsModalBtn from "./ActorsModalBtn";
import ListOfActorsTags from "./ListOfActorsTags";

import {ApiContext} from "../../api/ApiContextProvider";


const CollapseGroupsForActor = ({
    actorData,
    btnModalLabel,
    hideModalElements,
    iconModalPath,
    hideElements = [],
}) => {
    const {getActors} = useContext(ApiContext);

    const INITIAL_LIMIT_OF_ACTORS = 10;

    const [localActors, setLocalActors] = useState([]);
    const [hideCollapse, setHideCollapse] = useState(true);
    const [lengthOfActors, setLengthOfActors] = useState(0);
    const [collapseFlag, setCollapseFlag] = useReducer(state => !state, false);
    const [updateActorFlag, setUpdateActorFlag] = useReducer(state => !state, false);

    const {
        actor_type: actorType,
        uuid: actorUUID,
        uinfo: {
            groups = []
        } = {}
    } = actorData || {};

    const getActorsRequest = (params) => {
        const data = {
            ...params
        }

        switch (actorType) {
            case 'user':
            case 'classic_user':
            case 'service':
                if(groups.length !== 0) {
                    data.actor_type = ['group']
                    data.uuid = groups

                    return getActors(data, [])
                }
                break;
            case 'group':
                if(actorUUID){
                    data.uinfo = {}
                    data.uinfo.groups = [actorUUID]

                    return getActors(data, [])
                }

                break;
            default:
                break;
        }
    }

    const getActorsAndChangeState = async (params) => {
        const actorsData = await getActorsRequest(params)

        const {
            actors = [],
            total = 0
        } = actorsData || {};

        // console.log('actors', actors)

        setLocalActors(actors)
        setLengthOfActors(total)
        setHideCollapse(total < INITIAL_LIMIT_OF_ACTORS)
    }


    const changeCollapse = () => {
        if(collapseFlag) {
            initFunc()
        } else {
            getActorsAndChangeState()
        }

        setCollapseFlag()
    }

    const updateActorCallback = () => {
        setUpdateActorFlag()
    }

    const initFunc = () => {
        // console.log('initFunc initFunc')

        const params = {
            limit: INITIAL_LIMIT_OF_ACTORS,
            offset: 0
        }

        getActorsAndChangeState(params)

        if(updateActorFlag){
            setUpdateActorFlag()
        }
    }

    useEffect(() => {
        initFunc();
    }, [
        actorUUID,
        // updateActorFlag
        JSON.stringify(groups)
    ]);


    return(
        <>
            <ListOfActorsTags
                actors={localActors}
            />
                <div className={'d-flex mt-3'}>
                    {(!hideCollapse && !hideElements.includes('collapse')) &&
                        <Button
                            onClick = {changeCollapse}
                            type="default"
                            size={'small'}
                            className={'button-primary-outlined actorsModalBtn mr-3'}
                        >
                            <Icon path={mdiFormatListGroup} size={1}/>
                            Show {collapseFlag ? `only ${INITIAL_LIMIT_OF_ACTORS}` : `all ${lengthOfActors}`} groups
                        </Button>
                    }
                    <ActorsModalBtn
                        hideElements={hideModalElements}
                        actorUUID={actorUUID}
                        actorType={actorType}
                        btnModalLabel={btnModalLabel}
                        currentActors={groups}
                        iconPath={iconModalPath}
                        // updateActorCallback={updateActorCallback}
                        listOfActorsActionBtnLabel={'add group'}
                    />
                </div>
        </>
    )
}

// export default CollapseGroupsForActor;
export default memo(CollapseGroupsForActor);
