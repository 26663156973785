import { AuthConstants } from '../constants';
import _ from 'lodash';


const initialState = {
  'biome': {
    'domain': '',
    'name': '',
    'uuid': ''
  },
  'auth': {
    'domain': '',
    'name': '',
    'uuid': '',
    'public_key': ''
  },
  'fetching': false
};


export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AuthConstants.AUTH_INFO_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case AuthConstants.AUTH_INFO_SUCCESS:
      return {
        ...state,
        'fetching': false,
        'biome': {
          'domain': _.get(payload, 'biom_domain', ''),
          'name': _.get(payload, 'biom_name', ''),
          'uuid': _.get(payload, 'biom_uuid', '')
        },
        'auth': {
          'domain': _.get(payload, 'service_domain', ''),
          'name': _.get(payload, 'service_name', ''),
          'uuid': _.get(payload, 'service_uuid', ''),
          'public_key': _.get(payload, 'auth_biom_public_key', '')
        }
      };

    case AuthConstants.AUTH_INFO_FAILURE:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
};
