import React from 'react';
import LocaleSwitcher from './LocaleSwitcher';
import { Col, Row } from 'antd';
import ProfileMenu from './ProfileMenu';
import InterfacesList from './InterfacesList';
import { Link } from 'react-router-dom';
import MasqueradingTurnOffButton from './MasqueradingTurnOffButton';


const Header = () => {
  return (
    <header className={'dt-header'}>
      <div className={'dt-header__container'}>
        <div className={'dt-brand'}>
          <span className={'dt-brand__logo'}>
            <Link className={'dt-brand__logo-link'} to={'/admin/profile'}>
              Auth service
            </Link>
          </span>
        </div>
        <Row className={'d-flex align-items-stretch justify-content-end w-100'}>
          <Col>
            <MasqueradingTurnOffButton />
          </Col>
          <Col>
            <InterfacesList />
          </Col>
          <Col>
            <LocaleSwitcher />
          </Col>
          <Col>
            <ProfileMenu />
          </Col>
        </Row>
      </div>
    </header>
  );
};

export default Header;
