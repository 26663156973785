import { ActorsConstants } from '../constants/actionTypes';


const initialState = {
  fetching: false,
  data: [],
  error: ''
};

export default (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case ActorsConstants.GET_PARTITIONS_REQUEST:
      return {
        ...state,
        data: [],
        fetching: true
      };

    case ActorsConstants.GET_PARTITIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload.result
      };

    case ActorsConstants.GET_PARTITIONS_FAILURE:
    case ActorsConstants.GET_PARTITIONS_CANCEL:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload
      };

    default:
      return state;
  }
};
