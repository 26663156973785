import {get} from 'lodash';
import {getNameForActor} from "../../utils";

// GET ACTORS
export const getAllActors = (state) => get(state, 'actors.listOfActors.actors', []);

export const getAllUsers = (state) => get(state, 'actors.listOfActors.users', []);

export const getAllGroups = (state) => get(state, 'actors.listOfActors.groups', []);

export const getAllServices = (state) => get(state, 'actors.listOfActors.services', []);

export const getAllActorsWithUnifiedName = (state) => {
  return getAllActors(state).map((item) => (
      {
        ...item,
        uinfo: {
          ...item.uinfo,
          name: getNameForActor(item)
        }
      })
  )
};

// GET ACTORS MAP
export const getAllUsersMap = (state) => get(state, 'actors.listOfActors.usersMap', []);

export const getAllGroupsMap = (state) => get(state, 'actors.listOfActors.groupsMap', []);

export const getAllServicesMap = (state) => get(state, 'actors.listOfActors.servicesMap', []);

// FETCHING
export const isActorsFetching = (state) => get(state, 'actors.listOfActors.fetching', false);

export const isGroupsFetching = (state) => get(state, 'actors.listOfActors.fetchingGroups', false);

export const isUsersFetching = (state) => get(state, 'actors.listOfActors.fetchingUsers', false);

export const isServicesFetching = (state) => get(state, 'actors.listOfActors.fetchingServices', false);

export const isActorCreatedFetching = (state) => get(state, 'actors.created.fetching', false);

// ACTOR INFO
export const getActorsInfo = (state) => get(state, 'actorInfo.data.actorInfo', {});

export const isInfoFetching = (state) => get(state, 'actorInfo.data.fetching', false);

// FILTER
export const isResetTags = (state) => get(state, 'filter.resetTags');

// PARTITIONS
export const isPartitionsFetching = (state) => get(
  state,
  'actors.partitions.fetching',
  false
);

export const getAllPartitions = (state) => {
  const partitions = get(state, 'actors.partitions.data', []);
  return partitions.map((item) => (
    { name: item.params.name, uuid: item.uuid }
  ));
};
