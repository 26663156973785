// noinspection JSValidateTypes
import Cookies from 'universal-cookie';
import _ from 'lodash/core';


export class AuthHelper {
  static getToken = () => {
    const cookies = new Cookies();

    return cookies.get('Auth');
  };

  static access() {
    let access = localStorage.getItem('access') || false;
    return !!access;
  }

  static authorizedAccess(data) {
    localStorage.setItem('access', data);
  }

  static isAuthorised = () => {
    const cookies = new Cookies();
    const authToken = cookies.get('Auth');
    return !!(
      authToken && authToken !== 'undefined'
    );
  };

  static removeSession = () => {
    try {
      const cookies = new Cookies();
      let allCookies = cookies.getAll();
      _.map(allCookies, (value, key) => {
        if (key === 'session') {
          cookies.remove('session', { path: '/' });
        }
      });
    } catch (e) {
      // do nothing
    }
  };

  static clearCookies = () => {
    try {
      const cookies = new Cookies();
      const allCookies = cookies.getAll();

      _.keys(allCookies).forEach((key) => cookies.remove(key, { path: '/' }));
    } catch (e) {
      // console.log('removeSessionToken Error');
    }
  };

  static removeSessionToken = name => {
    try {
      const cookies = new Cookies();
      let allCookies = cookies.getAll();
      _.map(allCookies, (value, key) => {
        if (key === name) {
          cookies.remove('Auth', { path: '/' });
        }
      });
    } catch (e) {
      // console.log('removeSessionToken Error');
    }
  };

  static masqueradingOn = (masqueradeSession, primarySession) => {
    return new Promise((resolve, reject) => {
      try {
        const cookies = new Cookies();

        cookies.set('Auth', masqueradeSession, { path: '/' });
        cookies.set('AuthPrimary', primarySession, { path: '/' });
        resolve();
      } catch (e) {
        reject();
      }
    });
  };

  static masqueradingOff = () => {
    return new Promise((resolve, reject) => {
      try {
        const cookies = new Cookies();
        const authPrimary = cookies.get('AuthPrimary');

        if (authPrimary) {
          cookies.remove('Auth', { path: '/' });
          cookies.remove('AuthPrimary', { path: '/' });

          cookies.set('Auth', authPrimary, { path: '/' });
          resolve();
        } else {
          reject();
        }
      } catch (e) {
        reject();
      }
    });
  };
}
