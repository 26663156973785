import { combineReducers } from 'redux';
import fetchProfileInfoReducer from '../profile/reducers/fetchProfileInfoReducer';
import getListOfPoliciesReducer from '../securityPolicy/reducers/getListOfPoliciesReducer';
import partitionsReducer from '../actors/reducers/partitionsReducer';
import actorsDataReducer from '../actors/reducers/actorsDataReducer';
import actorsInfoReducer from '../actors/reducers/actorsInfoReducer';
import permissionsListReducer from '../permissions/reducers/permissionsListReducer';
import actorInGroupReducer from '../actors/reducers/actorInGroupReducer';
import publicUIReducer from '../publicInterfaces/reducers';
import locales from '../locale/reducers';
import filtersReducer from '../actors/reducers/filtersReducer';
import createActorReducer from '../actors/reducers/createActorReducer';
import authLogInReducer from '../auth/reducers/authLogInReducer';
import { connectRouter } from 'connected-react-router';
import authInfoReducer from '../auth/reducers/authInfoReducer';
import gertCertificatesReducer from '../certificates/reducers/gertCertificatesReducer';
import syncServicesInfoReducer from '../syncServices/reducers/syncServicesInfoReducer';
import adminServicePermSlicer from "../permissions/slicers/adminServicePermSlicer";
import serviceVersioningReducer from "../actors/reducers/serviceVersioningReducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: combineReducers({
      info: authInfoReducer,
      login: authLogInReducer
    }),
    actors: combineReducers({
      listOfActors: actorsDataReducer,
      partitions: partitionsReducer,
      created: createActorReducer,
      serviceVersioning: serviceVersioningReducer,
      type: 'user'
    }),
    actorInfo: combineReducers({
      data: actorsInfoReducer,
      permissions: permissionsListReducer,
      actorsInGroup: actorInGroupReducer,
      serviceAdmin: adminServicePermSlicer
    }),
    certificates: gertCertificatesReducer,
    filter: filtersReducer,
    locales,
    policy: getListOfPoliciesReducer,
    profile: combineReducers({
      info: fetchProfileInfoReducer
    }),
    publicUI: combineReducers({
      interfacesList: publicUIReducer
    }),
    syncServices: syncServicesInfoReducer
  });

export default createRootReducer;
